import React, { Component } from "react";
//import moment from 'moment'
//import 'moment/locale/zh-cn';
import moment from "moment";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  AddMorePopover,
  /*  DemoData */
} from "react-big-scheduler";
import withDragDropContext from "./withDnDContext";
import "react-big-scheduler/lib/css/style.css";
import AddProgressModal from "../../components/modals/addProgress";
import DemoData from "./DemoData";
import apicall from "../../apicall";
import * as AiIcons from "react-icons/ai";
import "./../../App.css";
import AddScheduleAllocation from "../../components/modals/addScheduleAllocation";
import ProjectModal from "../../components/modals/projectmodal";
import PeopleModal from "../../components/modals/peoplemodal";
import Timeoff from "../../components/modals/timeoff";
import ConfirmModal from "../../components/modals/confirmModal";
import { confirm } from "react-confirm-box";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const confirmModalOptions = {
  render: (message, onConfirm, onCancel) => {
    return (
      <>
        <Modal show={true} onHide={onCancel} centered>
          <Modal.Body>
            <h5
              style={{ textAlign: "center", marginBottom: "0px" }}
              className="text-secondary"
            >
              {message}
            </h5>
          </Modal.Body>
          <Modal.Footer style={{ margin: "0 auto" }}>
            <Button variant="primary" onClick={onConfirm}>
              Copy
            </Button>
            <Button variant="light" onClick={onCancel}>
              Move
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  },
};

function countPersonsByParentId(data, parentId) {
  let count = 0;
  for (const item of data) {
    if (item.parentId === parentId) {
      count++;
    }
  }
  return count + " Person" + (count > 1 ? "s" : "");
}

function getEventHeight(resourceId, tentative) {
  const tdElement = document.querySelector(
    `td[data-resource-id="${resourceId}"]`
  );
  let eventHeight = "";

  if (tdElement) {
    const originalHeight = tdElement.style.height;
    const numericHeight = parseInt(originalHeight, 10);
    const adjustedHeight =
      tentative === "Yes" ? numericHeight - 20 : numericHeight - 5;
    eventHeight = adjustedHeight + "px";
  }

  return eventHeight;
}

function getTotalHoursForResource(resourceId, scheduledata, allocations) {
  // Initialize a variable to store the total hours
  let eventGroups = scheduledata.eventGroups;
  let totalHours = 0;
  // Convert startDate and endDate to Date objects
  let startDate = new Date(scheduledata.startDate);
  let endDate = new Date(scheduledata.endDate);
  // Iterate through the eventGroups array
  for (const event of allocations) {
    const eventStartDate = new Date(event.start);
    const eventEndDate = new Date(event.end);
    // Check if the resource ID matches
    if (event.resourceId === resourceId) {
      // Calculate the overlap between event and date range
      const overlapStartDate = new Date(Math.max(startDate, eventStartDate));
      const overlapEndDate = new Date(Math.min(endDate, eventEndDate));
      if (overlapStartDate < overlapEndDate) {
        // Calculate the duration of the overlap in hours
        //const overlapHours = (overlapEndDate - overlapStartDate) / (1000 * 60 * 60);
        totalHours += parseFloat(event.total_hours);
      }
    }
  }
  return totalHours + "h";
}

var isCompletlyLoaded = false;
let isScrollingLeft = false;
let isScrollingRight = false;

class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      milestones: [], // Initialize resources as an empty array
    };

    const calculateEventItemHeight = (defaultSize) => {
      switch (defaultSize) {
        case "Comfortable":
          return 100;
        case "Spacious":
          return 150;
        default:
          return 30;
      }
    };
    const calculateEventItemLineHeight = (defaultSize) => {
      switch (defaultSize) {
        case "Spacious":
          return 250;
        case "Comfortable":
          return 200;
        default:
          return 60;
      }
    };

    const getSlotHeading = (currentPage) => {
      switch (currentPage) {
        case "Schedule":
          return "Team";
        case "Allocation":
          return "Project Plan";
        default:
          return "Project Plan";
      }
    };

    //let schedulerData = new SchedulerData(, ViewTypes.Week);
    const currentDate = new Date();
    // Get the current date in the 'YYYY-MM-DD' format

    //imp
    const currentDateString = localStorage.getItem("scheduler_start")
      ? localStorage.getItem("scheduler_start")
      : currentDate.toISOString().split("T")[0];
    const deafultView =
      localStorage.getItem("defaultView") == 4
        ? ViewTypes.Year
        : ViewTypes.Month;
    let schedulerData = new SchedulerData(
      currentDateString,
      deafultView,
      false,
      false,
      {
        dayMaxEvents: 4000,
        weekMaxEvents: 4000,
        monthMaxEvents: 4000,
        yearMaxEvents: 4000,
        schedulerWidth: "96%",
        schedulerMaxHeight: window.innerHeight - 108,
        eventItemHeight: 30,
        eventItemLineHeight: 32,
        nonAgendaSlotMinHeight: calculateEventItemLineHeight(
          this.props.defaultFiltersize
        ),
        crossResourceMove: true,
        eventItemPopoverEnabled: false,
        nonWorkingTimeHeadBgColor: "#F8F9FA",
        nonWorkingTimeBodyBgColor: "#F8F9FA",
        resourceName: getSlotHeading(this.props.defaultFilter),
        addMorePopoverHeaderFormat: "MMM D, YYYY dddd",
        eventItemPopoverDateFormat: "MMM D",
        nonAgendaDayCellHeaderFormat: "ha",
        nonAgendaOtherCellHeaderFormat: "ddd MMM D",
        views: [
          { viewName: "Today", viewType: ViewTypes.Custom },
          /* { viewName: "Week", viewType: ViewTypes.Week }, */
          { viewName: "Month", viewType: ViewTypes.Month },
          { viewName: "Year", viewType: ViewTypes.Year },
        ],
        dayResourceTableWidth: 300,
        weekResourceTableWidth: "20%",
        monthResourceTableWidth: 310,
        quarterResourceTableWidth: 200,
        yearResourceTableWidth: 310,
        customResourceTableWidth: 200,
        dayCellWidth: 30,
        weekCellWidth: 150,
        monthCellWidth: 150,
        quarterCellWidth: 80,
        yearCellWidth: 150,
        customCellWidth: 80,
        calendarPopoverEnabled: true,
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources(props.data);
    schedulerData.setEvents(props.milestones);
    //const dateLimit = JSON.parse(localStorage.setItem("dateLimit", dateLimit));
    //schedulerData.startDate = dateLimit.start_date;
    //schedulerData.endDate = dateLimit.endDate;

    this.state = {
      viewModel: schedulerData,
      modalShow: false,
      currentAddEvent: {},
      editModalShow: false,
      taskId: null,
      taskTitle: null,
      hoursPerDay: null,
      notes: null,
      startDate: null,
      endDate: null,
      borderColor: null,
      task: null,
      modalTitle: null,
      leave_id: null,
      milestone_start: null,
      milestone_end: null,
      milestone_title: null,
      milestone_id: null,
      scheduleAllocationModalShow: false,
      addModalShow: false,
      addPersonModalShow: false,
      leave_status: null,
      scheduleModalTitle: "Add Allocation",
      scheduleAllocationEditObj: null,
      schedulerStart: schedulerData.startDate,
      schedulerEnd: schedulerData.endDate,
      showtimeoff: false,
      timeOffObj: null,
      showConfirmModal: false,
      schedulingOnNonWorkingDay: false,
      managed_persons: JSON.parse(localStorage.getItem("managed_persons")),
      headerItem: undefined,
      isCtrlPressed: false,
      isAltPressed: false,
    };
  }

  /*  componentDidMount() {
    setTimeout(() => {
      isCompletlyLoaded = true;
      var schedulerContainer =
        document.getElementsByClassName("scheduler-view");
    }, 1000);
  } */

  getSummary = (
    schedulerData,
    headerEvents,
    slotId,
    slotName,
    headerStart,
    headerEnd
  ) => {
    let text = headerEvents.length.toString();
    let color = "#d9d9d9";

    if (headerEvents.length > 0) {
      color = headerEvents.length <= 1 ? "green" : "red";
    }

    if (headerEvents.length > 3) {
      headerEvents.forEach((n) => {});
    }
    return { text: text, color: color, fontSize: "12px" };
  };

  /* componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  } */

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  handleKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Control" || e.key === "Meta" || e.key === "Alt") {
      this.setState({ isCtrlPressed: true });
    }
    /* if (e.key === "Alt") {
      this.setState({ isAltPressed: true });
    } */
  };

  handleKeyUp = (e) => {
    if (e.key === "Control" || e.key === "Meta" || e.key === "Alt") {
      this.setState({ isCtrlPressed: false });
    }
    /* if (e.key === "Alt") {
      this.setState({ isAltPressed: false });
    } */
  };

  openAddProjectModal = () => {
    this.setState({ addModalShow: true });
  };

  openAddPersonModal = () => {
    this.setState({ addPersonModalShow: true });
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    this.props.setDateLimit({
      start_date: schedulerData.startDate,
      end_date: schedulerData.endDate,
    });
    this.setState({
      schedulerEnd: schedulerData.endDate,
      schedulerStart: schedulerData.startDate,
    });
    schedulerData.setEvents(this.props.milestones);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    this.props.setDateLimit({
      start_date: schedulerData.startDate,
      end_date: schedulerData.endDate,
    });
    this.setState({
      schedulerEnd: schedulerData.endDate,
      schedulerStart: schedulerData.startDate,
    });
    schedulerData.setEvents(this.props.milestones);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    if (view.viewType == 5) {
      localStorage.removeItem("dateLimit");
      localStorage.removeItem("scheduler_start");
      window.location.reload();
      return;
    }
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    localStorage.setItem("defaultView", view.viewType);
    //console.log(schedulerData.startDate, schedulerData.endDate, "view Change");
    this.props.setDateLimit({
      start_date: schedulerData.startDate,
      end_date: schedulerData.endDate,
    });
    this.setState({
      schedulerEnd: schedulerData.endDate,
      schedulerStart: schedulerData.startDate,
    });

    schedulerData.setEvents(this.props.milestones);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    //schedulerData.setEvents(DemoData.events);
    //console.log(schedulerData.startDate, schedulerData.endDate, "onSelectDate");
    this.props.setDateLimit({
      start_date: schedulerData.startDate,
      end_date: schedulerData.endDate,
    });
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event, slotId, slotName) => {
    // console.log(event, 'testeve');
    this.setState({
      viewModel: schedulerData,
    });
    let end = event.end;

    // if(event.freshEvent){
    //   const inputDate = new Date(event.end);
    //   inputDate.setDate(inputDate.getDate() - 1);
    //   const outputDateString = inputDate.toISOString().split("T")[0] + " 23:59:59";
    //   end = outputDateString;
    // }

    // alert(end);

    let currentAddEvent = {
      slotId: event.resourceId,
      start: event.start,
      end: end,
    };

    if (localStorage.getItem("access_role_id") == "3") {
      let isEventClickable = this.state.managed_persons.some(
        (obj) => obj.person_id == event.person_id
      );

      if (
        isEventClickable ||
        event.person_id == localStorage.getItem("person_id")
      ) {
        if (event.type == "1") {
          alert("here");
          if (localStorage.getItem("can_manage_project") == "Yes") {
            this.setState({ editModalShow: true });
          }
        } else if (event.type === 3) {
          if (localStorage.getItem("can_manage_people") == "Yes") {
            event["slotName"] = event.person_name;
            this.setState({ showtimeoff: true, timeOffObj: event });
          }
        } else {
          if (
            localStorage.getItem("can_manage_people") == "Yes" ||
            localStorage.getItem("person_id") == event.person_id
          ) {
            if (this.props.defaultFilter === "Schedule") {
              event["projects"] = this.props.projects;
              event["slotName"] = event.person_name;
              event["schedulerData"] = schedulerData;
              this.setState({
                scheduleAllocationModalShow: true,
                scheduleModalTitle: "Edit Allocation",
                scheduleAllocationEditObj: event,
              });
            } else {
              this.setState({ editModalShow: true });
            }
          }
        }
      }
    } else if (localStorage.getItem("access_role_id") == "2") {
      //console.log(event, 'event-from-basic');
      if (event.type === 3) {
        event["slotName"] = event.person_name;
        this.setState({ showtimeoff: true, timeOffObj: event });
      } else {
        if (this.props.defaultFilter === "Schedule") {
          event["projects"] = this.props.projects;
          event["slotName"] = event.person_name;
          event["schedulerData"] = schedulerData;
          this.setState({
            scheduleAllocationModalShow: true,
            scheduleModalTitle: "Edit Allocation",
            scheduleAllocationEditObj: event,
          });
        } else {
          this.setState({ editModalShow: true });
        }
      }
    } else if (localStorage.getItem("access_role_id") == "8") {
      if (event.type === 3) {
        if (
          event.tentative === "Yes" &&
          event.person_id == localStorage.getItem("person_id")
        ) {
          event["slotName"] = event.person_name;
          this.setState({ showtimeoff: true, timeOffObj: event });
        }
      } else {
        if (this.props.defaultFilter == "Schedule") {
          if (event.resourceId == localStorage.getItem("person_id")) {
            event["projects"] = this.props.projects;
            event["slotName"] = event.person_name;
            event["schedulerData"] = schedulerData;
            this.setState({
              scheduleAllocationModalShow: true,
              scheduleModalTitle: "Edit Allocation",
              scheduleAllocationEditObj: event,
            });
          } else {
            this.setState({
              scheduleAllocationModalShow: false,
              scheduleModalTitle: "Edit Allocation",
              scheduleAllocationEditObj: event,
            });
          }
        } else {
          if (
            event.type != "1" &&
            event.person_id == localStorage.getItem("person_id")
          ) {
            this.setState({
              editModalShow: true,
              modalTitle: "Edit Allocation",
            });
          } else {
            this.setState({ modalTitle: "Log Time" });
          }
        }
      }
    }

    this.setState({ taskId: event.id });
    this.setState({ taskTitle: event.title });
    this.setState({ hoursPerDay: event.hoursPerDay });
    this.setState({ notes: event.notes });
    this.setState({ startDate: event.start });
    this.setState({ endDate: event.end });
    this.setState({ currentAddEvent: currentAddEvent });

    if (event.type == "3") {
      //console.log(event, "leave");
      this.setState({ modalTitle: "Manage Leave" });
      this.setState({ task: "manageleave" });
      this.setState({ leave_id: event.id });
      this.setState({ leave_status: event.leave_status });
      if (localStorage.getItem("access_role_id") == "2") {
        this.setState({ editModalShow: false });
      }
    } else if (event.type == "1") {
      //console.log(event, "xyz");
      this.setState({ modalTitle: "Edit Milestone" });
      this.setState({ task: "editmilestone" });
      this.setState({ milestone_start: event.start });
      this.setState({ milestone_end: event.end });
      this.setState({ milestone_title: event.title });
      this.setState({ milestone_id: event.id });
    } else {
      this.setState({ modalTitle: "Edit Allocation" });
      this.setState({ task: "edit" });
    }
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    let startDate = start instanceof Date ? start : new Date(start);
    let dayOfTheWeek = startDate.getDay();

    let newFreshId =
      Math.max(...schedulerData.events.map((item) => item.id), 0) + 1;

    let addEventData = {
      id: newFreshId,
      schedulerData: schedulerData,
      slotId: slotId,
      slotName: slotName,
      start: start,
      end: end,
      type: type,
      projects: this.props.projects,
    };

    let newState = {
      viewModel: schedulerData,
    };

    if (dayOfTheWeek === 6 || dayOfTheWeek === 0) {
      newState.showConfirmModal = true;
      newState.currentAddEvent = addEventData;
      newState.schedulingOnNonWorkingDay = true;
    } else {
      newState.schedulingOnNonWorkingDay = false;
      if (this.props.defaultFilter === "Schedule") {
        newState.scheduleAllocationModalShow = true;
        newState.currentAddEvent = addEventData;
        newState.scheduleModalTitle = "Add Allocation";
        newState.scheduleAllocationEditObj = null;
      } else {
        newState.currentAddEvent = addEventData;
        newState.modalShow = true;
      }
    }

    if (slotId.toString().includes("p")) {
      let tempSlot = {
        slotId: slotId,
      };
      this.props.onSlotClick(tempSlot);
      return;
    }

    this.setState(newState);
  };

  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let borderWidth = isStart ? "6" : "0";
    let borderColor = event.type == 1 ? "transparent" : null;
    let borderRadius = event.type !== 1 ? "5px" : "0";
    let background =
      event.type === 1
        ? "#D3D3D3"
        : event.type === 3
        ? event.tentative === "No"
          ? `repeating-linear-gradient(126deg, transparent, transparent 6px, ${event.bgColor} 4px, ${event.bgColor} 7px)`
          : "white"
        : event.bgColor;
    let completeBorder =
      event.type === 1
        ? "transparent"
        : event.type === 3
        ? event.tentative === "Yes"
          ? event.bgColor
          : "transparent"
        : event.bgColor;
    let color =
      event.type == 1 || event.type === 3 || event.type === 2
        ? "black"
        : "white";
    let marginLeft = event.type !== 1 ? "0" : "0";
    let borderStyle =
      event.type === 3
        ? event.tentative === "Yes"
          ? "1px dashed "
          : "1px solid "
        : "1px solid ";
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );
    let project_name = event.project_name ? event.project_name : null;

    let divStyle =
      event.hoursPerDay && parseInt(event.hoursPerDay) > 8
        ? {
            borderLeft: borderWidth + "px solid " + borderColor,
            height:
              event.type === 3
                ? getEventHeight(event.resourceId, event.tentative)
                : mustBeHeight,
            borderRadius: borderRadius,
            marginLeft: marginLeft,
            color: color,
            border: borderStyle + completeBorder,
            borderBottomColor: "red",
            borderBottomWidth: "3px",
            borderBottomStyle: "dotted",
            background: background,
          }
        : {
            borderLeft: borderWidth + "px solid" + borderColor,
            height:
              event.type === 3
                ? getEventHeight(event.resourceId, event.tentative)
                : mustBeHeight,
            borderRadius: borderRadius,
            marginLeft: marginLeft,
            color: color,
            border: borderStyle + completeBorder,
            background: background,
          };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        {event.type === 1 && (
          <span
            style={{
              backgroundColor: "red",
              color: "transparent",
              float: "left",
              width: "3px",
              height: "100%",
            }}
          ></span>
        )}
        <div style={{ marginLeft: "6px", textAlign: "left" }}>
          <div>
            <span
              style={{
                position: "absolute",
                top: "2px",
                fontSize: "11px",
                maxWidth: "90%",
                overflow: "hidden",
              }}
            >
              {titleText}
            </span>
            {event.type !== 1 && (
              <span
                style={{
                  float: "right",
                  fontSize: "12px",
                  fontWeight: "700",
                  position: "absolute",
                  bottom: "2px",
                  right: "6px",
                }}
              >
                {event.type === 3
                  ? event.tentative === "Yes"
                    ? event.hoursPerDay + "h"
                    : null
                  : event.hoursPerDay + "h"}
              </span>
            )}

            <span
              style={{
                position: "absolute",
                bottom: "2px",
                fontSize: "11px",
                maxWidth: "75%",
                overflow: "hidden",
              }}
            >
              {project_name}
            </span>
          </div>
        </div>
      </div>
    );
  };

  slotItemTemplateResolver = (
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) => {
    let tempData = slot.slotName.split("|");
    return (
      <div
        className="clsname"
        style={{ textAlign: "left", height: "100%", cursor: "pointer" }}
        onClick={() => this.props.onSlotClick(slot)}
      >
        <div
          style={{
            borderLeft: `3px solid ${tempData[2] || "#FFF"}`,
            paddingLeft: "5px",
            height: "100%",
          }}
        >
          <span style={{ fontSize: "16px" }}>{tempData[0]} </span>
          {/* <span className="hrs-count">
            {!slot.slotId.toString().includes("p") &&
              getTotalHoursForResource(
                slot.slotId,
                schedulerData,
                this.props.milestones
              )}
          </span>  */}
          <br />
          <span style={{ fontSize: "11px" }}>{tempData[1]}</span>
          {slot.slotId.toString().includes("p") && (
            <div
              className="icon-container"
              style={{ float: "right", marginRight: "8px", fontSize: "12px" }}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleExpandFunc(schedulerData, slot.slotId);
              }}
            >
              {countPersonsByParentId(this.props.data, slot.slotId)}{" "}
              <AiIcons.AiOutlineShrink size={"16"} />
            </div>
          )}
        </div>
      </div>
    );
  };

  getEventStyle = (schedulerData, event, start, end, isSelected) => {
    const backgroundColor = event.bgColor || "#3174ad"; // Default background color or use event's bgColor property
    const borderColor = event.borderColor || "#215d97"; // Default border color or use event's borderColor property

    const style = {
      backgroundColor,
      borderColor,
    };

    return {
      style,
    };
  };

  updateEventStart = (schedulerData, event, newStart) => {
    let NewTotalHours = apicall.calculateTotalHours(
      newStart,
      event.end,
      event.hoursPerDay
    );
    event.start = newStart;
    event.total_hours = NewTotalHours;
    event["crossSlotMove"] = false;
    this.props.updateOnDrag(JSON.stringify(event));
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    //console.log(event, "end");
    let NewTotalHours = apicall.calculateTotalHours(
      event.start,
      newEnd,
      event.hoursPerDay
    );
    event.end = newEnd;
    event.total_hours = NewTotalHours;
    event["crossSlotMove"] = false;
    this.props.updateOnDrag(JSON.stringify(event));
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = async (schedulerData, event, slotId, slotName, start, end) => {
    const { isCtrlPressed, isAltPressed } = this.state;
    let crossSlotMove = false;
    if (event.resourceId != slotId) {
      crossSlotMove = true;
    }

    if (event.type === 3 && crossSlotMove) {
      alert("Cannot assign leave to another team member");
      return;
    }

    event["crossSlotMove"] = crossSlotMove;
    if (
      localStorage.getItem("current_calendar") === "Schedule" ||
      !localStorage.getItem("current_calendar")
    )
      event["new_person_id"] = crossSlotMove ? slotId : event.resourceId;
    else {
      if (!event.type && slotId[0] === "p") {
        alert("Cannot move allocation to a project slot.");
        return;
      }
      event["new_relation_id"] = crossSlotMove ? slotId : event.resourceId;
    }

    // Use stored state to check if Ctrl+Alt are pressed
    const isCopyAction = isCtrlPressed; // || isAltPressed;

    if (event.type !== 3) {
      /* const result = await confirm(
        "Do you want to copy or move?",
        confirmModalOptions
      ); */
      if (isCopyAction) {
        this.copyEvent(schedulerData, slotId, slotName, start, end, event);
        return;
      }
    }
    event.start = start;
    event.end = end;

    this.props.updateOnDrag(JSON.stringify(event));
    schedulerData.moveEvent(event, slotId, slotName, start, end);

    if (localStorage.getItem("current_calendar") === "Allocation") {
      let newBgColor = schedulerData.events.find(
        (event) => event.resourceId == slotId
      ).bgColor;
      let updatedEventObj = event;
      schedulerData.removeEvent(event);
      updatedEventObj.bgColor = newBgColor;
      schedulerData.addEvent(updatedEventObj);
    }

    this.setState({
      viewModel: schedulerData,
    });
  };

  copyEvent = async (schedulerData, slotId, slotName, start, end, event) => {
    console.log(event);

    // Create a new event object template
    let baseEvent = {
      project_name: event.project_name,
      title: event.title,
      start: start,
      end: end,
      resourceId: slotId,
      type: event.type,
      tentative: event.tentative,
      bgColor: event.bgColor,
      project_id: event.project_id,
      hoursPerDay: event.hoursPerDay,
      totalHours: event.hoursPerDay,
      notes: event.notes,
      endDateToDisplay: event.endDateToDisplay,
      person_name: slotName,
    };

    // Send the base event to copyOnDrag and receive the new IDs
    const ids = await this.props.copyOnDrag(baseEvent);

    // Check if ids is an array and has at least one element
    if (Array.isArray(ids) && ids.length > 0) {
      ids.forEach((id) => {
        // Create a new event for each ID
        let newEvent = { ...baseEvent, id: id };

        console.log(newEvent);

        // Add the new event to the scheduler data
        schedulerData.addEvent(newEvent);
      });

      // Update the state with the new scheduler data
      this.setState({
        viewModel: schedulerData,
      });
    } else {
      console.error("No IDs returned from copyOnDrag");
    }
  };

  onSetAddMoreState = (newState) => {
    if (newState === undefined) {
      this.setState({
        headerItem: undefined,
        left: 0,
        top: 0,
        height: 0,
      });
    } else {
      console.log(newState);
      this.setState({
        ...newState,
      });
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nonAgendaCellHeaderTemplateResolver = (
    schedulerData,
    item,
    formattedDateItems,
    style
  ) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;
    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), "hour");
    } else {
      isCurrentDate = datetime.isSame(new Date(), "day");
    }
    if (isCurrentDate) {
      style.backgroundColor = "#118DEA";
      style.color = "white";
    }
    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: formattedItem.replace(/[0-9]/g, "<b>$&</b>"),
            }}
          />
        ))}
      </th>
    );
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    schedulerData.next();
    //console.log(schedulerContent);
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });

    const remainingScroll = maxScrollLeft - schedulerContent.scrollLeft;
    schedulerContent.scrollTo({
      left: maxScrollLeft - Math.min(remainingScroll, 10),
      behavior: "smooth",
    });
    schedulerContent.scrollLeft = maxScrollLeft - 10;
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    schedulerData.prev();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });

    schedulerContent.scrollTo({
      left: Math.min(schedulerContent.scrollLeft, 10),
      behavior: "smooth",
    });
    schedulerContent.scrollLeft = 10;
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    //console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    //console.log("onScrollBottom");
  };

  render() {
    const { viewModel } = this.state;
    let popover = <div />;
    if (this.state.headerItem !== undefined) {
      popover = (
        <AddMorePopover
          headerItem={this.state.headerItem}
          eventItemClick={this.eventClicked}
          schedulerData={viewModel}
          closeAction={this.onSetAddMoreState}
          left={this.state.left}
          top={this.state.top}
          height={this.state.height}
          moveEvent={this.moveEvent}
        />
      );
    }

    let leftCustomHeader = (
      <div style={{ display: "flex", flexDirection: "row" }} className="mx-1">
        {this.props.defaultFilter === "Schedule" &&
        localStorage.getItem("access_role_id") !== "8" ? (
          // Add person icon
          localStorage.getItem("access_role_id") === "2" ? (
            <AiIcons.AiOutlineUserAdd
              style={{
                marginRight: "8px",
                fontSize: "35px",
                marginLeft: "5px",
                color: "black",
                borderRadius: "10px",
                padding: "7px",
                cursor: "pointer",
                border: "1px solid #D3D3D3",
              }}
              onClick={this.openAddPersonModal}
            />
          ) : localStorage.getItem("access_role_id") === "3" &&
            localStorage.getItem("create_edit_people") === "Yes" ? (
            <AiIcons.AiOutlineUserAdd
              style={{
                marginRight: "8px",
                fontSize: "35px",
                marginLeft: "5px",
                color: "black",
                borderRadius: "10px",
                padding: "7px",
                cursor: "pointer",
                border: "1px solid #D3D3D3",
              }}
              onClick={this.openAddPersonModal}
            />
          ) : null
        ) : // Add Project Icon
        localStorage.getItem("access_role_id") !== "8" ? (
          <AiIcons.AiOutlineFolderAdd
            style={{
              marginRight: "8px",
              fontSize: "35px",
              marginLeft: "5px",
              color: "black",
              borderRadius: "10px",
              padding: "7px",
              cursor: "pointer",
              border: "1px solid #D3D3D3",
            }}
            onClick={this.openAddProjectModal}
          />
        ) : null}
        <div>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={this.props.filterEvents}
            value={this.props.defaultFilter}
          >
            <option value="Schedule">Schedule</option>
            <option value="Allocation">Project Plan</option>
            <option
              value="Logs"
              selected={this.props.defaultFilter === "Logs"}
              hidden
            >
              Log{" "}
            </option>
            <option
              value="Leaves"
              selected={this.props.defaultFilter === "Leaves"}
              hidden
            >
              Leaves
            </option>
          </select>
        </div>
        <div style={{ marginLeft: "5px" }}>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(event) =>
              this.props.handleFilterSize(event.target.value)
            }
            value={this.props.defaultFiltersize}
          >
            <option value="Compact"> &#9644; Compact</option>
            <option value="Comfortable"> &#9632; Comfortable</option>
            <option value="Spacious">&#9608; Spacious</option>
          </select>
        </div>
      </div>
    );

    //Custom function
    const updateEvent = (eventId, newEventObj) => {
      // //console.log(this.state.viewModel, 'sc-data');
      let currentSchedulerEvents = this.state.viewModel.events;
      //console.log(currentSchedulerEvents, 'sc-events');
      let foundEvent = currentSchedulerEvents.find((obj) => obj.id == eventId);
      if (foundEvent) {
        //console.log(foundEvent, 'foundEvent');
        const index = currentSchedulerEvents.findIndex(
          (obj) => obj.id === eventId
        );
        // alert(index);
        //console.log(currentSchedulerEvents[index], 'old-event');
        if (index !== -1) {
          // Use splice to remove the object from the array
          currentSchedulerEvents[index].bgColor = newEventObj.bgColor;
          currentSchedulerEvents[index].title = newEventObj.title;
          currentSchedulerEvents[index].project_name = newEventObj.project_name;
          currentSchedulerEvents[index].hoursPerDay = newEventObj.hoursPerDay;
          currentSchedulerEvents[index].notes = newEventObj.notes;
          currentSchedulerEvents[index].start = newEventObj.start;
          currentSchedulerEvents[index].end = newEventObj.end;

          this.state.viewModel.updateEventStart(
            currentSchedulerEvents[index],
            newEventObj.start
          );
          this.state.viewModel.updateEventEnd(
            currentSchedulerEvents[index],
            newEventObj.end
          );

          //console.log(currentSchedulerEvents[index], 'new-event');
        } else {
          alert(`Object with id ${eventId} not found.`);
        }
        let newSchedulerData = this.state.viewModel;
        newSchedulerData["events"] = currentSchedulerEvents;
        alert("found");

        // //console.log(foundEvent, 'foundevent');
        this.setState({ viewModel: newSchedulerData });
      } else {
        alert("not-found");
      }
    };

    function subDay(dateString) {}

    const handleModalAfterConfirm = () => {
      this.setState({
        scheduleAllocationModalShow: true,
        showConfirmModal: false,
      });
    };

    return (
      <div>
        {this.state.scheduleAllocationModalShow && (
          <AddScheduleAllocation
            show={this.state.scheduleAllocationModalShow}
            onHide={() => this.setState({ scheduleAllocationModalShow: false })}
            currentAddEvent={this.state.currentAddEvent}
            onEditProject={this.props.onSlotClick}
            persons={this.props.data}
            title={this.state.scheduleModalTitle}
            allocationObject={this.state.scheduleAllocationEditObj}
            current_calendar={this.props.defaultFilter}
            schedulerStart={this.state.schedulerStart}
            schedulerEnd={this.state.schedulerEnd}
            addNewEvent={this.newEvent}
            schedulerData={this.state.viewModel}
            updateEvent={(eventId, newEventObj) =>
              updateEvent(eventId, newEventObj)
            }
            nonWorkingDay={this.state.schedulingOnNonWorkingDay}
          />
        )}

        {this.state.editModalShow && (
          <AddProgressModal
            show={this.state.editModalShow}
            onHide={() => this.setState({ editModalShow: false })}
            currentAddEvent={this.state.currentAddEvent}
            addToView={(data) => this.setState({ viewModel: data })}
            modalTitle={this.state.modalTitle}
            taskTitle={this.state.taskTitle}
            hoursPerDay={this.state.hoursPerDay}
            notes={this.state.notes}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            task={this.state.task}
            allocationId={this.state.taskId}
            leave_id={this.state.leave_id}
            milestone_start={this.state.milestone_start}
            milestone_end={this.state.milestone_end}
            milestone_title={this.state.milestone_title}
            milestone_id={this.state.milestone_id}
            leave_status={this.state.leave_status}
            current_calendar={this.props.defaultFilter}
            schedulerStart={this.state.schedulerStart}
            schedulerEnd={this.state.schedulerEnd}
          />
        )}

        {this.state.modalShow && (
          <AddProgressModal
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
            currentAddEvent={this.state.currentAddEvent}
            addToView={(data) => this.setState({ viewModel: data })}
            modalTitle={
              localStorage.getItem("access_role_id") == "8"
                ? "Log Time"
                : "Add Allocation"
            }
            task={"add"}
            current_calendar={this.props.defaultFilter}
            schedulerStart={this.state.schedulerStart}
            schedulerEnd={this.state.schedulerEnd}
          />
        )}

        {this.state.addModalShow && (
          <ProjectModal
            show={this.state.addModalShow}
            onHide={() => {
              this.setState({ addModalShow: false });
            }}
          />
        )}

        {this.state.addPersonModalShow && (
          <PeopleModal
            show={this.state.addPersonModalShow}
            onHide={() => {
              this.setState({ addPersonModalShow: false });
            }}
          />
        )}

        {this.state.showtimeoff && (
          <Timeoff
            show={this.state.showtimeoff}
            onHide={() => {
              this.setState({ showtimeoff: false });
            }}
            currentAddEvent={this.state.currentAddEvent}
            timeOffObj={this.state.timeOffObj}
            persons={this.props.data}
            schedulerData={this.state.viewModel}
          />
        )}

        {this.state.showConfirmModal && (
          <ConfirmModal
            show={this.state.showConfirmModal}
            onHide={() => {
              this.setState({ showConfirmModal: false });
            }}
            onConfirm={() => handleModalAfterConfirm()}
          />
        )}

        {this.state.confirmDrag && (
          <ConfirmModal
            show={this.state.confirmDrag}
            onHide={() => {
              this.setState({ confirmDrag: false });
            }}
            onConfirm={() => alert("confirm")}
            bodyText={"Doy want to copy or move?"}
            btn1="Copy"
            btn2="Move"
          />
        )}

        {/* <div style={{marginTop: '10px'}}><h3>Schedule</h3></div> */}
        <div>
          <Scheduler
            //ref={this.schedulerRef}
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            eventItemLineHeight={24}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            leftCustomHeader={leftCustomHeader}
            slotItemTemplateResolver={this.slotItemTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={
              this.nonAgendaCellHeaderTemplateResolver
            }
            onSetAddMoreState={this.onSetAddMoreState}
          />
          {popover}
        </div>
      </div>
    );
  }
}

export default withDragDropContext(Basic);
