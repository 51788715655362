import React, { useState } from "react";

// ICONS
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import * as AiIcons from "react-icons/ai";

import { IconContext } from "react-icons";
import { useAuth } from '../../context/AuthContext';

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
import { SidebarData } from "./SidebarData";
import logoImage from '../../assets/images/logo_3.png';

// STYLES
import "./navbar.css";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const { isAuth } = useAuth();

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#FFF" }}>
        {/* All the icons now are white */}
      
        <div className="navbar" >
          <Link to="#" className="menu-bars" >
            <FaIcons.FaBars onClick={showSidebar}/>
          </Link>
          <img src={logoImage} style={{height: '60px', marginLeft: '10px'}}/>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path} >
                    {item.icon}
                    <span><span style={{marginLeft: '10px'}}>{item.title}
                      </span></span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

function PrivateNavbar() {
  const { isAuth } = useAuth();

  return isAuth ? <Navbar /> : null;
}

export default PrivateNavbar;
