import React, { useEffect, useState } from 'react';
import { Table, Button } from 'reactstrap';
import EditPeople from '../Modals/editpeople';
import apicall from '../../../apicall';
import * as AiIcons from "react-icons/ai";
import '../../../App.css';
// import ModalForm from '../../projects/Modals/Modal';

function PersonDataTable(props){
  const [modalShow, setModalShow] = useState(false);
  const [peopleData, setPeopleData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [curentRow, setRow] = useState('');

 
  //Toggle Modal
  const handleToggleModal = () => {
    setModalShow(!modalShow);
  };

  
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({
      'access_role_id' : localStorage.getItem('access_role_id'),
      'accessible_projects' : localStorage.getItem('accessible_projects')
    }),
  };


  useEffect(() => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/projects", requestOptions)
    .then((response) => response.json())
    .then((data) => {
        let projectsData = data.data;
        setProjects(projectsData);
    })
    .catch((error) => {
        console.error('Error fetching data from the API: ', error);
    });
}, []); 

  const editItem = (data) =>{
  if(localStorage.getItem('access_role_id') == 2){
    if(data.access_role_id == 2){
      if(localStorage.getItem('access_role_id' ) == 2){
        setPeopleData(data);
        setModalShow(true)
      }
   }
   else{
    setPeopleData(data);
    setModalShow(true)
   }
  }
  else{
    if(localStorage.getItem('create_edit_people') == 'Yes'){
      setPeopleData(data);
      setModalShow(true)
    }
  }

  }



  const getAccess = (value) =>{
    switch(value){
      case 2:
        return 'Admin';
      case 3:
        return 'Manager';
        case 8:
          return 'Member';
      default:
        return '-';
    }
  }

    const items = props.items.map(item => {
      return (
        <tr key={item.id} onClick={() => editItem(item)} className='datatable' onMouseEnter={() => setRow(item.id)}>
        {/*  <th scope="row">{item.id}</th> */}
        <td className='checkbox' onClick={(e) => e.stopPropagation()}><input type="checkbox" onChange={() => props.toggleProject(item)}  hidden = { ((localStorage.getItem('access_role_id') == 8 || localStorage.getItem('access_role_id') == 3) && item.access_role_id == 2) || (localStorage.getItem('access_role_id') == '3' && item.id == localStorage.getItem('person_id') ) || (localStorage.getItem('access_role_id') == '3' && localStorage.getItem('create_edit_people') != 'Yes')}/></td>
          <td style={{ padding: '12px' }}>{item.name}</td>
          <td>
            <a href='/schedule' style={{color: 'grey'}} >{curentRow == item.id && <AiIcons.AiOutlineAlignRight size={'22'}/>}</a>
          </td>
          <td>{item.role == 3 ? 'Manger' : 'Employee'}</td>
          <td>{item.department}</td>
          
          <td>{getAccess(item.access_role_id)}</td>
          <td>-</td>
          <td>
          {item.tags && JSON.parse(item.tags).map(item =>{
              return(
                <span key={item.id} style={{backgroundColor: "rgb(243, 242, 245)", padding: '3px', margin: '2px', borderRadius: '5px'}}>{item}</span>
              )
          })}
          </td> 
          <td>{item.person_type}</td>
          {/* <td>{item.email}</td> */}
        {/*  <td>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button color="warning" onClick={() => editItem(item)} style={{ marginRight: '10px' }} >Edit</Button>
              <Button color="danger">Del</Button>
            </div>
          </td> */}
        </tr>
      );
    });
      
    return (
      <div>
        {peopleData && <EditPeople  show={modalShow} onHide={handleToggleModal}  projects={projects} currentpeopledata = {peopleData}/>}
        <Table responsive hover>
          <thead style={{  position: 'sticky',top: '0', zIndex: '1'}}>
            <tr>
              {/* <th>ID</th> */}
              <th></th>
              <th>Name</th>
              <th></th>
              <th>Role</th>
              <th>Department</th>
              <th>Access</th>
              <th>Manages</th>
              <th>Tags</th>
              <th>Types</th>
            {/*  <th>Email</th> */}
            {/*   <th>Actions</th> */}
            </tr>
          </thead>
          <tbody >
            {items}
          </tbody>
        </Table>
      </div>
    );
  }  

export default PersonDataTable