import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col } from 'react-bootstrap';
import apicall from '../../../apicall';
import ChipInput from '../../../components/chipInput/ChipInput';
import '../../../App.css';
import PopoverComponent from '../../../components/modals/popover';
import MoreAction from '../../../components/modals/moreaction';
import ConfirmArchiveActive from '../../../components/modals/confirmArchiveActive';

let milestoneCounter = 1;
function formatDate(dateString) {
    if (!dateString) return ''; // Return an empty string if dateString is undefined or null
  
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
  
    return `${year}-${month}-${day}`;
  }
  
function EditProject(props) {

    const [persons, setPerson] = useState(props.persons);
    const [selectedPersons, setSelectedPerson] = useState([]);
    const [milestones, setMilestones] = useState();
    const [selectedTask, setSelectedTask] = useState(props.currentprojectdata.tasks ? JSON.parse(props.currentprojectdata.tasks): []);
    

    const [name, setName] = useState(props.currentprojectdata.name);
    const [color, setColor] = useState(props.currentprojectdata.color);
    const [startdate, setStartDate] = useState(props.currentprojectdata.start_date);
    const [enddate, setEndDate] = useState(props.currentprojectdata.end_date);
    // const [budget, setBudget] = useState(props.currentprojectdata.budget);
    const [description, setDescription] = useState(props.currentprojectdata.description);
    const [client, setClient] = useState(props.currentprojectdata.client);
    const [tags, setTags] = useState(props.currentprojectdata.tags ? JSON.parse(props.currentprojectdata.tags) : []);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);




    //To Update the value of selectedPersons State Variable everytime edit button is clicked
    useEffect(() => {
        let data  = props.currentprojectdata;
        setName(data.name);
        setSelectedTask(data.tasks ? JSON.parse(data.tasks) : []);
        setClient(data.client);
        setMilestones(data.milestones_detail);
        setColor(data.color);
        setDescription(data.description);
        setSelectedPerson(data.team_detail);
        setPerson(props.persons);
        setTags(data.tags ? JSON.parse(data.tags) : []);
        
    }, [props.show]);

    /* useEffect(() => {
        
    }, [props.currentprojectdata.team_detail]); */

    useEffect(() => {
        // Filter out persons whose id is in selectedPersons
        const filteredPersons = persons.filter(person => !selectedPersons.some(selectedPerson => selectedPerson.id == person.id));
        
        // Update the persons state with the filtered array
        setPerson(filteredPersons);
    }, [selectedPersons]);


    

    const onSelectPerson = (event) => {
        const selectedValue = event.target.value;
    
        // Find the selected person object from the persons array
        const selectedPersonObject = persons.find(person => person.name === selectedValue);
    
        if (selectedPersonObject) {
            // Remove the selected person from the persons state
            const updatedPersons = persons.filter(person => person !== selectedPersonObject);
            
            // Add the selected person to the selectedPersons state
            const updatedSelectedPersons = [...selectedPersons, selectedPersonObject];
    
            // Update the states
            setPerson(updatedPersons);
            setSelectedPerson(updatedSelectedPersons);
            //setSelectedPerson(""); // Clear the selected person value
        }
    }

    const managePerons = (event) => {
        const divElement = event.target.parentElement.parentElement;
        const pElement = divElement.querySelector('p');
        const personToRemove = pElement.getAttribute('id');
        
        //Removing from selected Persons by person id
        const selectedPersonObject = selectedPersons.find(person => person.id == personToRemove);
        const updatedSelectedPersons = selectedPersons.filter(person => person !== selectedPersonObject);

        const updatedPersons = [...persons, selectedPersonObject];

        setSelectedPerson(updatedSelectedPersons);
        setPerson(updatedPersons);



    }


    const manageMilestones = (event) => {
        let milestoneToRemove = event.target.parentElement.id;
    
    
        // Use the milestone ID to find the milestone object to remove
        let updatedMilestones = milestones.filter(mile => mile.id != milestoneToRemove); 
    
        setMilestones(updatedMilestones);
    }

    let addMilestone = (event) => {
        if(!document.getElementById('milestonename').value){
            alert('Milestone name is required');
        }
        else if(!document.getElementById('from').value){
            alert('Start date is required');
        }
        else if(!document.getElementById('to').value){
            alert('End date is required');
        }
        else{
            let name = document.getElementById('milestonename').value;
            let start = document.getElementById('from').value;
            let end = document.getElementById('to').value;
    
            let tempMilestoneId = 'm' + milestoneCounter;
            let newMilestone = {
                'id' : tempMilestoneId,
                'title' : name,
                'start' : start,
                'end' : end
            }
            milestoneCounter += 1;
    
            let updatedMilestones = [...milestones, newMilestone];
    
            setMilestones(updatedMilestones);
            document.getElementById('milestonename').value  = '';
            document.getElementById('from').value  = '';
            document.getElementById('to').value  = '';
            document.getElementById('to').disabled  = true;
        }

    }


   const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        let personData = JSON.stringify(selectedPersons);
        document.getElementById('peopleinput').setAttribute('value', personData);


        let milestoneData = JSON.stringify(milestones);
        document.getElementById('milestones').setAttribute('value', milestoneData);

        const formData = new FormData(event.target);

        const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/edit/project", {
            method: 'POST',
            body: formData,
        });

        if (apiResponse.ok) {
            // If the response status is OK (e.g., 200), parse the response body as JSON
            window.location.reload();
        } else {
            setIsSubmitting(false);
            // Handle errors or non-OK responses here
        }

    }

    const handleChange = (event) => {
        if(event.target.name == 'name'){
            setName(event.target.value);
        }
        if(event.target.name == 'color'){
            setColor(event.target.value);
        }
        if(event.target.name == 'start_date'){
            setStartDate(event.target.value);
        }
        if(event.target.name == 'end_date'){
            setEndDate(event.target.value);
        }
        // if(event.target.name == 'budget'){
        //     setBudget(event.target.value);
        // }
        if(event.target.name == 'description'){
            setDescription(event.target.value);
        }
        if(event.target.name == 'client'){
            setClient(event.target.value);
        }
    }


    const createTask = (event) => {
        if(document.getElementById('taskInput').value){

            const selectedTasksArray = selectedTask.map(item => item.toLowerCase());
            const newTaskString = document.getElementById('taskInput').value.toLowerCase();

        if(selectedTasksArray.includes(newTaskString)){
            alert('This task name already exists for this project. Please update and try again.')
        }
        else{
            let updatedSeletedTask = [...selectedTask , document.getElementById('taskInput').value];
            setSelectedTask(updatedSeletedTask);

            document.getElementById('tasks').value = JSON.stringify(updatedSeletedTask);
            }
        }
    }

    const removeTask = (event) => {
        if(event.target.getAttribute('value')){
            const updatedSelectedTasks = selectedTask.filter(item => item !== event.target.getAttribute('value'));
            setSelectedTask(updatedSelectedTasks);

            document.getElementById('tasks').value = JSON.stringify(updatedSelectedTasks);
            document.getElementById('tasks').value = JSON.stringify(updatedSelectedTasks);

        }
    }


    const deleteProject = async () => {
        setIsSubmitting(true);
        const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/delete/project', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',     
              },
            body: JSON.stringify({
                'selectedProject' : [props.currentprojectdata.id]
            }),
        });

        if(apiResponse.status === 200){
            setIsSubmitting(false);
            window.location.reload();
        }
        else{
            setIsSubmitting(false);
            alert('Some Error Occured');
        }

    }


    const markAllPeople = (event) => {
        document.getElementById('teamselect').hidden = event.target.checked;

        if(!event.target.checked)
        {
            document.getElementById('peopleinput').value = JSON.stringify([]);
            return;
        }

        let allPersons = [];
        
        persons.forEach((person) => {
            let personObj = {
                'id' : person.id,
                'name' : person.name
            }
            allPersons.push(personObj)
        });

        document.getElementById('peopleinput').value = JSON.stringify(allPersons);

    }
    return (
        <Form >
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static" // Prevents modal from closing when clicking outside
            keyboard={false}
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Project 
                </Modal.Title>
            </Modal.Header> */}
            <form onSubmit={handleSubmit} >
            <Modal.Body style={{minHeight: '535px'}}>
                <Tabs defaultActiveKey="title" id="event-tabs">
                    <Tab eventKey="title" title="Info">
                        {/* <Form method="POST" action={apicall.SERVER_URL_CA + "api/v1/add/project"} > */}
                            <br />
                            <input type="text"  value={props.currentprojectdata.id } name='project_id' hidden/>
                           {/*  <input type="text"  value={localStorage.getItem('name') } name='owner' hidden/> */}
                            <Form.Group >
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'name'}
                                    value={name || ''}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <br />
                            <Form.Group >
                                <Form.Label>Client</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'client'}
                                    value={client || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <br />
                            <Form.Group >
                                <Form.Label>Project Color</Form.Label>
                                <Form.Control
                                    type="color"
                                    name = {'color'}
                                    value = {color || ''}
                                    onChange = {handleChange}
                                    id="color-input"
                                    required
                                    hidden
                                />
                                <PopoverComponent setColorField= {setColor}  defaultColor = {props.currentprojectdata.color}/>
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'start_date'}
                                    value={formatDate(startdate) || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group> */}

                            {/* <Form.Group >
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'end_date'}
                                    value={formatDate(enddate) || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group> */}

                            {/* <Form.Group >
                                <Form.Label>Budget</Form.Label>
                                <Form.Control
                                    type="number"
                                    name = {'budget'}
                                    value={budget || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group> */}

                            <br />
                            <Form.Group >
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name = {'description'}
                                    value={description || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <br />
                            <Form.Group controlId="selectDropdown">
                                <Form.Label>Tags</Form.Label>
                                <ChipInput setTags={(value) => setTags(value)} tags={tags}/>
                                <input type="hidden" name="tags" value={tags}></input>
                            </Form.Group>
                      
                        {/* </Form> */}
                    </Tab>
                    <Tab eventKey="startDate" title="Team">
                    <br />
                    <Form.Group >
                            <Form.Label>Assign a Team Member</Form.Label>
                            <Form.Control as="select" id="teamselect" onChange={onSelectPerson}>
                                    <option>--Select a Team Member--</option>
                                    {persons && persons.length > 0 ? (
                                        persons.map((person) => (
                                            <option id={person.id} value={person.name}>{person.name}</option>
                                        ))
                                    ) : null}
                            </Form.Control>
                        </Form.Group>

                        <input type="text" placeholder='' id="peopleinput" name="team" hidden></input>
                        {selectedPersons.length === 0 ? 
                        <>
                        <input type="checkbox" id="all-people-checkbox" className="mt-3" style={{fontSize : '16px'}} onClick={(event) => markAllPeople(event)} />
                        <label for="all-people-checkbox" id="all-people-checkbox-label" className='mx-1' style={{fontSize : '16px'}} >Assign all people</label>
                        </>
                        : null}
                        <div className='mt-4 scrollable-team-div' id="teamDiv">
                            {selectedPersons && selectedPersons.map((person) => (
                                <div  style={{borderBottom: '1px solid #D3D3D3', paddingBottom : '12px' , paddingTop : '12px', display: 'flex', justifyContent: 'space-between' }}><p id={person.id}>{person.name} </p><span style={{float : 'right', marginRight: '12px', cursor: 'pointer'}} onClick={managePerons}><b>X</b></span> </div>
                            ))}
                        </div>
                    </Tab>


                    <Tab eventKey="endDate" title="Milestones">
                        {/* <Form> */}
                            <Form.Group>
                                <br />
                                <Form.Label>Milestone Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id='milestonename'
                                />
                            </Form.Group>
                        <br />
                        <Row>
                            <Col>
                            <p style={{ marginBottom : '0px' }}>From</p>    
                            <Form.Group >
                                <Form.Control
                                    type="date"
                                    name = {'from'}
                                    id="from"
                                    onChange={(e) => {
                                        const fromValue = e.target.value;
                                        const toInput = document.getElementById('to');
                                        if (fromValue) {
                                          toInput.disabled = false;
                                          toInput.min = fromValue; // Set the minimum date for "To" input
                                        } else {
                                          toInput.disabled = true;
                                        }
                                      }}
                                />
                            </Form.Group>
                            </Col>
                            <Col>
                            <p style={{ marginBottom : '0px' }}>To</p>
                            <Form.Group >
                                <Form.Control
                                    type="date"
                                    name = {'to'}
                                    id='to'
                                    disabled
                                />
                            </Form.Group>
                            </Col>
                            <Col> 
                            <input type="text" id="milestones" name="milestones" hidden/>
                            <p style={{color : 'transparent', marginBottom : '0px'}} > lestone</p>
                            <button className="btn btn-success" type="button" onClick={addMilestone}>Add Milestone</button>
                            </Col>
                        </Row>
                        {/* </Form>  */}
                        <div className="mt-4 scrollable-milestone-div" id="mileDiv" style={{ paddingRight: '10px'}}>
                             {milestones && milestones.map((milestones) => (
                                <div style={{borderBottom: '1px solid #D3D3D3', marginTop : '15px', paddingBottom: '10px'}}>
                                    <p style={{marginBottom: '2px', fontSize: '18px'}} className='text-primary'><b>{milestones.title}</b></p>
                                    <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                    <p style={{marginBottom: '0px'}}>{formatDate(milestones.start)} - {formatDate(milestones.end)}</p>
                                    <p style={{ cursor : 'pointer', fontSize : '16px'}} onClick={manageMilestones} id={milestones.id}><b>X</b></p>   
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Tab>

                         <Tab title="Task List" eventKey="TaskList">
                        <input type="text" id="tasks" name="tasks" value={JSON.stringify(selectedTask)} hidden  />
                        <br />
                        <br />
                        <div className="row mx-2">
                        <Form.Label>Add a task name</Form.Label>
                        </div>
                        <div className="row mx-2">
                        <div className="col-9">
                        <Form.Group >
                         <Form.Control
                             type="text"
                             id='taskInput'
                         />
                        </Form.Group>
                        </div>
                        <div className="col-3">
                        <Button className="btn-info" style={{width: '100%', color:'white'}} onClick={createTask}>Add Task</Button>
                        </div>
                        </div>

                        <div id="selectedTaskDiv" className='mx-4 mt-3 scrollable-team-div'>
                        {selectedTask && selectedTask.map((task) => (
                            <div style={{ borderBottom: '1px solid #D3D3D3', paddingTop : '12px', paddingBottom : '12px' }}>
                                    <p style={{marginBottom: '0px'}}>{task}<span style={{float : 'right', marginRight:'10px', 'cursor' : 'pointer'}} onClick={removeTask} value={task} >X</span></p>
                            </div>
                        ))}
                        </div>
                    </Tab> 
                        
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                <Button type='submit' disabled={isSubmitting}>{isSubmitting ? 'Update Project...' : 'Update Project'}</Button>
                <div hidden={isSubmitting}>
                    <MoreAction  delete = {setConfirmModalShow} />
                </div>
                <ConfirmArchiveActive  show={confirmModalShow} onHide={()=>{setConfirmModalShow(false)}} heading= {'Delete Project'} bodyText = {'Delete ' + name + '?'} onConfirm = {deleteProject} isSubmitting = {isSubmitting}/>
                <p className='btn btn-secondary' onClick={props.onHide} style={{right : "20px", position : "absolute"}} hidden={isSubmitting}>Close</p>
            </Modal.Footer>
            </form>
        </Modal>
        </Form>
    );

}

export default EditProject;
