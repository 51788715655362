import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import AddEditForm from "../Forms/FormAddEdit";
import apicall from "../../../apicall";
import EditProject from "./editproject";

function ModalForm(props) {
  const [modal, setModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [projectData, setProjectData] = useState([]);

  const toggle = async (event) => {
    if(!modalShow){
    let projctRow = event.target.closest('tr');
    let project_id =  projctRow.getAttribute('id');
      const requestData = {
        'project_id' : project_id,
        // Add any other data you need to send here
      };
  
      //call api to get details of project
      const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/get/project", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify(requestData),  //Add the key-value pair ' project_id : value ' here
    });

    if (apiResponse.status === 200) {
      const responseData = await apiResponse.json();
      setProjectData(responseData.message);
   }
    }
    
    setModalShow(!modalShow);

  };


  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );
  const label = props.buttonLabel;

  let button = "";
  let title = "";

  if (label === "Edit") {
    button = (
      <Button
        color="warning"
        onClick={toggle}
        style={{ float: "left", marginRight: "10px" }}
      >
        {label}
      </Button>
    );
    title = "Edit Project";
  } else {
    button = (
      <Button
        color="success"
        onClick={toggle}
        style={{ float: "left", marginRight: "10px" }}
      >
        {label}
      </Button>
    );
    title = "Add New Item";
  }

  return (
    <div>
      {button}
          <EditProject  show={modalShow} onHide={toggle}  currentprojectdata = {projectData}/>
    </div>
  );
}

export default ModalForm;
