import React from "react";
import { Form } from "react-bootstrap";
import * as BsIcons from "react-icons/bs";

function OTP(){

    return (
        <div
        style={{
            display: 'flex',
            marginTop : '150px',
            justifyContent: 'center',
          }}>
            <div  className='shadow' style={{ width: '500px', textAlign: 'center' ,border : '1px solid #d3d3d3', height: 'fit-content' , padding: '30px', borderRadius: '10px'}}>
            <BsIcons.BsShieldLockFill style={{ fontSize : '24px'}} className='text-primary mb-1'  />
            <h4 style={{ fontWeight: '600' }}>Password Recovery Code</h4>
            <p>We've sent a 4-digit password recovery code to your email. Enter the code to proceed</p>
            <form action="">
            <Form.Group>
            <Form.Label style={{ float: 'left' }}>Enter OTP</Form.Label>
            <Form.Control
                type="password"
                name={'otp'}
                maxLength="4"  
                required
                style = {{textAlign: 'center'}}
          />
            </Form.Group>
            <button className="btn btn-primary mt-3" style={{ width: '100%'}} type="submit">Reset Password</button>
          </form>
            </div>

        </div>
    )
}

export default OTP
