
import apiCall from '../../apicall';
import { useState, useEffect } from 'react'; 


// fetch(apiCall.SERVER_URL_CA+"api/v1/all/projects")
// .then((response) => response.json())
// .then((items) => setItems(items.message))

const DemoData = {
    resources: [
        {
            "id": "p1",
            "name": "Project 103",
            "groupOnly": true
        },
        {
            "id": 1,
            "name": "D",
            "parentId": "p1"
        },
        {
            "id": "p2",
            "name": "Project 104",
            "groupOnly": true
        },
        {
            "id": 3,
            "name": "DP",
            "parentId": "p2"
        },
        {
            "id": "p3",
            "name": "Project 105",
            "groupOnly": true
        },
        {
            "id": 4,
            "name": "Dharmaj",
            "parentId": "p3"
        },
        {
            "id": 4,
            "name": "Dharmaj",
            "parentId": "p2"
        }
    ],
    events: [
        {
            id: 1,
            start: '2023-08-18 09:30:00',
            end: '2023-08-19 23:30:00',
            resourceId: 'r1',
            title: 'I am finished',
            bgColor: '#D9D9D9',
            showPopover: false
        },
        {
            id: 2,
            start: '2023-08-18 12:30:00',
            end: '2023-08-26 23:30:00',
            resourceId: 'r2',
            title: 'I am not resizable',
            resizable: false
        },
        {
            id: 3,
            start: '2023-08-19 12:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r3',
            title: 'I am not movable',
            movable: false
        },
        {
            id: 4,
            start: '2023-08-19 14:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r4',
            title: 'I am not start-resizable',
            startResizable: false,
        },
        {
            id: 5,
            start: '2023-08-19 15:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r5',
            title: 'I am not end-resizable',
            endResizable: false
        },
        {
            id: 6,
            start: '2023-08-19 15:35:00',
            end: '2023-08-19 23:30:00',
            resourceId: 'r6',
            title: 'I am normal'
        },
        {
            id: 7,
            start: '2023-08-19 15:40:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r7',
            title: 'I am exceptional',
            bgColor: '#FA9E95'
        },
        {
            id: 8,
            start: '2023-08-19 15:50:00',
            end: '2023-08-19 23:30:00',
            resourceId: 'r1',
            title: 'I am locked',
            movable: false,
            resizable: false,
            bgColor: 'red'
        },
        {
            id: 9,
            start: '2023-08-19 16:30:00',
            end: '2023-08-27 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 1'
        },
        {
            id: 10,
            start: '2023-08-19 17:30:00',
            end: '2023-08-19 23:30:00',
            resourceId: 'r1',
            title: 'R1 has recurring tasks every week on Tuesday, Friday',
            rrule: 'FREQ=WEEKLY;DTSTART=20231219T013000Z;BYDAY=TU,FR',
            bgColor: '#f759ab'
        },
        {
            id: 11,
            start: '2023-08-19 18:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 3'
        },
        {
            id: 12,
            start: '2023-08-20 18:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 4'
        },
        {
            id: 13,
            start: '2023-08-21 18:30:00',
            end: '2023-08-24 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 5'
        },
        {
            id: 14,
            start: '2023-08-23 18:30:00',
            end: '2023-08-27 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 6'
        },
    ],
    eventsForTaskView: [
        {
            id: 1,
            start: '2023-08-18 09:30:00',
            end: '2023-08-18 23:30:00',
            resourceId: 'r1',
            title: 'I am finished',
            bgColor: '#D9D9D9',
            groupId: 1,
            groupName: 'Task1'
        },
        {
            id: 2,
            start: '2023-08-18 12:30:00',
            end: '2023-08-26 23:30:00',
            resourceId: 'r2',
            title: 'I am not resizable',
            resizable: false,
            groupId: 2,
            groupName: 'Task2'
        },
        {
            id: 3,
            start: '2023-08-19 12:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r3',
            title: 'I am not movable',
            movable: false,
            groupId: 3,
            groupName: 'Task3'
        },
        {
            id: 7,
            start: '2023-08-19 15:40:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r7',
            title: 'I am exceptional',
            bgColor: '#FA9E95',
            groupId: 4,
            groupName: 'Task4'
        },
        {
            id: 4,
            start: '2023-08-20 14:30:00',
            end: '2023-08-21 23:30:00',
            resourceId: 'r4',
            title: 'I am not start-resizable',
            startResizable: false,
            groupId: 1,
            groupName: 'Task1'
        },
        {
            id: 5,
            start: '2023-08-21 15:30:00',
            end: '2023-08-22 23:30:00',
            resourceId: 'r5',
            title: 'I am not end-resizable',
            endResizable: false,
            groupId: 3,
            groupName: 'Task3'
        },
        {
            id: 9,
            start: '2023-08-21 16:30:00',
            end: '2023-08-21 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks',
            groupId: 4,
            groupName: 'Task4'
        },
        {
            id: 6,
            start: '2023-08-22 15:35:00',
            end: '2023-08-23 23:30:00',
            resourceId: 'r6',
            title: 'I am normal',
            groupId: 1,
            groupName: 'Task1'
        },
        {
            id: 8,
            start: '2023-08-25 15:50:00',
            end: '2023-08-26 23:30:00',
            resourceId: 'r1',
            title: 'I am locked',
            movable: false,
            resizable: false,
            bgColor: 'red',
            groupId: 1,
            groupName: 'Task1'
        },
        {
            id: 10,
            start: '2023-08-26 18:30:00',
            end: '2023-08-26 23:30:00',
            resourceId: 'r2',
            title: 'R2 has many tasks',
            groupId: 4,
            groupName: 'Task4'
        },
        {
            id: 11,
            start: '2023-08-27 18:30:00',
            end: '2023-08-27 23:30:00',
            resourceId: 'r14',
            title: 'R4 has many tasks',
            groupId: 4,
            groupName: 'Task4'
        },
        {
            id: 12,
            start: '2023-08-28 18:30:00',
            end: '2023-08-28 23:30:00',
            resourceId: 'r6',
            title: 'R6 has many tasks',
            groupId: 3,
            groupName: 'Task3'
        },
    ],
    eventsForCustomEventStyle: [
        {
            "id": 7,
            "start": "2023-08-20 00:00:00",
            "end": "2023-08-21 00:00:00",
            "resourceId": 59,
            "title": "Event",
            "bgColor": "#FA9E95",
        },
        {
            "id": 1,
            "title": "Test 1",
            "start": "2023-08-20 00:00:00",
            "end": "2023-08-22 00:00:00",
            "resourceId": 48,
            "bgColor": "#488FAB"
        },
        {
            "id": 8,
            "start": "2023-03-01 00:00:00",
            "end": "2023-05-01 00:00:00",
            "resourceId": "p1",
            "title": "Milestone 2",
            "bgColor": "#FA9E95",
            "type": 1
        },
        /* {
            id: 8,
            start: '2023-08-19 15:50:00',
            end: '2023-08-19 23:30:00',
            resourceId: 'r1',
            title: 'I am locked',
            movable: false,
            resizable: false,
            bgColor: 'red',
            type: 2
        },
        {
            id: 9,
            start: '2023-08-19 16:30:00',
            end: '2023-08-27 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 1',
            type: 3
        },
        {
            id: 10,
            start: '2023-08-20 18:30:00',
            end: '2023-08-20 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 2',
            type: 1
        },
        {
            id: 11,
            start: '2023-08-21 18:30:00',
            end: '2023-08-22 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 3',
            type: 2
        },
        {
            id: 12,
            start: '2023-08-23 18:30:00',
            end: '2023-08-27 23:30:00',
            resourceId: 'r1',
            title: 'R1 has many tasks 4',
            type: 3
        }, */
    ],
}

export default DemoData
