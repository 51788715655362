import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CustomDropdown from '../customDropdown/customDropdown';
import apicall from '../../apicall';
import { useEffect } from 'react';
import MultiSelectDropdwon from '../chipInput/MultiSelectDropdown';
import MoreAction from './moreaction';

function Timeoff(props) {
    const [timeOffType, setTimeOffType] = useState( null);
    const [timeOffBgColor, setTimeOffBgColor] = useState( null);
    const [isSubmitting , setIsSubmitting] = useState(false);
    const [tentative , setTentative] = useState(props.timeOffObj ? props.timeOffObj.tentative === 'Yes' ? true : false: false);
    const [selectedValue, setSelectedValue] = useState(props.timeOffObj ? [{value: props.timeOffObj.resourceId, label: props.timeOffObj.slotName}] : props.currentAddEvent ?  [{value: props.currentAddEvent.slotId, label: props.currentAddEvent.slotName}]: null);
     
    //console.log(props.timeOffObj, 'toff');
    function removeTimeFromDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      //console.log(props.timeOffObj ? props.timeOffObj : null , 'timeoffobj');
      //console.log(props.currentAddEvent ? props.currentAddEvent : null , 'currentAddEvent');

      const handleSubmit = async (event) => {
            event.preventDefault();
            setIsSubmitting(true);

            const formData = new FormData(event.target);
            
            const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/edit/timeoff', {
                method: 'POST',
                body: formData,
            });

            if(apiResponse.status === 200){
                // window.location.reload();
                let responseData = await apiResponse.json();
                let foundEvent = props.schedulerData.events.find(obj => obj.id == props.timeOffObj.id);
                props.schedulerData.removeEvent(props.timeOffObj);
                Object.keys(selectedValue).forEach(key => {
                        let newEvent = {
                            id: responseData.allocation_ids[key],
                            title: document.getElementById('time_off_type').value.length > 0 ? document.getElementById('time_off_type').value : responseData.timeoff_type,
                            project_name : null,
                            start:document.getElementById('timeoff_start_date') ? document.getElementById('timeoff_start_date').value + ' 00:00:00' : props.timeOffObj.start,//addEventData.start,
                            end: document.getElementById('timeoff_end_date') ? addDay(document.getElementById('timeoff_end_date').value) : props.timeOffObj.end ,//addEventData.end,
                            endDateToDisplay : document.getElementById('timeoff_end_date') ? document.getElementById('timeoff_end_date').value : props.timeOffObj.end,
                            resourceId: selectedValue[key].value,
                            bgColor: document.getElementById('bg_color').value.length > 0 ? document.getElementById('bg_color').value : responseData.timeoff_bg_color, //document.getElementById('bg_color').value,
                            hoursPerDay : document.getElementById('timeoff_hours_per_day').value ,
                            type : 3 ,
                            tentative :document.getElementById('tentative') ?  document.getElementById('tentative').value : null,
                            notes : document.getElementById('notes').value,
                            person_name : selectedValue[key].label,
                            total_hours : props.timeOffObj.type && props.timeOffObj.type === 3 ? apicall.calculateTotalHours(props.currentAddEvent.start, addDay(props.currentAddEvent.end), document.getElementById('timeoff_hours_per_day').value) : '',
                         };
                         props.schedulerData.addEvent(newEvent);
                  });
                  setIsSubmitting(false);
                  props.onHide();
             }
            else{
                alert('Some Error Occured!');
                setIsSubmitting(false);
            }
      }

      function addDay(dateString){
        const inputDate = new Date(dateString);
        inputDate.setDate(inputDate.getDate() + 1);
        const outputDateString = inputDate.toISOString().split("T")[0] + " 00:00:00";
        return outputDateString
    }
      const handleStatus = (status) =>{
        if(status === 'decline'){
            document.getElementById('timeoff_status').value = 'Declined';
        }
        else{
            document.getElementById('timeoff_status').value = 'Approved';
        }

        document.getElementById('submit-btn').click();
      }

      const toggleTentative = () => {
        setTentative(!tentative)
      }

      function convertNameToLabel(inputArray) {
        // Iterate through the input array
        const newArray = inputArray.map(item => {
          // Clone the original object to avoid modifying the original array
          const newItem = { ...item };
          // Set the "label" property to the value of the "name" property
          newItem.label = newItem.name;
          newItem.value = newItem.id;
          // Remove the original "name" property if needed
          delete newItem.name;
          return newItem;
        });
        return newArray;
      }

      useEffect(()=>{
        setTentative(props.timeOffObj ? props.timeOffObj.tentative === 'Yes' ? true: false : false);
        setSelectedValue(props.timeOffObj ? [{value: props.timeOffObj.resourceId, label: props.timeOffObj.slotName}] : props.currentAddEvent ? [{value: props.currentAddEvent.slotId, label: props.currentAddEvent.slotName}]: null);
      },[props.show]);


      const deleteTimeOff = async () => {
        setIsSubmitting(true);
        const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/delete/timeoff', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'timeoff_id': props.timeOffObj ? props.timeOffObj.id : null
            })
        });
      
        if(apiResponse.status === 200){
            // window.location.reload();
            props.schedulerData.removeEvent(props.timeOffObj);
            props.onHide();
            setIsSubmitting(false);
        }
        else{
            alert('Some error occured');
            setIsSubmitting(false);
        }
    }
      
  const changeTimeOff = async (status) => {
    setIsSubmitting(true);
    document.getElementById('timeoff_status').value = status;

    const formElement = document.getElementById('form'); // replace 'yourFormId' with the actual ID of your form
    const formData = new FormData(formElement);


    const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/edit/timeoff/status', {
      method: 'POST',
      body: formData,
    });

    if(apiResponse.status === 200){
      window.location.reload();
    }
    else{
      alert('Some Error Occured');
      setIsSubmitting(false);
    }

  }

  const handleHoursPerDay = (event) => {
    if(event.target.value <= 0){
      event.target.value = 1;
    }
    else if(event.target.value > 8){
        event.target.value = 8;
    }
  }
  return (
    <>
      <Modal 
       show={props.show}
       onHide={props.onHide}  
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       backdrop="static" // Prevents modal from closing when clicking outside
       keyboard={false}
     >
        {/* <Modal.Header closeButton>
          <Modal.Title>Time Off</Modal.Title>
        </Modal.Header> */}
        <form onSubmit={handleSubmit} id="form">
        <Modal.Body>
        <input type="text" name='time_off_type' id="time_off_type" defaultValue={timeOffType} hidden />
        <input type="text" name='bg_color' id="bg_color" defaultValue={timeOffBgColor} hidden />
        <input type='text' value={props.timeOffObj ? props.timeOffObj.person_id : ''} name='person_id' hidden />
        <input type='text' value={localStorage.getItem('person_id')} name='edit_by' hidden/>

        <div className="row mt-1">
            <div className='col' >
                    <Form.Label style={{ marginBottom : '0px'}}>Hours Per Day</Form.Label>
                    <Form.Control
                    type="number"
                    name = {'timeoff_hours_per_day'}
                    id = {'timeoff_hours_per_day'}
                    defaultValue={ props.timeOffObj ? props.timeOffObj.hoursPerDay : '8'}
                    min = {'1'}
                    max = {'8'}
                    onBlur = {handleHoursPerDay}
                    required
                    />
              </div>
              <div className="col">
              <Form.Label style={{ marginBottom : '0px'}}>Total Hours</Form.Label>
              <Form.Control
                    type="number"
                    name = {'total_hours'}
                    defaultValue={ props.timeOffObj ? props.timeOffObj.total_hours : '8'}
                    required
                    disabled
                    />
              </div>
              <div className="col">
              <Form.Label style={{ marginBottom : '0px'}}>Start Date</Form.Label>
              <Form.Control
                type="date"
                name = {'timeoff_start_date'}
                id = {'timeoff_start_date'}
                defaultValue={props.timeOffObj ? removeTimeFromDate(props.timeOffObj.start) : props.currentAddEvent ? removeTimeFromDate(props.currentAddEvent.start): null}
                required
                onChange={(e) => {
                    const fromValue = e.target.value;
                    const toInput = document.getElementById('timeoff_end_date');
                    if (fromValue) {
                      toInput.min = fromValue; // Set the minimum date for "To" input
                    } 
                  }}
                /> 
              </div>
              <div className="col">
              <Form.Label style={{ marginBottom : '0px'}}>End Date</Form.Label>
              <Form.Control
                type="date"
                name = {'timeoff_end_date'}
                id="timeoff_end_date"
                min = {props.timeOffObj ? props.timeOffObj.endDateToDisplay? props.timeOffObj.endDateToDisplay : removeTimeFromDate(props.timeOffObj.end) : removeTimeFromDate(props.currentAddEvent.end)}
                defaultValue={props.timeOffObj ? props.timeOffObj.endDateToDisplay? props.timeOffObj.endDateToDisplay : removeTimeFromDate(props.timeOffObj.end) : removeTimeFromDate(props.currentAddEvent.end)}
                required
                />
              </div>
        </div>
        
        <div className='mt-4'>
        {!props.opened_from_notification ?
        <>
        <Form.Label style={{ marginBottom : '0px'}}>Time Off</Form.Label>
        <CustomDropdown setTimeOffType={setTimeOffType} setTimeOffBgColor = {setTimeOffBgColor} currentLeave = {props.timeOffObj ? props.timeOffObj.title : null}> </CustomDropdown></> : 
        <h5>{props.timeOffObj.title}</h5>
        }
        {localStorage.getItem('access_role_id') != '8' && !props.opened_from_notification? 
        <>
          <button type="button" className={tentative ? 'btn btn-info text-light mt-2' : 'btn btn-light mt-2' } onClick={toggleTentative}>Tentative</button>
          <input value={tentative ? 'Yes' : 'No'} type='text' name="tentative" id="tentative" hidden></input> </>: 
        <input value={'Yes'} type='text' name="tentative" id="tentative" hidden></input> 
        }
        </div>


        <div className="row mt-4 mx-1" >
        <Form.Label style={{ marginBottom : '0px',  paddingLeft: '0px'}}>{!props.opened_from_notification ? 'Notes' : 'Response'}</Form.Label>
            <Form.Control
                as="textarea"
                id='notes'
                name = {!props.opened_from_notification ? 'notes': 'response'}
                defaultValue={!props.opened_from_notification ? props.timeOffObj ? props.timeOffObj.notes : '':props.timeOffObj.response }
            />
        </div> 


        <p style={{ fontSize: '18px', fontWeight: '600' }} className='mt-2'>
        {props.timeOffObj && props.timeOffObj.approved_declined_by ? (
          <sup className='text-danger'>*</sup>
       ) : (
        ''
       )}
      {props.timeOffObj ? props.timeOffObj.approved_declined_by || '' : ''}
    </p>



        <div className='mt-3'>
        {localStorage.getItem('access_role_id') !== '8' ? (
        <>
          {!props.opened_from_notification ? (
            <>
            <Form.Label>Assigned to</Form.Label>
              <MultiSelectDropdwon
                personsOptions={props.persons ? convertNameToLabel(props.persons) : null}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
              <input type="text" name="assigned_to" value={JSON.stringify(selectedValue)} hidden />
            </>
          ) : null}
        </>
      ) : null}

        </div>

        </Modal.Body>
        <Modal.Footer>
        <input type="text" name='timeoff_id' value={props.timeOffObj ? props.timeOffObj.id : null} hidden />
        {/* // <>
        // <button id='submit-btn' hidden></button>
        // <input type="text" name="timeoff_status" id={'timeoff_status'} defaultValue={'Approved'} hidden/>
        // <Button variant="primary" type="button" onClick={() => handleStatus('approve')} disabled={isSubmitting}>{isSubmitting ? 'Approve'+'...' : 'Approve'}</Button>
        // <Button variant="outline-danger" type="button" onClick={() => handleStatus('decline')} disabled={isSubmitting}>{isSubmitting ? 'Decline'+'...' : 'Decline'}</Button>
        // </>:   */}
        { !props.opened_from_notification ? 
        <>
        <button className='btn btn-primary' type='submit' disabled={isSubmitting}>{isSubmitting ? 'Edit Time Off...': 'Edit Time Off'}</button>
        <div hidden={isSubmitting}>
        <MoreAction delete={deleteTimeOff}/>
        </div>
        <p className='btn btn-secondary' onClick={props.onHide} style={{right : "20px", position : "absolute"}} hidden={isSubmitting}>Close</p>
        </>:
        <>
        <button id='submit-btn' hidden></button>
        <input type="text" name="timeoff_status" id={'timeoff_status'} defaultValue={'approved'} hidden />
        <button className='btn btn-primary' type="button" onClick={() => changeTimeOff('approved')} disabled={isSubmitting}>{isSubmitting ? 'Approve...' : 'Approve'}</button>
        <button className='btn btn-outline-danger' type="button" onClick={() => changeTimeOff('declined')} disabled={isSubmitting}>{isSubmitting ? 'Decline...' : 'Decline'}</button>
        <p className='btn btn-secondary' onClick={props.onHide} style={{right : "20px", position : "absolute"}} hidden={isSubmitting}>Close</p>
        </>
      }
        </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Timeoff;