import React, { useState } from "react";
import { SidebarData } from "./SidebarData";
import NotificationPopover from "../modals/notificationpopover";
import { useAuth } from "../../context/AuthContext";
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import '../../App.css'


function Sidebar(props) {

  const [activeRoute, setActiveRoute] = useState(props.activeRoute);
  const [showNotificationPopover, setShowNotificationPopover] = useState(false);
  const {showBadge, toogleToast, setShowBadge} = useAuth();



  const checkItem = (item) => {
    let popoverElement = document.getElementById('Popover2');
    // If clicked on logout
    if (item.path == '/') {
      localStorage.clear();
    }

    // If clicked on Notification
    if (item.path === '#') {
      if (popoverElement) {
        if (!popoverElement.classList.contains('activeicon')) {
          popoverElement.classList.add('activeicon');
          setShowNotificationPopover(true);
        } else {
          popoverElement.classList.remove('activeicon');
          setShowNotificationPopover(false);
        }
      }
    } else {
      popoverElement.classList.remove('activeicon');
      setShowNotificationPopover(false);
    }


    if(item.name === "notification")
    setShowBadge(false);
  }

  const removeActiveClass = () => {
    let popoverElement = document.getElementById('Popover2');
    popoverElement.classList.remove('activeicon');
  }

  return (
    <div style={{ marginLeft: '5px' }}>
      {SidebarData.map((item, index) => {
        return (
          <a href={item.path} style={{ textDecoration: 'none', position: 'relative' }} key={item.path} onClick={() => checkItem(item)}>
            <p style={{ color: '#374151', fontSize: '24px', marginBottom: '0px' }} id={item.path == '#' ? 'Popover2' : ''} className={activeRoute === item.title ? 'activeicon' : 'iconhov'}>
              {item.icon}{item.path == '#' && <></>}
              {item.name === 'notification' ? showBadge ? <span style={{ position: 'absolute', top: 8, right: 12, backgroundColor: 'red', borderRadius: '50%', width: '8px', height: '8px' }}></span> : null : null}
            </p>
            <p style={{ color: '#374151', fontSize: '10px' }}>{item.title}</p>
            {item.hr && <hr style={{ marginTop: '20px' }} />}
          </a>
        );
      })}
      {showNotificationPopover && <NotificationPopover showPopover={showNotificationPopover} removeactiveclass={() => removeActiveClass()} />}
    </div>
  );
}



export default Sidebar;
