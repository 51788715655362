import React, { useEffect, useState } from 'react'
import { Table, Button } from 'reactstrap';
import ModalForm from '../Modals/Modal'
import EditProject from '../Modals/editproject';
import apicall from '../../../apicall';
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";


function calculateProjectDates(milestones) {
  if (milestones.length === 0) {
    return { startDate: '-', endDate: '-' };
  }

  // Sort the milestones by start date in case they are not already sorted.
  milestones.sort((a, b) => new Date(a.star) - new Date(b.end));

  const startDate = milestones[0].start;
  const endDate = milestones[milestones.length - 1].end;

  return { startDate, endDate };
}

function removeTimeFromDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.log("Invalid date string:", dateString);
    return '-'; // or throw an error, depending on your use case
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function DataTable(props){
  const [modalShow, setModalShow] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [persons, setPerson] = useState([]);
  const [curentRow, setRow] = useState('');


  const handleToggleModal = () => {
    setModalShow(!modalShow);
  };

  const editItem = (data)=>{
    setProjectData(data);
    if(localStorage.getItem('access_role_id') == '3' && localStorage.getItem('can_manage_project') == 'Yes'){
        setModalShow(true)
    }
    else if(localStorage.getItem('access_role_id') == '2'){
      setModalShow(true);
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({
      'access_role_id' : localStorage.getItem('access_role_id'),
      'accessible_projects' : localStorage.getItem('accessible_projects')
    }),
  };

  useEffect(() => {
        fetch(apicall.SERVER_URL_CA + "api/v1/all/persons", requestOptions)
        .then((response) => response.json())
        .then((data) => {
            let personData = data.data;
            setPerson(personData);
        })
        .catch((error) => {
            console.error('Error fetching data from the API: ', error);
        });
}, []); 


  const deleteItem = id => {
    let confirmDelete = window.confirm('Delete item forever?')
    if(confirmDelete){
      fetch('http://localhost:3000/crud', {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id
      })
    })
      .then(response => response.json())
      .then(item => {
        props.deleteItemFromState(id)
      })
      .catch(err => console.log(err))
    }
  }

  const items = props.items.map(item => {
    const projectDates = calculateProjectDates(item.milestones_detail);
    return (
      <tr key={item.id} id={item.id} onClick={() => editItem(item)} onMouseEnter={() => setRow(item.id)} className='datatable'>
       {/*  <th scope="row">{item.id}</th> */}
        <td className='checkbox' onClick={(e) => e.stopPropagation()}><input type="checkbox" onChange={() => props.toggleProject(item)} /></td>
        <td style={{ padding: '12px' }}>{item.name}</td>
        <td  onClick={(e) => e.stopPropagation()}>
          <a  style={{color: 'grey', marginRight: '5px'}}  onClick={() => 
            editItem(item)}>{curentRow == item.id && <AiIcons.AiOutlinePlus size={'22'}/>}</a>
          <a href='/schedule' style={{color: 'grey'}} >{curentRow == item.id && <AiIcons.AiOutlineAlignRight size={'22'}/>}</a>
        </td>
        <td>{item.client}</td>
        <td>{item.description && <FiIcons.FiFolder color='grey'/>}</td>
        <td>
         {item.tags && JSON.parse(item.tags).map(item =>{
            return(
              <span key={item} style={{backgroundColor: "rgb(243, 242, 245)", padding: '3px', margin: '2px', borderRadius: '5px'}}>{item}</span>
            )
         })}
        </td> 
        {/* <td>${item.budget}</td> */}
        <td>{removeTimeFromDate(projectDates.startDate)}</td> 
        <td>{removeTimeFromDate(projectDates.endDate)}</td>
        <td>
          <div style={{borderRadius: '25px', backgroundColor: item.color, width: '50px', padding: '10px', textAlign: 'center'}}>
            {item.project_owner && item.project_owner.charAt(0)}
          </div>
       </td>
      </tr>
      )
  })

    

  return (
    <div>
      {projectData && <EditProject  show={modalShow} onHide={handleToggleModal}  persons={persons} currentprojectdata = {projectData}/>}
    <Table responsive hover>
      <thead style={{  position: 'sticky',top: '0', zIndex: '1'}}>
        <tr>
          {/* <th>ID</th> */}
          <th></th>
          <th>Project</th>
          <th></th>
          <th>Client</th>
          <th></th>
          {/* <th>Color</th> */}
          <th></th>
          <th>Start</th>
          <th>End</th>
          <th>Owner</th>
         {/*  <th>Actions</th> */}
        </tr>
      </thead>
      <tbody>
        {items}
      </tbody>
    </Table>
    </div>
  )
}

export default DataTable