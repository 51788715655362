import React, { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Basic from './pages/schedule/Basic';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import login from './pages/login/login';
import { useState } from 'react';
import { AuthProvider } from './context/AuthContext';
import PrivateNavbar from './components/navbar/Navbar';
import Projects from './pages/projects/Projects';
import People from './pages/people/People';
import Calendar from './pages/schedule/index';
import Settings from './pages/settings/Settings';
import RecoverPassword from './pages/recover/recoverpassword';
import OTP from './pages/recover/otp';
import ToastMsg from './components/modals/toastmsg';





function App() {
  return (
    <>
    <AuthProvider>
    <ToastMsg />
      <div className="App">
        <Router> 
          {/* <PrivateNavbar  />     */}
          <Switch>
            <Route path="/" exact component={login} />
            <Route path="/schedule" component={Calendar} />
            <Route path="/projects" component={Projects} />
            <Route path="/reports" component={Basic} />
            <Route path="/people" component={People} />
            <Route path="/settings" component={Settings} />
            <Route path="/recover-password" component={RecoverPassword} />
            <Route path="/enter-otp" component={OTP} />
          </Switch>
        </Router>
        {/* <Basic /> */}
      </div>
    </AuthProvider>  
    </>  
  );
}

export default App;
