import React, { useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import * as AiIcons from "react-icons/ai";

function MoreAction(props) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const togglebtndropdown = () => {
        setDropdownOpen(!dropdownOpen);
    } 
    return (
        <div className="mx-3">
        <ButtonDropdown isOpen={dropdownOpen} direction="up" toggle={togglebtndropdown}  style={{width : '100%'}}>
          <DropdownToggle caret color="light">
            {props.isSubmitting ? 'More Action...' : 'More Action'}
          </DropdownToggle>
          <DropdownMenu style={{paddingTop : '0px', paddingBottom: '0px'}}>
            <DropdownItem className = 'moreaction-item' onClick={props.delete}><span style={{ verticalAlign : 'text-bottom' , paddingTop: '0px !important', paddingBottom: '0px !important'}} ><AiIcons.AiOutlineDelete style={{marginRight :'5px', verticalAlign : 'middle'}} className="text-danger"/></span>Delete</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>

        </div>
    )
}

export default MoreAction