import React, { useEffect } from "react";
import { useState } from "react";
import Chips from 'react-chips';

const ChipInput = (props) => {
    const [chips, setChips] = useState(props.tags ? props.tags : []);

    useEffect(() =>{
        setChips(props.tags);
    }, [props.tags])

    const onChange = chips => {
        setChips(chips);
        props.setTags(chips);
    }


    return(
        <Chips
            value={chips}
            onChange={onChange}
            createChipKeys={[13, 32]}
            //suggestions={["Your", "Data", "Here"]}
        />

    )

}

export default ChipInput;
