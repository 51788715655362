import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SearchableDropdown from "../searchDropdown/SearchableDropdown";
import "../../App.css";
import MultiSelectDropdwon from "../chipInput/MultiSelectDropdown";
import apicall from "../../apicall";
import MoreAction from "./moreaction";
import CustomDropdown from "../customDropdown/customDropdown";

// Define a functional component
function AddScheduleAllocation(props) {
  // //console.log(, 'x1');
  const [tasks, setTask] = useState([]);
  const [eventObj, setEventObj] = useState(props.allocationObject);
  const [value, setValue] = useState(eventObj ? eventObj.title : "");
  const [allProjects, setAllProjects] = useState(
    props.allocationObject
      ? props.allocationObject.projects
      : props.currentAddEvent.projects
      ? props.currentAddEvent.projects
      : []
  );
  const [selectedValue, setSelectedValue] = useState(
    eventObj
      ? [{ value: eventObj.resourceId, label: eventObj.slotName }]
      : [
          {
            value: props.currentAddEvent.slotId,
            label: props.currentAddEvent.slotName,
          },
        ]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tentative, setTentative] = useState(false);
  const [timeOffType, setTimeOffType] = useState(null);
  const [timeOffBgColor, setTimeOffBgColor] = useState(null);
  const [apiUrl, setApiUrl] = useState(
    props.nonWorkingDay
      ? "api/v1/add/scheduleallocation"
      : "api/v2/add/scheduleallocation"
  );
  const [selectedTab, setSelectedTab] = useState("allocation");
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [sectionTitle, setSectionTitle] = useState(
    props.title ? props.title : ""
  );

  // const [btnTitle, setBtnTitle] = useState('Add Allocation');

  ////console.log(eventObj, 'event-obj-this');
  ////console.log(props.schedulerData, 'schedulerData');
  ////console.log(props.nonWorkingDay, 'non-w');

  useEffect(() => {
    setEventObj(props.allocationObject);
    ////console.log(props.allocationObject, 'alloc-obj');
  }, [props.allocationObject]);

  useEffect(() => {
    //console.log(allProjects[0] , 'all');
    if (eventObj) {
      const foundObject = allProjects.find(
        (item) => item.id === eventObj.project_id
      );
      createTaskList(foundObject && foundObject.id ? foundObject.id : null);
    } else {
      createTaskList(
        allProjects[0] && allProjects[0].id ? allProjects[0].id : null
      );
    }
  }, [allProjects]);

  function convertNameToLabel(inputArray) {
    // Iterate through the input array
    const newArray = inputArray.map((item) => {
      // Clone the original object to avoid modifying the original array
      const newItem = { ...item };
      // Set the "label" property to the value of the "name" property
      newItem.label = newItem.name;
      newItem.value = newItem.id;
      // Remove the original "name" property if needed
      delete newItem.name;
      return newItem;
    });
    return newArray;
  }

  const openProjectModal = (event) => {
    let projectId = document.getElementById("projectselect").value;
    projectId = "p" + projectId;
    let slot = {
      slotId: projectId,
    };
    props.onEditProject(slot);
  };

  const createTaskList = async (projectId) => {
    if (projectId) {
      const foundProject = allProjects.find(
        (project) => project.id == projectId
      );
      if (foundProject.name) {
        document.getElementById("proj-title").value = foundProject.name;
      }
      const tasksArray = [];

      if (foundProject.tasks) {
        JSON.parse(foundProject.tasks).forEach((task) => {
          const tempTask = {
            id: task,
            name: task,
          };

          tasksArray.push(tempTask);
        });
      }

      setTask(tasksArray);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    let weekDaysdates = [];
    let formData = new FormData(event.target);
    if (!props.nonWorkingDay) {
      weekDaysdates = generateWeekdayDates(
        formData.get("start_date"),
        formData.get("end_date")
      );
    } else {
      weekDaysdates = [
        {
          start: formData.get("start_date"),
          end: formData.get("end_date"),
        },
      ];
    }

    // //console.log(weekDaysdates, 'weekdaysDates');
    // setIsSubmitting(false);
    // return;
    formData.append("weekDaysdates", JSON.stringify(weekDaysdates));
    if (selectedValue.length == 0) {
      if (selectedValue.length == 0) {
        if (document.getElementById("error-text")) {
          document.getElementById("error-text").style.display = "block";
        }
        setIsSubmitting(false);
      }
    } else {
      let apiurl = "";
      if (eventObj) {
        apiurl = "api/v2/edit/scheduleallocation";
      } else {
        apiurl = apiUrl;
      }
      if (document.getElementById("error-text")) {
        document.getElementById("error-text").style.display = "none";
      }

      if (apiurl === "api/v1/add/timeoff") {
        if (!timeOffType || !timeOffBgColor) {
          document.getElementById("timeoff-error-text").style.display = "block";
          setIsSubmitting(false);
          return;
        } else {
          document.getElementById("timeoff-error-text").style.display = "none";
        }
      }

      const apiResponse = await fetch(apicall.SERVER_URL_CA + apiurl, {
        method: "POST",
        body: formData,
      });

      if (apiResponse.status === 200) {
        let responseData = await apiResponse.json();
        //console.log(responseData, 'res-data-1');
        console.log(apiUrl + " api ");
        setIsSubmitting(false);
        props.current_calendar
          ? localStorage.setItem("current_calendar", props.current_calendar)
          : localStorage.setItem("current_calendar", null);
        props.schedulerEnd
          ? localStorage.setItem("scheduler_end", props.schedulerEnd)
          : localStorage.setItem("scheduler_end", null);
        props.schedulerStart
          ? localStorage.setItem("scheduler_start", props.schedulerStart)
          : localStorage.setItem("scheduler_start", null);

        if (
          apiurl === "api/v2/add/scheduleallocation" ||
          apiurl === "api/v1/add/timeoff" ||
          apiurl === "api/v1/add/scheduleallocation"
        ) {
          weekDaysdates.forEach((weekdayObj) => {
            Object.keys(selectedValue).forEach((key) => {
              let newEvent = {
                id: responseData.allocation_ids[key],
                title: apiurl.includes("scheduleallocation")
                  ? document.getElementsByName("title")[0].value
                  : document.getElementById("time_off_type").value,
                project_name: apiurl.includes("scheduleallocation")
                  ? document.getElementById("proj-title").value
                  : "",
                start: apiurl.includes("scheduleallocation")
                  ? weekdayObj.start + " 00:00:00"
                  : document.getElementById("timeoff_start_date").value +
                    " 00:00:00", //addEventData.start,
                end: apiurl.includes("scheduleallocation")
                  ? addDay(weekdayObj.end)
                  : addDay(document.getElementById("timeoff_end_date").value), //props.currentAddEvent.end ,//addEventData.end,
                endDateToDisplay: apiurl.includes("scheduleallocation")
                  ? weekdayObj.end
                  : document.getElementById("timeoff_end_date").value,
                resourceId: selectedValue[key].value,
                bgColor: responseData.bgColor
                  ? responseData.bgColor
                  : document.getElementById("time_off_bg_color").value,
                hoursPerDay: apiurl.includes("scheduleallocation")
                  ? document.getElementById("hours_per_day").value
                  : document.getElementById("timeoff_hours_per_day").value,
                type: apiurl.includes("scheduleallocation") ? 9 : 3,
                tentative: apiurl.includes("scheduleallocation")
                  ? null
                  : document.getElementById("tentative").value,
                notes: apiurl.includes("scheduleallocation")
                  ? document.getElementById("notes")
                    ? document.getElementById("notes").value
                    : ""
                  : document.getElementById("timeoff_notes")
                  ? document.getElementById("timeoff_notes").value
                  : "",
                person_name: selectedValue[key].label,
                total_hours: apiurl.includes("scheduleallocation")
                  ? ""
                  : apicall.calculateTotalHours(
                      props.currentAddEvent.start,
                      addDay(props.currentAddEvent.end),
                      document.getElementById("timeoff_hours_per_day").value
                    ),
                project_id: document.getElementById("projectselect").value,
                freshEvent: true,
              };

              if (localStorage.getItem("access_role_id") == "8") {
                newEvent.person_id =
                  localStorage.getItem(
                    "person_id"
                  ) /* Add the logic to get or set the person_id */;
              }
              //console.log(apiurl, 'apiurl');
              //  console.log(newEvent, 'newevent');
              props.currentAddEvent.schedulerData.addEvent(newEvent);
            });
          });
        } else {
          //console.log(props.currentAddEvent, 'current');
          let foundEvent = props.schedulerData.events.find(
            (obj) => obj.id == eventObj.id
          );
          props.schedulerData.removeEvent(eventObj);
          weekDaysdates.forEach((weekdayObj) => {
            Object.keys(selectedValue).forEach((key) => {
              let newEvent = {
                id: responseData.allocation_ids[key],
                title: document.getElementsByName("title")[0].value,
                project_name:
                  document.getElementById("projectselect").options[
                    document.getElementById("projectselect").selectedIndex
                  ].text,
                start: weekdayObj.start
                  ? weekdayObj.start + " 00:00:00"
                  : eventObj.start, //addEventData.start,
                end: weekdayObj.end ? addDay(weekdayObj.end) : eventObj.end, //addEventData.end,
                endDateToDisplay: weekdayObj.end,
                resourceId: selectedValue[key].value,
                bgColor: responseData.bgColor
                  ? responseData.bgColor
                  : "#d3d3d3",
                hoursPerDay: document.getElementById("hours_per_day").value,
                type: apiurl === "api/v2/edit/scheduleallocation" ? 9 : 3,
                tentative: document.getElementById("tentative")
                  ? document.getElementById("tentative").value
                  : null,
                notes: document.getElementById("notes").value,
                person_name: selectedValue[key].label,
                total_hours:
                  eventObj.type && eventObj.type === 3
                    ? apicall.calculateTotalHours(
                        props.currentAddEvent.start,
                        addDay(props.currentAddEvent.end),
                        document.getElementById("timeoff_hours_per_day").value
                      )
                    : "",
                project_id: document.getElementById("projectselect").value,
              };
              props.schedulerData.addEvent(newEvent);
            });
          });
        }
        props.onHide();
      } else {
        alert("Error Occured");
        for (let pair of formData.entries()) {
          // pair is an array [key, value]
          console.log(pair[0] + ": " + pair[1]);
        }
        setIsSubmitting(false);
      }
    }
  };

  function addDay(dateString) {
    const inputDate = new Date(dateString);
    inputDate.setDate(inputDate.getDate() + 1);
    const outputDateString =
      inputDate.toISOString().split("T")[0] + " 00:00:00";
    return outputDateString;
  }

  function subDay(dateString) {
    const inputDate = new Date(dateString);
    inputDate.setDate(inputDate.getDate() - 1);
    const outputDateString =
      inputDate.toISOString().split("T")[0] + " 23:59:59";
    return outputDateString;
  }

  function removeTimeFromDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const deleteAllocation = async () => {
    if (eventObj) {
      setIsSubmitting(true);
      //console.log(eventObj.id);
      const apiResponse = await fetch(
        apicall.SERVER_URL_CA + "api/v1/edit/deletescheduleallocation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            allocation_id: eventObj.id,
          }),
        }
      );

      if (apiResponse.status === 200) {
        props.allocationObject.schedulerData.removeEvent(
          props.allocationObject
        );
        props.onHide();
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        alert("Some Error Occured");
      }
    }
  };

  const toggleTentative = () => {
    setTentative(!tentative);
  };

  const toggleTab = (tabKey) => {
    setSelectedTab(tabKey);

    if (tabKey === "timeoff") {
      setApiUrl("api/v1/add/timeoff");
      if (localStorage.getItem("access_role_id") == "8")
        setSectionTitle("Request Time Off");
      else setSectionTitle("Allocate Time Off");
    } else if (tabKey === "allocation") {
      if (eventObj) {
        //console.log(eventObj , 'hit');
      }
      setApiUrl("api/v1/add/scheduleallocation");
      setSectionTitle(props.title);
    }
  };

  const handleHoursPerDay = (event) => {
    if (event.target.value <= 0) {
      event.target.value = 1;
    } else if (event.target.value > 24) {
      if (event.target.id == "hours_per_day") {
        event.target.value = 24;
      } else {
        event.target.value = 8;
      }
    }
  };

  const generateWeekdayDates = (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const result = [];

    let currentDate = startDate;
    let obj = { start: null, end: null };

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // If it's not a Saturday (6) or Sunday (0), add the range
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        if (obj.start === null) {
          // Set the start date if it's not already set
          obj.start = currentDate.toISOString().split("T")[0];
        }
        // Update the end date
        obj.end = currentDate.toISOString().split("T")[0];
      } else {
        // If it's a weekend, finalize the current object and reset for the next range
        if (obj.start !== null) {
          result.push({ ...obj }); // Clone the object to avoid reference issues
          obj = { start: null, end: null };
        }
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Add the last object if it exists
    if (obj.start !== null) {
      result.push(obj);
    }

    return result;
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" // Prevents modal from closing when clicking outside
        keyboard={false}
      >
        <form onSubmit={handleSubmit}>
          {/* <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
            </Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <Tabs
              defaultActiveKey="allocation"
              id="event-tabs"
              onSelect={toggleTab}
            >
              <Tab eventKey="allocation" title={"Allocation"}>
                <Form.Group>
                  <br />
                  <Form.Label>Project</Form.Label>
                  <Form.Control
                    as="select"
                    id="projectselect"
                    name="project_id"
                    onChange={(e) => createTaskList(e.target.value)}
                    required
                  >
                    {allProjects.map((project) => (
                      <option
                        value={project.id}
                        selected={
                          eventObj ? project.id == eventObj.project_id : false
                        }
                      >
                        {project.name}
                      </option>
                    ))}
                  </Form.Control>
                  <input type="text" id="proj-title" hidden></input>
                  <input
                    name="sender_id"
                    value={localStorage.getItem("person_id")}
                    hidden
                  ></input>
                  {localStorage.access_role_id != "8" ? (
                    <p
                      style={{
                        float: "right",
                        marginTop: "3px",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                      className="text-primary"
                      onClick={openProjectModal}
                    >
                      Edit Project
                    </p>
                  ) : null}
                  <br />
                  <Form.Label>Task</Form.Label>
                  <SearchableDropdown
                    options={tasks}
                    label="name"
                    id={"task"}
                    name={"title"}
                    selectedVal={value}
                    handleChange={(val) => setValue(val)}
                  />
                  <br />
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    name={"notes"}
                    id="notes"
                    defaultValue={eventObj ? eventObj.notes : ""}
                  />
                  <div className="row my-3">
                    <div className="col">
                      <Form.Label>Hours Per Day</Form.Label>
                      <Form.Control
                        type="number"
                        name={"hours_per_day"}
                        id="hours_per_day"
                        defaultValue={eventObj ? eventObj.hoursPerDay : "8"}
                        onBlur={handleHoursPerDay}
                        min={"1"}
                        max={"24"}
                        required
                      />
                    </div>
                    <div className="col">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name={"start_date"}
                        id="start_date"
                        defaultValue={
                          eventObj
                            ? removeTimeFromDate(eventObj.start)
                            : removeTimeFromDate(props.currentAddEvent.start)
                        }
                        required
                        onChange={(e) => {
                          const fromValue = e.target.value;
                          const toInput = document.getElementById("end_date");
                          if (fromValue) {
                            toInput.min = fromValue; // Set the minimum date for "To" input
                          }
                        }}
                      />
                    </div>
                    <div className="col">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name={"end_date"}
                        id="end_date"
                        min={
                          eventObj
                            ? eventObj.endDateToDisplay
                              ? eventObj.endDateToDisplay
                              : removeTimeFromDate(eventObj.end)
                            : removeTimeFromDate(props.currentAddEvent.end)
                        }
                        defaultValue={
                          eventObj
                            ? eventObj.endDateToDisplay
                              ? eventObj.endDateToDisplay
                              : removeTimeFromDate(eventObj.end)
                            : removeTimeFromDate(props.currentAddEvent.end)
                        }
                        required
                      />
                    </div>
                  </div>
                  {localStorage.getItem("access_role_id") == "8" &&
                  apiUrl != "api/v2/add/scheduleallocation" ? null : (
                    <>
                      <div
                        hidden={
                          localStorage.getItem("access_role_id") != "8"
                            ? false
                            : true
                        }
                      >
                        <Form.Label>Assigned to</Form.Label>
                        <MultiSelectDropdwon
                          personsOptions={convertNameToLabel(props.persons)}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                        />
                      </div>
                      <p
                        id="error-text"
                        className="text-danger"
                        style={{ display: "none" }}
                      >
                        Please select at least one person
                      </p>
                      <input
                        type="text"
                        name="assigned_to"
                        value={JSON.stringify(selectedValue)}
                        hidden
                      />
                    </>
                  )}
                  <input
                    type="text"
                    value={props.currentAddEvent.slotId}
                    name="slot_id"
                    hidden
                  />
                  <input
                    type="text"
                    value={eventObj ? eventObj.id : ""}
                    name="allocation_id"
                    id="allocation_id"
                    hidden
                  />
                </Form.Group>
              </Tab>
              {!props.allocationObject && !props.nonWorkingDay ? (
                <Tab eventKey="timeoff" title={"Time Off"}>
                  <div className="mt-3">
                    <div className="row">
                      <div className="col">
                        {/* <input
                          name="sender_id"
                          value={localStorage.getItem("person_id")}
                        ></input> */}
                        <Form.Label>Hours Per Day</Form.Label>
                        <Form.Control
                          type="number"
                          min={"1"}
                          max={"8"}
                          name={"timeoff_hours_per_day"}
                          id="timeoff_hours_per_day"
                          defaultValue={eventObj ? eventObj.hoursPerDay : "8"}
                          onBlur={handleHoursPerDay}
                          required
                        />
                      </div>
                      <div className="col">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          name={"timeoff_start_date"}
                          id="timeoff_start_date"
                          defaultValue={
                            eventObj
                              ? removeTimeFromDate(eventObj.start)
                              : removeTimeFromDate(props.currentAddEvent.start)
                          }
                          required
                          onChange={(e) => {
                            const fromValue = e.target.value;
                            const toInput =
                              document.getElementById("timeoff_end_date");
                            if (fromValue) {
                              toInput.min = fromValue; // Set the minimum date for "To" input
                            }
                          }}
                        />
                      </div>
                      <div className="col">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          name={"timeoff_end_date"}
                          id="timeoff_end_date"
                          min={
                            eventObj
                              ? eventObj.endDateToDisplay
                                ? eventObj.endDateToDisplay
                                : removeTimeFromDate(eventObj.end)
                              : removeTimeFromDate(props.currentAddEvent.end)
                          }
                          defaultValue={
                            eventObj
                              ? eventObj.endDateToDisplay
                                ? eventObj.endDateToDisplay
                                : removeTimeFromDate(eventObj.end)
                              : removeTimeFromDate(props.currentAddEvent.end)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Form.Label style={{ marginBottom: "0px" }}>
                        Time Off
                      </Form.Label>
                      <input
                        type="text"
                        name="time_off_type"
                        id="time_off_type"
                        value={timeOffType}
                        hidden
                      />
                      <input
                        type="text"
                        name="bg_color"
                        id="time_off_bg_color"
                        value={timeOffBgColor}
                        hidden
                      />
                      <CustomDropdown
                        setTimeOffType={setTimeOffType}
                        setTimeOffBgColor={setTimeOffBgColor}
                      >
                        {" "}
                      </CustomDropdown>
                      <p
                        className="text-danger"
                        style={{
                          fontSize: "13px",
                          marginBottom: "0px",
                          marginTop: "2px",
                          display: "none",
                        }}
                        id="timeoff-error-text"
                      >
                        Please select a timeoff{" "}
                      </p>
                    </div>
                    <div className="my-2">
                      {localStorage.getItem("access_role_id") != "8" ? (
                        <>
                          <input
                            value={tentative ? "Yes" : "No"}
                            type="text"
                            name="tentative"
                            id="tentative"
                            hidden
                          ></input>
                          <button
                            type="button"
                            className={
                              tentative
                                ? "btn btn-info text-light mt-2"
                                : "btn btn-light mt-2"
                            }
                            onClick={toggleTentative}
                          >
                            Tentative
                          </button>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name="tentative"
                            value={"Yes"}
                            id="tentative"
                            hidden
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <div className="mt-4">
                        <Form.Label style={{ marginBottom: "0px" }}>
                          Notes
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name={"timeoff_notes"}
                          id={"timeoff_notes"}
                          defaultValue={eventObj ? eventObj.notes : ""}
                        />
                      </div>
                    </div>
                    {localStorage.getItem("access_role_id") != "8" ? (
                      <div className="mt-4">
                        <Form.Label style={{ marginBottom: "0px" }}>
                          Assigned to
                        </Form.Label>
                        <MultiSelectDropdwon
                          personsOptions={convertNameToLabel(props.persons)}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                        />
                      </div>
                    ) : null}
                  </div>
                </Tab>
              ) : null}
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                className="modal-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? sectionTitle + "..." : sectionTitle}
              </Button>
              {props.title == "Edit Allocation" && (
                <div hidden={isSubmitting}>
                  <MoreAction
                    delete={deleteAllocation}
                    isSubmitting={isSubmitting}
                  />
                </div>
              )}
              <p
                className="btn btn-secondary"
                onClick={props.onHide}
                style={{ right: "20px", position: "absolute" }}
                hidden={isSubmitting}
              >
                Close
              </p>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default AddScheduleAllocation;
