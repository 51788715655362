import React, { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import apicall from "../../apicall";


function ManagesMultiSelect(props){
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);

    function convertNameToLabel(inputArray) {
        const newArray = inputArray.map(item => {
          const newItem = { ...item };
          newItem.label = newItem.name;
          newItem.value = newItem.id;
          delete newItem.name;
          return newItem;
        });
        return newArray;
      }


    useEffect(() => {
        const fetchPersons = async () => {
            try {
                const response = await fetch(apicall.SERVER_URL_CA + 'api/v1/all/persons', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'access_role_id': localStorage.getItem('access_role_id'),
                        'accessible_projects': localStorage.getItem('accessible_projects')
                    }),
                });
    
                const responseData = await response.json();
                let allPersons = [];
                responseData.data.forEach(obj => {
                    let newPerson = {
                        'id' : obj.id,
                        'name' : obj.name
                    };
                    //console.log(props.parent, 'parent');
                    if(props.parent === 'editPeople'){
                        //If manager Id is same as person['id'] then do not push
                        if(props.managerId != newPerson.id){
                            allPersons.push(newPerson);
                        }
                        //if manager id from api matches manager id from prop, then push a {label,value} object to selected state variable:
                        if(props.managerId == obj.manager_id){
                           let assignedPerson =  {
                            'value': obj.id,
                            'label': obj.name}

                            setSelectedValue(prevSelectedValue => [...prevSelectedValue, assignedPerson]);
                        }
                    }
                    else{
                        if(obj.manager_assigned === 'No'){
                            allPersons.push(newPerson);
                        }
                    }
                });
                setOptions(convertNameToLabel(allPersons));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchPersons();
    }, []);
    

    const onSelect = (selectedList, selectedItem) =>{
        setSelectedValue(selectedList);
    }
    
    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
    }


    return(
        <>
        <MultiSelect
        options={options}
        value={selectedValue}
        onChange={setSelectedValue}
        labelledBy="Select"
      />

      <input type="text" id="manages" name="manages" value={JSON.stringify(selectedValue)} hidden />
</>
    )


}

export default ManagesMultiSelect;