import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastMsg(props) {
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  const {showToast, toogleToast, notificationMsg} = useAuth();

  const notify = (notificationMsg) => {

    toast.info(notificationMsg, {
      position: toast.POSITION.TOP_RIGHT,
      theme : 'colored'
    });
  }

  useEffect(() => {
    if(showToast){
      notify(notificationMsg);
      toogleToast();
    }
  },[showToast])
  return(
    <div>
    <ToastContainer />
  </div>
  )
}

export default ToastMsg;
