import React, { useEffect, useState } from "react";
import {Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import * as BsIcons from "react-icons/bs";

import '../../App.css';
import apicall from "../../apicall";
import Timeoff from "./timeoff";
import moment from "moment";


function NotificationPopover(props) {
    const [loadingNotification , setLoadingNotification] = useState(true);
    const [allNotifications, setAllNotifications] = useState([]);
    const [showTimeoff, setShowTimeOff] = useState(false);
    const [timeOffObj, setTimeOffObj] =  useState(null);
    const [popoverOpen, setPopoverOpen] = useState(props.showPopover);



        
    const fetchNotifications = async () => {
      try {
        const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/all/notifications", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                receiver_id: localStorage.getItem('person_id')
            })
        });
        let responseData = await apiResponse.json();

        if (apiResponse.status === 200) {
            setAllNotifications(responseData.data);
            setLoadingNotification(false);
        } else {
            console.error("Failed to fetch notifications:", apiResponse.status);
        }
    } catch (error) {
        console.error("Error fetching notifications:", error);
    }
};
    
    useEffect(() => {
    setLoadingNotification(true);
    fetchNotifications();
    }, [popoverOpen]);

    const handleClickOutside = (event) => {
      if (popoverOpen && !event.target.closest('.popover-inner')) {
        setPopoverOpen(false);
        props.removeactiveclass();
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [popoverOpen]);



    const toggleTimeOffModal = async (notification) =>{
      if(notification.notification_type === 'timeoff-requested' && localStorage.getItem('access_role_id') != '8') {
        const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/get/timeoff', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              'timeoff_id': notification.timeoff_id,
          }),
      });
  
        if(apiResponse.status === 200){
          const responseData = await apiResponse.json();
          //console.log(responseData.data, 'data');
          setTimeOffObj(responseData.data);
          setShowTimeOff(true);
          setPopoverOpen(false);
          props.removeactiveclass();
        }
        else{
          alert('Some error occured!');        
        }
      }

    }

    return (
        <div>
            {showTimeoff && <Timeoff
              show = {showTimeoff}
              onHide = {() => setShowTimeOff(false)}
              timeOffObj = {timeOffObj}
              opened_from_notification = {true}
            />
            }

            <Popover placement="right" isOpen={popoverOpen} target="Popover2" toggle={() => setPopoverOpen(!popoverOpen)}  style={{ width: '400px' }} className="">
                <div style={{ width: '100%' }}> {/* Add a wrapper div for width control */}
                    <PopoverHeader className='bg-light text-dark'>
                        Notifications
                        <BsIcons.BsThreeDotsVertical style={{ float: 'right', marginTop: '2px', cursor: 'pointer' }} />
                    </PopoverHeader>
                <PopoverBody className='scrollable-popover'>
                    {loadingNotification ? (
                       <div class="text-center" style={{ marginTop: '42%' }}>
                         <div className="spinner-border text-info" role="status"></div>
                         <p className="text-secondary" style={{ fontWeight: '700' }}>
                           Checking for new notifications...
                         </p>
                       </div>
                        ) : allNotifications.length === 0 ? (
                         <p>Nothing found</p>
                        ) : (
                  allNotifications.map((notification, index) => (
                    <div
                      key={index}
                      className=" notification-div "
                      style={{ cursor: 'pointer' , overflowX : 'hidden'}}
                      onClick={() => toggleTimeOffModal(notification)}
                    >
                      <div className="col-1"
                      style={{ background: notification.tentative === 'No' ? `repeating-linear-gradient(150deg, transparent, transparent 6px, ${notification.bg_color} 4px, ${notification.bg_color} 8px)` : notification.bg_color , width :'7px'}}
                      ></div>
                      <div className="col-2 pt-2">
                        <div className="initials-circle mx-2">
                          <p className="name-initials">{notification.name_initials}</p>
                        </div>
                      </div>
                      <div className="col-7 pt-2">
                        <p style={{ paddingTop: '2px' }} dangerouslySetInnerHTML={{ __html: notification.message }}>
                        </p>
                      </div>
                      <div className="col-3 pt-2 text-center text-secondary" style={{ fontSize : '11px' }}>
                        {moment.utc(notification.created_at).fromNow()}
                      </div>
                    </div>
                  ))
                )}
            </PopoverBody>
                </div>
            </Popover>

        </div>
    )
}

export default NotificationPopover;
