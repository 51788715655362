import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Row, Col } from "react-bootstrap";
import apicall from "../../apicall";
import { Input, Label } from "reactstrap";
import ChipInput from "../chipInput/ChipInput";
import "../../App.css";
import PopoverComponent from "./popover";

function ProjectModal(props) {
  const [persons, setPerson] = useState([]);
  const [task, setTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [tags, setTags] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_role_id: localStorage.getItem("access_role_id"),
      accessible_projects: localStorage.getItem("accessible_projects"),
    }),
  };

  useEffect(() => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/persons", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let personData = data.data;
        setPerson(personData);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });
  }, []);

  let peopleObj = {};
  let peopleString = "";

  let milestonesObj = {};
  let milestoneString = "";

  let milestoneCounter = 1;

  function removePerson(event) {
    window.alert(event.target);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(event.target);
    const apiResponse = await fetch(
      apicall.SERVER_URL_CA + "api/v1/add/project",
      {
        method: "POST",
        body: formData,
      }
    );

    if (apiResponse.status === 200) {
      window.location.reload();
    } else {
      setIsSubmitting(false);
      // Handle other status codes or errors
      // You can display an error message or take other actions
    }
  };

  function addMilestone() {
    if (!document.getElementById("milestonename").value) {
      alert("Milestone name is required");
    } else if (!document.getElementById("from").value) {
      alert("Start date is required");
    } else if (!document.getElementById("to").value) {
      alert("End date is required");
    } else {
      let name = document.createElement("p");
      name.innerText = document.getElementById("milestonename").value;
      name.classList = "text-secondary";
      name.style.fontSize = "20px";
      name.style.fontWeight = "700";
      name.style.marginBottom = "2px";

      let from = document.getElementById("from").value;
      let to = document.getElementById("to").value;

      let dateString = document.createElement("p");
      dateString.innerText = from + " - " + to;

      let div = document.createElement("div");
      div.style.borderBottom = "1px solid #D3D3D3";
      div.style.marginLeft = "10px";
      div.style.marginRight = "10px";
      div.style.paddingTop = "15px";
      div.style.paddingBottom = "5px";

      let newMilestoneId = "m" + milestoneCounter;
      div.setAttribute("name", newMilestoneId);
      milestoneCounter = milestoneCounter + 1;

      let tempMilestoneObj = {
        name: document.getElementById("milestonename").value,
        start_date: document.getElementById("from").value,
        end_date: document.getElementById("to").value,
      };

      milestonesObj[newMilestoneId] = tempMilestoneObj;
      milestoneString = JSON.stringify(milestonesObj);
      document
        .getElementById("milestones")
        .setAttribute("value", milestoneString);

      let span = document.createElement("span");
      span.innerText = "X";
      span.style.float = "right";
      span.style.marginRight = "10px";
      span.style.cursor = "pointer";

      span.onclick = function (event) {
        let mile =
          event.target.parentElement.parentElement.getAttribute("name");

        const mileString = mile.toString();
        if (milestonesObj.hasOwnProperty(mileString)) {
          delete milestonesObj[mileString];
        }
        milestoneString = JSON.stringify(milestonesObj);
        document
          .getElementById("milestones")
          .setAttribute("value", milestoneString);
        event.target.parentElement.parentElement.remove();
      };

      name.appendChild(span);
      div.appendChild(name);
      div.appendChild(dateString);
      document.getElementById("mileDiv").appendChild(div);

      document.getElementById("milestonename").value = "";
      document.getElementById("from").value = "";
      document.getElementById("to").value = "";
      document.getElementById("to").disabled = true;
    }
  }

  const handleTeam = (event) => {
    const selectedTeamMember = event.target.value; // Get the selected value from the event
    const optionToRemove = event.target.options[event.target.selectedIndex];
    let id = optionToRemove.getAttribute("id");

    let div = document.createElement("div");
    // div.style.borderTop = '1px solid #D3D3D3';
    div.style.borderBottom = "1px solid #D3D3D3";
    div.style.marginLeft = "10px";
    div.style.marginRight = "10px";
    div.style.paddingTop = "15px";
    div.style.paddingBottom = "5px";
    div.setAttribute("value", optionToRemove.text);
    div.setAttribute("name", id);

    //Adding Data to People Object:
    peopleObj[id] = optionToRemove.text;
    peopleString = JSON.stringify(peopleObj);
    document.getElementById("peopleinput").setAttribute("value", peopleString);

    let p = document.createElement("p");
    p.innerText = selectedTeamMember;
    let span = document.createElement("span");
    span.innerText = "X";

    document.getElementById("all-people-checkbox").hidden = true;
    document.getElementById("all-people-checkbox-label").hidden = true;

    span.style.cursor = "pointer";
    span.onclick = function (event) {
      var projectsDiv = document.getElementById("teamDiv");
      if (projectsDiv.childElementCount === 1) {
        document.getElementById("all-people-checkbox").hidden = false;
        document.getElementById("all-people-checkbox-label").hidden = false;
      }

      let parentDiv = event.target.parentElement.parentElement;
      let option = document.createElement("option");
      option.setAttribute("value", parentDiv.getAttribute("value"));
      option.setAttribute("id", parentDiv.getAttribute("name"));
      option.innerText = parentDiv.getAttribute("value");
      document.getElementById("teamselect").appendChild(option);

      id = parentDiv.getAttribute("name");
      const idString = id.toString();
      if (peopleObj.hasOwnProperty(idString)) {
        delete peopleObj[idString];
      }
      peopleString = JSON.stringify(peopleObj);
      document
        .getElementById("peopleinput")
        .setAttribute("value", peopleString);

      parentDiv.remove();
    };
    span.style.marginRight = "15px";
    span.style.float = "right";

    p.appendChild(span);
    div.appendChild(p);

    document.getElementById("teamDiv").appendChild(div);

    optionToRemove.remove();
  };

  const createTeam = (data) => {
    return <div>dnvdkvnn</div>;
  };

  const createTask = (event) => {
    if (document.getElementById("taskInput").value) {
      const selectedTasksArray = selectedTask.map((item) => item.toLowerCase());
      const newTaskString = document
        .getElementById("taskInput")
        .value.toLowerCase();

      if (selectedTasksArray.includes(newTaskString)) {
        alert(
          "This task name already exists for this project. Please update and try again."
        );
      } else {
        let updatedSeletedTask = [
          ...selectedTask,
          document.getElementById("taskInput").value,
        ];
        setSelectedTask(updatedSeletedTask);

        document.getElementById("tasks").value =
          JSON.stringify(updatedSeletedTask);
      }
    }
  };

  const removeTask = (event) => {
    if (event.target.getAttribute("value")) {
      const updatedSelectedTasks = selectedTask.filter(
        (item) => item !== event.target.getAttribute("value")
      );
      setSelectedTask(updatedSelectedTasks);

      document.getElementById("tasks").value =
        JSON.stringify(updatedSelectedTasks);
    }
  };

  const setColorField = (colorCode) => {
    document.getElementById("color-input").value = colorCode;
  };

  const markAllPeople = (event) => {
    document.getElementById("teamselect").hidden = event.target.checked;

    if (!event.target.checked) {
      document.getElementById("peopleinput").value = JSON.stringify({});
      return;
    }

    //console.log(persons, 'pppp');

    let allPeople = {};
    persons.forEach((person) => {
      allPeople[person.id] = person.name;
    });

    document.getElementById("peopleinput").value = JSON.stringify(allPeople);
  };

  return (
    <Form>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static" // Prevents modal from closing when clicking outside
        keyboard={false}
      >
        {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Project 
                </Modal.Title>
            </Modal.Header> */}
        <form method="POST" onSubmit={handleSubmit}>
          <Modal.Body style={{ minHeight: "535px" }}>
            <Tabs defaultActiveKey="title" id="event-tabs">
              <Tab eventKey="title" title="Info">
                {/* <Form method="POST" action={apicall.SERVER_URL_CA + "api/v1/add/project"} > */}
                <br />
                <input
                  type="text"
                  value={localStorage.getItem("name")}
                  name="owner"
                  hidden
                />
                <Form.Group controlId="eventTitle">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control type="text" name={"name"} required />
                </Form.Group>
                <br />
                <Form.Group controlId="eventTitle">
                  <Form.Label>Client</Form.Label>
                  <Form.Control type="text" name={"client"} />
                </Form.Group>
                <br />
                <Form.Group controlId="startDate">
                  <Form.Label>Project Color</Form.Label>
                  <Form.Control
                    type="color"
                    name={"color"}
                    required
                    hidden
                    id="color-input"
                  />
                  <PopoverComponent setColorField={setColorField} />
                </Form.Group>
                {/* <Form.Group controlId="endDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'start_date'}
                                />
                            </Form.Group> */}

                {/* <Form.Group controlId="endDate">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'end_date'}
                                />
                            </Form.Group> */}

                {/* <Form.Group controlId="endDate">
                                <Form.Label>Budget</Form.Label>
                                <Form.Control
                                    type="number"
                                    name = {'budget'}
                                />
                            </Form.Group> */}

                <br />
                <Form.Group controlId="endDate">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" name={"description"} />
                </Form.Group>
                <br />
                <Form.Group controlId="selectDropdown">
                  <Form.Label>Tags</Form.Label>
                  <ChipInput setTags={(value) => setTags(value)} />
                  <input type="hidden" name="tags" value={tags}></input>
                </Form.Group>

                {/* </Form> */}
              </Tab>
              <Tab eventKey="startDate" title="Team">
                <br />
                <Form.Group controlId="selectDropdown" id="project-select">
                  <Form.Label>Assign a Team Member</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleTeam}
                    id="teamselect"
                  >
                    <option>--Select a Team Member--</option>
                    {persons && persons.length > 0
                      ? persons.map((person) => (
                          <option
                            id={person.id}
                            value={person.name}
                            key={person.id}
                          >
                            {person.name}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                </Form.Group>
                <input
                  type="text"
                  placeholder=""
                  id="peopleinput"
                  name="team"
                  hidden
                ></input>
                <input
                  type="checkbox"
                  id="all-people-checkbox"
                  className="mt-3"
                  style={{ fontSize: "16px" }}
                  onClick={(event) => markAllPeople(event)}
                />
                <label
                  for="all-people-checkbox"
                  id="all-people-checkbox-label"
                  className="mx-1"
                  style={{ fontSize: "16px" }}
                >
                  Assign all people
                </label>
                <div className="mt-3 scrollable-team-div" id="teamDiv"></div>
              </Tab>
              <Tab eventKey="endDate" title="Milestones">
                {/* <Form> */}
                <Form.Group controlId="endDate">
                  <br />
                  <Form.Label>Milestone Name</Form.Label>
                  <Form.Control type="text" id="milestonename" />
                </Form.Group>
                <br />
                <Row>
                  <Col>
                    <p style={{ marginBottom: "0px" }}>From</p>
                    <Form.Group>
                      <Form.Control
                        type="date"
                        name={"from"}
                        id="from"
                        onChange={(e) => {
                          const fromValue = e.target.value;
                          const toInput = document.getElementById("to");
                          if (fromValue) {
                            toInput.disabled = false;
                            toInput.min = fromValue; // Set the minimum date for "To" input
                          } else {
                            toInput.disabled = true;
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <p style={{ marginBottom: "0px" }}>To</p>
                    <Form.Group>
                      <Form.Control type="date" name={"to"} id="to" disabled />
                    </Form.Group>
                  </Col>
                  <Col>
                    <input
                      type="text"
                      id="milestones"
                      name="milestones"
                      hidden
                    />
                    <p style={{ color: "transparent", marginBottom: "0px" }}>
                      {" "}
                      lestone
                    </p>
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={addMilestone}
                    >
                      Add Milestone
                    </button>
                  </Col>
                </Row>
                {/* </Form>  */}
                <div
                  className=" mt-4 scrollable-milestone-div"
                  id="mileDiv"
                ></div>
              </Tab>

              {/* <Tab eventKey="endDate" title="Task List">

                    </Tab> */}

              <Tab title="Task List" eventKey="TaskList">
                <input type="text" id="tasks" name="tasks" hidden />
                <br />
                <br />
                <div className="row mx-2">
                  <Form.Label>Add a task name</Form.Label>
                </div>
                <div className="row mx-2">
                  <div className="col-9">
                    <Form.Group>
                      <Form.Control type="text" id="taskInput" />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Button
                      className="btn-info"
                      style={{ width: "100%", color: "white" }}
                      onClick={createTask}
                    >
                      Add Task
                    </Button>
                  </div>
                </div>

                <div
                  id="selectedTaskDiv "
                  className="mx-4 mt-3 scrollable-team-div"
                >
                  {selectedTask.map((task) => (
                    <div
                      style={{
                        borderBottom: "1px solid #D3D3D3",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>
                        {task}
                        <span
                          style={{
                            float: "right",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={removeTask}
                          value={task}
                        >
                          X
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button onClick={props.onHide}>Close</Button> */}
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Add Project..." : "Add Project"}
            </Button>
            <p
              className="btn btn-secondary"
              onClick={props.onHide}
              style={{ right: "20px", position: "absolute" }}
              hidden={isSubmitting}
            >
              Close
            </p>
          </Modal.Footer>
        </form>
      </Modal>
    </Form>
  );
}

export default ProjectModal;
