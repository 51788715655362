import React, { useEffect, useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import * as AiIcons from "react-icons/ai";
import '../../App.css';

const PopoverComponent = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [colorRow1] = useState([
    '#3451b2' , '#d23197', '#ecadd4' , '#793aaf' , '#d3b4ed' , '#067a6f' , '#69d9c1' , '#69d9c1'
  ])
  const [colorRow2] = useState([
    '#34c754', '#d24023', '#d24023', '#ffb224', '#f7ce00', '#173074', '#0078a1', '#2e5fe8'
  ])

  const [colorRow3] = useState([
    '#5eb0ef', '#68ddfd', '#242c39' , '#344765', '#bfcbdc' , '#706f6c' , '#776750' , '#cbbda4'
  ]);

  const [recentColors] = useState(JSON.parse(localStorage.getItem('recently_used_colors')));
  const [usedByClient] = useState(JSON.parse(localStorage.getItem('used_by_client')));

  const [defaultColor, setDefaultColor] = useState(props.defaultColor ? props.defaultColor : 'black');
  const [customcolormodal,setCustomcolormodal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (customcolormodal) {
        setPopoverOpen(false);
        setCustomcolormodal(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [customcolormodal]);

  
  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };



  const showCustomColor = () => {
    document.getElementById('custom-color').click();
    setCustomcolormodal(true);
  }

  const setColorField = (event) => {
    let colorCode = event.target.value ? event.target.value :event.target.getAttribute('value');
    setDefaultColor(colorCode)
    props.setColorField(colorCode);

    if(!event.target.value){
        setPopoverOpen(false);
    }
  }
  return (
    <div >
    <div style={{ display : 'flex'}}>
      <div id="Popover1" style={{ height : '25px', width: '25px', borderRadius : '50%', backgroundColor:  defaultColor, cursor: 'pointer'}} onClick={toggle} >
      </div>
      <p className='mx-2 mt-1' onClick = {toggle} style={{ cursor : 'pointer'}}>&#x25BC;</p>
      </div>

      <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
        {/* <PopoverHeader>Popover Title</PopoverHeader> */}
        
        <PopoverBody>
        <p style={{ marginBottom: '8px', marginLeft: '5px', fontWeight : '600'}}>Default Colors</p>
        <div style={{ display: 'flex'}}>
        {colorRow1.map((color) => (
          <div style={{ backgroundColor: color}} className = 'col color-circle' value={color} onClick={setColorField}></div>
        ))}
        </div>

        <div style={{ display: 'flex'}} className='mt-2'>
        {colorRow2.map((color) => (
          <div style={{ backgroundColor: color}} className = 'col color-circle ' value={color} onClick={setColorField}></div>
        ))}
        </div>

        <div style={{ display: 'flex'}} className='mt-2'>
        {colorRow3.map((color) => (
          <div style={{ backgroundColor: color}} className = 'col color-circle' value={color} onClick={setColorField}   ></div>
        ))}
        </div>

        {usedByClient.length > 0 ? <div>
        <hr />
        <p style={{ marginBottom: '8px', marginLeft: '5px', fontWeight : '600'}}>Used by client before</p>
        <div style={{ display: 'flex'}}>
        {usedByClient.map((color) => (
          <div style={{ backgroundColor: color}} className = 'color-circle' value={color} onClick={setColorField}></div>
        ))}
        </div>
        </div> : null}

        {recentColors.length > 0 ? <div>
        <hr />
        <p style={{ marginBottom: '8px', marginLeft: '5px', fontWeight : '600'}}>Used by client before</p>
        <div style={{ display: 'flex'}}>
        {recentColors.map((color) => (
          <div style={{ backgroundColor: color}} className = 'color-circle' value={color} onClick={setColorField}></div>
        ))}
        </div>
        </div> : null}
        <hr />
        
        <button className="btn btn-light" style={{ width : '100%'}} onClick={showCustomColor}>
        <AiIcons.AiOutlinePlus  style={{ marginRight :'5px', verticalAlign: 'middle', fontWeight : '900'}} />
         Set custom color...
      <input type="color" name="" id="custom-color"  style={{ height: '0px', width : '0px'}} defaultValue="#fff" onChange={setColorField}/>
        </button>   
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default PopoverComponent;
