import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useState } from "react";
import PersonDataTable from "./tables/DataTable";
import PeopleModal from "../../components/modals/peoplemodal";
import apiCall from "../../apicall";
import Sidebar from "../../components/navbar/sidebar";
import * as AiIcons from "react-icons/ai";
import LoadingScreen from "react-loading-screen";
import ConfirmArchiveActive from "../../components/modals/confirmArchiveActive";

function People() {
  const [modalShow, setModalShow] = useState(false);
  const [accessRoleId] = useState(localStorage.getItem("access_role_id"));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPerson, setselectedPerson] = useState([]);
  const [actionBtnTitle, setActionBtnTitle] = useState("Archive");
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [heading, setHeading] = useState(null);
  const [bodyText, setBodyText] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  const handleToggleModal = () => {
    setModalShow(!modalShow);
  };

  const [items, setItems] = useState([]);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_role_id: localStorage.getItem("access_role_id"),
      accessible_projects: localStorage.getItem("accessible_projects"),
      filter: "active",
      person_id: localStorage.getItem("person_id"),
    }),
  };

  const getItems = () => {
    // Calling API to get list of all projects
    fetch(apiCall.SERVER_URL_CA + "api/v1/all/persons", requestOptions)
      .then((response) => response.json())
      .then((items) => setItems(items.data))
      .catch((err) => console.log(err));
  };

  const archivePeople = (action) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        access_role_id: localStorage.getItem("access_role_id"),
        accessible_projects: localStorage.getItem("accessible_projects"),
        selectedPerson: selectedPerson,
        filter: action,
      }),
    };

    setIsLoading(true);
    fetch(apiCall.SERVER_URL_CA + "api/v1/archivePersons", requestOptions)
      .then((response) => {
        setselectedPerson([]);
        window.location.reload();
        response.json();
      })
      .catch((err) => console.log(err));
  };

  const toggleProject = (item) => {
    // Sample array of IDs
    const idArray = [...selectedPerson];
    const idToAddOrRemove = item.id;

    // Check if the ID exists in the array
    if (idArray.includes(idToAddOrRemove)) {
      // ID exists, so remove it
      const index = idArray.indexOf(idToAddOrRemove);
      if (index !== -1) {
        idArray.splice(index, 1);
      }
    } else {
      // ID doesn't exist, so add it
      idArray.push(idToAddOrRemove);
    }
    setselectedPerson(idArray);
  };

  const handlePeopleFilter = (event) => {
    let updatedRequestOptions = JSON.parse(requestOptions.body);
    updatedRequestOptions["filter"] = event.target.value;
    requestOptions["body"] = JSON.stringify(updatedRequestOptions);
    getItems();

    if (event.target.value == "archive") {
      setActionBtnTitle("Activate");
    } else {
      setActionBtnTitle("Archive");
    }
  };

  const toggleConfirmModal = () => {
    setHeading(null);
    setBodyText(null);
    setConfirmModalShow(true);
  };

  const setConfirmModal = () => {
    setHeading("Delete People");
    setBodyText("Delete " + selectedPerson.length + " People ?");
    setConfirmModalShow(true);
  };

  const deletePeople = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    const apiResponse = await fetch(
      apiCall.SERVER_URL_CA + "api/v1/delete/person",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedPerson: selectedPerson,
        }),
      }
    );

    if (apiResponse.status === 200) {
      setIsSubmitting(false);
      setselectedPerson([]);
      window.location.reload();
    } else {
      setIsSubmitting(false);
      alert("Something went wrong");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "rgb(243, 242, 245)",
        minHeight: "95vh",
      }}
    >
      <ConfirmArchiveActive
        show={confirmModalShow}
        onHide={() => {
          setConfirmModalShow(false);
        }}
        onConfirm={heading ? deletePeople : () => archivePeople(actionBtnTitle)}
        heading={
          heading
            ? heading
            : actionBtnTitle == "Archive"
            ? "Move to Archive"
            : "Move to Active"
        }
        bodyText={
          bodyText
            ? bodyText
            : "Move " +
              selectedPerson.length +
              " " +
              (selectedPerson.length > 1 ? "people " : "person ") +
              "to " +
              (actionBtnTitle == "Archive" ? "archive?" : "active?")
        }
        isSubmitting={isSubmitting}
      />
      <LoadingScreen
        loading={isLoading}
        bgColor="rgba(255,255,255,1)"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc=""
        text=""
      />

      <div className="mx-2">
        <Sidebar activeRoute="People" />
      </div>
      <div className="" style={{ width: "100%", margin: "9px" }}>
        <Row>
          <Col>
            <div
              className="counts"
              style={{ marginBottom: "5px", display: "flex" }}
            >
              {items.length} People
              {selectedPerson.length > 0 && (
                <div>
                  {" "}
                  <button
                    onClick={toggleConfirmModal}
                    className="btn btn-primary mx-3"
                  >
                    {actionBtnTitle === "Activate"
                      ? "Retrieve"
                      : actionBtnTitle}
                  </button>
                  <button className="btn btn-danger" onClick={setConfirmModal}>
                    Delete
                  </button>
                </div>
              )}
            </div>
            {accessRoleId == "2" ||
            localStorage.getItem("create_edit_people") == "Yes" ? (
              <div
                onClick={handleToggleModal}
                style={{
                  float: "right",
                  marginRight: "20px",
                  padding: "5px",
                  background: "#2E5FE8",
                  borderRadius: "10px",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
              >
                {" "}
                <AiIcons.AiOutlinePlus size={"22"} color={"#fff"} />
              </div>
            ) : null}
            <div style={{ float: "right", marginRight: "20px" }}>
              <select
                className="form-select form-select-sm"
                id="people-filter"
                onChange={handlePeopleFilter}
              >
                <option value="active" selected>
                  Active
                </option>
                <option value="archive">Archived</option>
              </select>
            </div>

            {/* {accessRoleId == '2' ? <button onClick={handleToggleModal} style={{ float: 'right' , marginBottom : '12px', marginRight:'50px' }} className="btn btn-success">Add New Person</button> : null} */}
          </Col>
        </Row>

        <Row>
          <Col>
            <PersonDataTable items={items} toggleProject={toggleProject} />
          </Col>
        </Row>

        <PeopleModal show={modalShow} onHide={handleToggleModal} />
      </div>
    </div>
  );
}

export default People;
