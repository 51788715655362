import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from "pusher-js";
import apicall from '../apicall';


const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const[showToast, setShowToast] =  useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  useEffect(() => {
    // On component mount, read the authentication state from cookie
    const storedAuth = Cookies.get('isAuth');
    if (storedAuth) {
      setIsAuth(true);
    }else{
        setIsAuth(false);
    }


    if(localStorage.getItem('person_id')){
      initializePusher();
    }

  }, []);

  const toggleAuth = () => {
    setIsAuth((prevAuth) => {
        // Update the cookie when authentication state changes
        Cookies.set('isAuth', !prevAuth);
        return !prevAuth;
      });
  };

  const logOut = () =>{
    Cookies.remove('isAuth');
    setIsAuth(false)
  }

  const toogleToast = () =>{
    setShowToast(!showToast);
    setShowBadge(!showBadge);
  }

  const initializePusher = () =>{
    //Initialize Pusher:
    const pusher = new Pusher('7c9f1a8d354908656152', {
      cluster: 'ap2',
      encrypted: true,
    });

    
    // console.log(pusher,'pusher')
    pusher.connection.bind('connected', function () {
      //console.log('Socket ID:', pusher.connection.socket_id);
      
      const response = fetch(apicall.SERVER_URL_CA+'api/v1/add/onlineuser', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json', // Use an object for headers
          },
          body: JSON.stringify({
              person_id: localStorage.getItem('person_id'),
              person_email: localStorage.getItem('person_email'),
              socket_id: pusher.connection.socket_id,
          }),
      });

       const channel = pusher.subscribe('notification-channel.'+pusher.connection.socket_id);

       channel.bind('test_event', function(data) {
        alert(data);
       });


       channel.bind('send_notification', function(data) {
        // setShowToastMsg(true);
        setNotificationMsg(data.message);
        toogleToast();
        // setTimeout(toogleToast, 3000);
        // setTotalNotifications(totalNotifications + 1);
       });

       return () => {
          pusher.unsubscribe('realtime-notification-channel');
        };
   });
  }
  

  const value = {
    isAuth,
    toggleAuth,
    logOut,
    showBadge,
    showToast,
    setShowBadge,
    toogleToast,
    notificationMsg,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
