import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmModal(props) {
  const {
    show = false,
    onHide = () => {},
    onConfirm = () => {},
    bodyText = "Schedule on a non-work day?",
    btn1 = "Yes",
    btn2 = "Close",
  } = props;
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <h5
            style={{ textAlign: "center", marginBottom: "0px" }}
            className="text-secondary"
          >
            {bodyText}
          </h5>
        </Modal.Body>
        <Modal.Footer style={{ margin: "0 auto" }}>
          <Button variant="primary" onClick={onConfirm}>
            {btn1}
          </Button>
          <Button variant="light" onClick={onHide}>
            {btn2}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModal;
