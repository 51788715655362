import React, { useState } from "react";
//import logoImage from '../../assets/images/companyLogo2.png';
import logoImage from '../../assets/images/circle_.png';
import dwtc from '../../assets/images/dwtc.png';


import './formStyle.css'; // Make sure to import your stylesheet here
import { useAuth } from '../../context/AuthContext';
import { useEffect } from "react";
import apicall from "../../apicall";



export default function YourComponent(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { toggleAuth, logOut } = useAuth(); 
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // On component mount, read the authentication state from cookie
    logOut();
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setIsSubmitting(true);
    apicall.PostCall(
      'api/v1/login',
      JSON.stringify({
        email: email,
        password: password,
      }),
      function(response) {
        if(response.status == 'Success'){
          localStorage.setItem("access_role_id", response.access_role_id);
          localStorage.setItem("person_id", response.person_id);
          localStorage.setItem("accessible_projects", response.accessible_projects);
          localStorage.setItem("can_manage_project", response.can_manage_project);
          localStorage.setItem("can_manage_people", response.can_manage_people);
          localStorage.setItem("create_edit_people", response.create_edit_people);
          localStorage.setItem('person_email',email);
          localStorage.setItem('recently_used_colors',response.recently_used_colors);
          localStorage.setItem('used_by_client',response.used_by_client);
          localStorage.setItem('managed_persons',response.managed_persons);
          
          window.location.href = '/schedule';

        }
        else{
          setIsSubmitting(false);
            window.alert(response.message);
        }
        toggleAuth();
      },
      function(err) {
        //console.log("Something went wrong, please try later"+err);
      }
    );
  };

  return (
    <div className="two-column-container" style={{backgroundColor: 'blue'}}>
      <div className="logo-column">
        <img
          src={logoImage}
          alt="Logo"
          className="logo-image"
        />
      </div>
      <div className="form-column">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label style={{ float : 'left'}}>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                value={email}
                name='email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label style={{ float : 'left'}}>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={password}
                name = 'password'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <a className="text-primary mt-1" style={{float: 'right', textDecoration: 'none'}} href="/recover-password">Forgot Password?</a>
            <br />
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Signing In...' : 'Sign In'}
              </button>
            </div>
            <br/>
           {/*  <p className="forgot-password text-right mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
        <div className="logo-footer">
          <img
            src={dwtc}
            alt="Logo"
            
          />
        </div>
        
      </div>
    </div>
  );
}
