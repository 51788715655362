import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ModalForm from "./Modals/Modal";
import DataTable from "./Tables/DataTable";
import { CSVLink } from "react-csv";
import ProjectModal from "../../components/modals/projectmodal";
import apiCall from '../../apicall';
import Sidebar from "../../components/navbar/sidebar";
import * as AiIcons from "react-icons/ai";
import LoadingScreen from "react-loading-screen";
import ConfirmArchiveActive from "../../components/modals/confirmArchiveActive";
import apicall from "../../apicall";



function Projects(props) {
  const [modalShow, setModalShow] = useState(false);
  const [accessRoleId, setAccessRoleId] = useState(localStorage.getItem('access_role_id'));
  const [items, setItems] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionBtnTitle, setActionBtnTitle] = useState('Archive')
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [heading, setHeading] = useState(null);
  const [bodyText, setBodyText] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getItems();
  }, []);

  const handleToggleModal = () => {
    setModalShow(!modalShow);
  };
    
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify({
      'access_role_id' : localStorage.getItem('access_role_id'),
      'accessible_projects' : localStorage.getItem('accessible_projects'),
      'filter' : 'active'
    }),
  };


  const getItems = () => {
    // Calling API to get list of all projects
    fetch(apiCall.SERVER_URL_CA+"api/v1/all/projects" , requestOptions)
      .then((response) => response.json())
      .then((items) => setItems(items.data))
      .catch((err) => console.log(err));
  };

  const addItemToState = (item) => {
    setItems([...items, item]);
  };

  const updateState = (item) => {
    const itemIndex = items.findIndex((data) => data.id === item.id);
    const newArray = [
      ...items.slice(0, itemIndex),
      item,
      ...items.slice(itemIndex + 1)
    ];
    setItems(newArray); 
  };

  const deleteItemFromState = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };


  const toggleConfirmModal = () => {
    setHeading(null);
    setBodyText(null);
    setConfirmModalShow(true);
  }

  const archiveProject = (action) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'access_role_id': localStorage.getItem('access_role_id'),
        'accessible_projects': localStorage.getItem('accessible_projects'),
        'selectedProject': selectedProject, // Include selectedProject in the request body
        'filter' : action
      }),
    };

    setIsLoading(true);
    fetch(apiCall.SERVER_URL_CA + "api/v1/archiveProjects",requestOptions)
    .then((response)=>{
      // setIsLoading(false);
      setSelectedProject([]);
      window.location.reload();
    })

  }

  const toggleProject = (item) =>{
    // Sample array of IDs
    const idArray = [...selectedProject];
    const idToAddOrRemove = item.id;

    // Check if the ID exists in the array
    if (idArray.includes(idToAddOrRemove)) {
      // ID exists, so remove it
      const index = idArray.indexOf(idToAddOrRemove);
      if (index !== -1) {
        idArray.splice(index, 1);
      }
    } else {
      // ID doesn't exist, so add it
      idArray.push(idToAddOrRemove);
    }
    setSelectedProject(idArray);
  }


 const hanldeProjectFilter = (event) => {
  let updatedRequestOptions = JSON.parse(requestOptions.body)
  updatedRequestOptions['filter'] = event.target.value;
  requestOptions['body'] = JSON.stringify(updatedRequestOptions);
  getItems();

  if(event.target.value == 'archive'){
    setActionBtnTitle('Activate')
  }
  else{
    setActionBtnTitle('Archive')
  } 
 }

 const setConfirmModal = () => {
 setHeading('Delete Projects');
 setConfirmModalShow(true);
 setBodyText('Delete ' + selectedProject.length + ' Projects ?');
 } 


 const deleteProjects = async () => {
   setIsSubmitting(true);
   setIsLoading(true);
  const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/delete/project', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',     
        },
      body: JSON.stringify({
          'selectedProject' : selectedProject
      }),
  });

  if(apiResponse.status === 200){
      setIsSubmitting(false);
      setSelectedProject([]);
      window.location.reload();
  }
  else{
      setIsSubmitting(false);
      alert('Some Error Occured');
  }

}


  return (
    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(243, 242, 245)', minHeight: '95vh'}}>
      <ConfirmArchiveActive  show={confirmModalShow} onHide={()=>{setConfirmModalShow(false)}} onConfirm = {heading ? deleteProjects : ()=> archiveProject(actionBtnTitle)} heading={heading ? heading : actionBtnTitle == 'Archive' ? 'Move to Archive': 'Move to Active'} selected={selectedProject.length}  bodyText={bodyText ? bodyText : 'Move ' + selectedProject.length + ' ' + (selectedProject.length > 1 ? 'projects ' : 'project ') + 'to ' + (actionBtnTitle == 'Archive' ? 'archive?' : 'active?')} isSubmitting = {isSubmitting}/>
      <LoadingScreen
      loading={isLoading}
      bgColor="rgba(255,255,255,1)"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc=""
      text=""
    />
     
    <div className="mx-2" style={{ marginTop: '6px'}} ><Sidebar activeRoute={"Projects"} /></div>
    <div style={{ margin: "9px", width: '100%',  }}>
      <Row>
        <Col>
        <div className="counts" style={{display: 'flex'}}>
          {items.length} Project{items.length > 1 && 's'}
          {selectedProject.length > 0 && (<div><button onClick = {toggleConfirmModal} className="btn btn-primary mx-3" >
          {actionBtnTitle === 'Activate' ? 'Retrieve' : actionBtnTitle}
            </button>
            <button className="btn btn-danger" onClick = {setConfirmModal}>Delete</button></div>)} 
        </div>
        {accessRoleId == '2' || (accessRoleId == '3' && localStorage.getItem('can_manage_project') == 'Yes') ? <div onClick={handleToggleModal} style={{ float: 'right' , marginRight: '20px' , padding: '5px', background: "#2E5FE8", borderRadius:'10px'}} > <AiIcons.AiOutlinePlus size={'22'} color={'#fff'}/></div>: null}
        <div style={{ float: 'right' , marginRight: '20px'}} onChange={hanldeProjectFilter}>
        <select className="form-select form-select-sm" id="people-filter">
          <option value="active" selected>Active</option>
          <option value="archive">Archived</option>
        </select>
        </div>

          {/* {accessRoleId == '2' ? <button onClick={handleToggleModal} style={{ float: 'right' , marginRight: '80px' }} className="btn btn-success">Add New Project</button>: null} */}
        </Col>
      </Row>
      <Row style={{marginBottom: "8px"}}>
        <Col>
          {/* <CSVLink
            filename={"db.csv"}
            color="primary"
            style={{ float: "left", marginRight: "10px" }}
            className="btn btn-primary"
            data={items}
          >
            Download CSV
          </CSVLink> */}
          {/* <ModalForm buttonLabel="Add New Project" addItemToState={addItemToState} /> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            items={items}
            updateState={handleToggleModal}
            deleteItemFromState={deleteItemFromState}
            toggleProject={toggleProject}
          />
        </Col>
      </Row>
      {/* <ProjectModal name={'Dharmaj'} /> */}


      {/* Pass the toggle function and modal visibility state to the child component */}
      <ProjectModal show={modalShow} onHide={handleToggleModal} />

    </div>
    </div>
  );
}

export default Projects;
