import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import apicall from "../../apicall";

function RecoverPassword(props) {
  const [otpSubmitting, setOtpSubmitting] = useState(false);
  const [otpChecking, setOtpChecking] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (event.target.id == "recover") {
      setOtpSubmitting(true);

      document.getElementById("user-not-found-div").style.display = "none";
      const apiResponse = await fetch(
        apicall.SERVER_URL_CA + "api/v1/send/OTP",
        {
          method: "POST",
          body: formData,
        }
      );

      let responseData = await apiResponse.json();

      if (responseData.message == "User not found") {
        document.getElementById("user-not-found-div").style.display = "block";
        setOtpSubmitting(false);
      } else if (responseData.message == "OTP Sent Successfully") {
        document.getElementById("recover-div").style.display = "none";
        document.getElementById("email-to-recover").value =
          formData.get("email");
        document.getElementById("otp-div").style.display = "block";
      }
    } else if (event.target.id == "otp") {
      setOtpChecking(true);

      document.getElementById("invalid-otp-div").style.display = "none";
      document.getElementById("otp-expired-div").style.display = "none";

      const apiResponse = await fetch(
        apicall.SERVER_URL_CA + "api/v1/check/otp",
        {
          method: "POST",
          body: formData,
        }
      );

      let otpResponseData = await apiResponse.json();

      //console.log(otpResponseData);
      if (otpResponseData.message == "OTP match found") {
        document.getElementById("otp-div").style.display = "none";
        document.getElementById("email-to-reset").value = formData.get("email");
        document.getElementById("new-pw-div").style.display = "block";
      } else if (otpResponseData.message == "Invalid OTP") {
        setOtpChecking(false);
        document.getElementById("invalid-otp-div").style.display = "block";
      } else if (otpResponseData.message == "OTP Expired") {
        setOtpChecking(false);
        document.getElementById("otp-expired-div").style.display = "block";
        document.getElementById("check-otp-btn").disabled = true;
      }
    } else if (event.target.id == "new-pw") {
      document.getElementById("passwords-mismatch-div").style.display = "none";

      if (formData.get("password") !== formData.get("confirm_password")) {
        document.getElementById("passwords-mismatch-div").style.display =
          "block";
      } else {
        const apiResponse = await fetch(
          apicall.SERVER_URL_CA + "api/v1/reset/password",
          {
            method: "POST",
            body: formData,
          }
        );

        let responseData = await apiResponse.json();
        if ((responseData.message = "Password Reset Successful")) {
          document.getElementById("new-pw-div").style.display = "none";
          document.getElementById("reset-success-div").style.display = "block";
        }
      }
    } else if (event.target.id == "reset-success") {
      window.location.href = "/";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "150px",
        justifyContent: "center",
      }}
    >
      <div
        className="shadow"
        style={{
          width: "500px",
          textAlign: "center",
          border: "1px solid #d3d3d3",
          height: "fit-content",
          padding: "30px",
          borderRadius: "10px",
        }}
      >
        <div id="recover-div">
          <AiIcons.AiFillUnlock
            style={{ fontSize: "24px" }}
            className="text-primary mb-1"
          />
          <h4 style={{ fontWeight: "600" }}>Recover Password</h4>
          <p>
            Please enter the email address associated with your account and
            we'll send an email with instructions on how to recover your
            password.
          </p>
          <form onSubmit={handleSubmit} id="recover">
            <div
              id="user-not-found-div"
              style={{
                textAlign: "left",
                border: "1px solid red",
                padding: "10px",
                backgroundColor: "#ffebe8",
                display: "none",
              }}
              className="mb-3"
            >
              <p style={{ fontWeight: "800", marginBottom: "2px" }}>
                No User Found
              </p>
              <p style={{ marginBottom: "0px" }}>
                We could not find any user associated with the email you
                provided. Please try again with other email.
              </p>
            </div>
            <Form.Group>
              <Form.Label style={{ float: "left" }}>
                Enter your Email
              </Form.Label>
              <Form.Control type="email" name={"email"} required />
            </Form.Group>
            <button
              className="btn btn-primary mt-3"
              style={{ width: "100%" }}
              type="submit"
              disabled={otpSubmitting}
            >
              {otpSubmitting ? "Sending OTP.." : "Send"}
            </button>
          </form>
          <p className="mt-2 text-secondary" style={{ fontWeight: "600" }}>
            Already have an account?{" "}
            <span className="text-primary">
              <a href="/" style={{ textDecoration: "none" }}>
                Login
              </a>
            </span>
          </p>
        </div>

        {/* Enter OTP Form */}
        <div style={{ display: "none" }} id="otp-div">
          <BsIcons.BsShieldLockFill
            style={{ fontSize: "24px" }}
            className="text-primary mb-1"
          />
          <h4 style={{ fontWeight: "600" }}>Password Recovery Code</h4>
          <p>
            We've sent a 4-digit password recovery code to your email which is
            valid for the next 10 minutes. Please enter the code to proceed
          </p>
          <form onSubmit={handleSubmit} id="otp">
            <input type="text" name="email" id="email-to-recover" hidden />
            <div
              id="invalid-otp-div"
              style={{
                textAlign: "left",
                border: "1px solid red",
                padding: "10px",
                backgroundColor: "#ffebe8",
                display: "none",
              }}
              className="mb-3"
            >
              <p style={{ fontWeight: "800", marginBottom: "2px" }}>
                Invalid OTP
              </p>
              <p style={{ marginBottom: "0px" }}>
                The entered OTP does not match with the one we sent on your
                email. Please try again.
              </p>
            </div>
            <div
              id="otp-expired-div"
              style={{
                textAlign: "left",
                border: "1px solid red",
                padding: "10px",
                backgroundColor: "#ffebe8",
                display: "none",
              }}
              className="mb-3"
            >
              <p style={{ fontWeight: "800", marginBottom: "2px" }}>
                OTP Expired
              </p>
              <p style={{ marginBottom: "0px" }}>
                The entered OTP is expired. Please{" "}
                <a
                  className="text-primary"
                  href="/recover-password"
                  style={{ textDecoration: "none" }}
                >
                  Click Here
                </a>{" "}
                to go back and try again.
              </p>
            </div>
            <Form.Group>
              <Form.Label style={{ float: "left" }}>Enter OTP</Form.Label>
              <Form.Control
                type="password"
                name={"otp"}
                maxLength="4"
                required
                style={{ textAlign: "center" }}
              />
            </Form.Group>
            <button
              className="btn btn-primary mt-3"
              id="check-otp-btn"
              style={{ width: "100%" }}
              type="submit"
              disabled={otpChecking}
            >
              {" "}
              {otpChecking ? "Validating.." : "Validate"}
            </button>
          </form>
        </div>

        {/* Enter New Password Form*/}
        <div style={{ display: "none" }} id="new-pw-div">
          <AiIcons.AiFillLock
            style={{ fontSize: "24px" }}
            className="text-primary mb-1"
          />
          <h4 style={{ fontWeight: "600" }}>Create a new password</h4>
          <p>Please enter a new password for your account.</p>
          <div
            id="passwords-mismatch-div"
            style={{
              textAlign: "left",
              border: "1px solid red",
              padding: "10px",
              backgroundColor: "#ffebe8",
              display: "none",
            }}
            className="mb-3"
          >
            <p style={{ fontWeight: "800", marginBottom: "2px" }}>
              Password Mismatch
            </p>
            <p style={{ marginBottom: "0px" }}>
              The entered passwords do not match each other. Please try again.
            </p>
          </div>
          <form onSubmit={handleSubmit} id="new-pw">
            <input type="text" name="email" id="email-to-reset" hidden />
            <Form.Group>
              <Form.Label style={{ float: "left" }}>Password</Form.Label>
              <Form.Control
                type="password"
                name={"password"}
                minLength="8"
                required
              />
              {/* <p>Password </p> */}
              <br />
              <Form.Label style={{ float: "left" }}>
                Confirm Password
              </Form.Label>
              <Form.Control
                type="password"
                name={"confirm_password"}
                minLength="8"
                required
              />
            </Form.Group>
            <button
              className="btn btn-primary mt-3"
              style={{ width: "100%" }}
              type="submit"
            >
              Reset Password
            </button>
          </form>
        </div>

        {/* Reset Complete Form*/}
        <div style={{ display: "none" }} id="reset-success-div">
          <AiIcons.AiFillCheckCircle
            style={{ fontSize: "40px" }}
            className="text-primary mb-1"
          />
          <h4 style={{ fontWeight: "600" }}>Password Reset Successful</h4>
          <p>
            Your password reset was successful. You can now proceed to sign-in
            to your account.
          </p>
          <form onSubmit={handleSubmit} id="reset-success">
            <button
              className="btn btn-primary mt-2"
              style={{ width: "100%" }}
              type="submit"
            >
              Proceed to Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RecoverPassword;
