import React from "react";
import Sidebar from "../../components/navbar/sidebar";

import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useEffect } from "react";
import OtpInput from "react-otp-input";
import apicall from "../../apicall";
import { useState } from "react";
import "../../App.css";

// Define a functional component
function Settings() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [oldEmail, setOldEmail] = useState();
  const [personId, setPersonId] = useState(localStorage.getItem("person_id"));
  const [divToShow, setDivToShow] = useState("basic-info");
  const [otpValue, setOtpValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      person_id: localStorage.getItem("person_id"),
    }),
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const apiResponse = await fetch(
      apicall.SERVER_URL_CA + "api/v1/edit/basicinfo",
      {
        method: "POST",
        body: formData,
      }
    );

    if (apiResponse.ok) {
      window.location.reload();
    } else {
      // Handle errors or non-successful responses here
      console.error("API request failed with status:", apiResponse.status);
    }
  };

  useEffect(() => {
    fetch(apicall.SERVER_URL_CA + "api/v1/get/person", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setOldEmail(data.data.email);
        setEmail(data.data.email);
        setName(data.data.name);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });
  }, []);

  const handleInputChange = (event) => {
    if (event.target.id == "name") {
      setName(event.target.value);
    }

    if (event.target.id == "email") {
      setEmail(event.target.value);
    }
  };

  const switchDiv = async (divName) => {
    switch (divName) {
      case "enter-otp":
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", email);
        const apiResponse = await fetch(
          apicall.SERVER_URL_CA + "api/v1/send/OTP",
          {
            method: "POST",
            body: formData,
          }
        );
        let responseData = await apiResponse.json();
        setIsSubmitting(false);
        if (responseData.status === "Success") setDivToShow(divName);
        else alert(responseData.message);
        break;

      case "change-password":
        setIsSubmitting(true);
        let otpFormFata = new FormData();
        otpFormFata.append("email", email);
        otpFormFata.append("otp", otpValue);
        const validateOtpResponse = await fetch(
          apicall.SERVER_URL_CA + "api/v1/check/otp",
          {
            method: "POST",
            body: otpFormFata,
          }
        );

        setIsSubmitting(false);
        let otpresponseData = await validateOtpResponse.json();
        //console.log(otpresponseData, 'xyz');
        if (otpresponseData.status === "Success") setDivToShow(divName);
        else alert(otpresponseData.message);
        break;

      case "reset":
        let new_pw = document.getElementById("new-pw").value;
        let confirm_new_pw = document.getElementById("confirm-new-pw").value;
        setIsSubmitting(true);

        if (new_pw === confirm_new_pw) {
          let pwFormData = new FormData();
          pwFormData.append("email", email);
          pwFormData.append("password", confirm_new_pw);
          const pwResponse = await fetch(
            apicall.SERVER_URL_CA + "api/v1/reset/password",
            {
              method: "POST",
              body: pwFormData,
            }
          );

          let pwResoponseData = await pwResponse.json();
          setIsSubmitting(false);
          if (pwResoponseData.status === "Success") {
            // setDivToShow('basic-info');
            alert("Password Changed Successfully");
            window.location.href = "/settings";
          } else alert(pwResoponseData.message);
        } else {
          alert("Both passwords should match. Please try again");
          return;
        }

      default:
        setDivToShow(divName);
        break;
    }
  };

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const moveBack = () => {
    switch (divToShow) {
      case "send-otp":
        setDivToShow("basic-info");
        break;
      case "enter-otp":
        setDivToShow("send-otp");
        break;
      case "change-password":
        setDivToShow("enter-otp");
        break;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgb(243, 242, 245)",
          minHeight: "100vh",
        }}
      >
        <div className="mx-2">
          <Sidebar activeRoute="Settings" />
        </div>

        {divToShow != "basic-info" && (
          <div
            onClick={moveBack}
            style={{ height: "fit-content", width: "fit-content" }}
          >
            <h5
              style={{ marginTop: "50px", marginLeft: "30px" }}
              className="reset-password text-primary"
            >
              <span style={{ verticalAlign: "text-bottom" }}>&#8592;</span> Back{" "}
            </h5>
          </div>
        )}

        {/* Basic Info Div: */}
        {divToShow === "basic-info" && (
          <div id="basic-info" className="mt-3 mx-4" style={{ width: "50%" }}>
            <h2 style={{ float: "left" }}>Settings</h2>
            <br />
            <form className="mt-5" onSubmit={handleSubmit}>
              <input type="email" value={oldEmail} name="old_email" hidden />
              <input type="text" value={personId} name="person_id" hidden />
              <FormGroup>
                <Label
                  for="exampleEmail"
                  style={{ float: "left", fontSize: "18px" }}
                >
                  Name
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  id="name"
                />
              </FormGroup>
              <br />
              <FormGroup>
                <Label
                  for="exampleEmail"
                  style={{ float: "left", fontSize: "18px" }}
                >
                  Email
                </Label>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  id="email"
                  disabled
                />
              </FormGroup>
              <br />

              <p
                style={{ float: "left", fontSize: "17px" }}
                className="text-primary reset-password"
                onClick={() => switchDiv("send-otp")}
              >
                Reset Password &#8594;
              </p>
              <Button
                color="primary"
                style={{ float: "", width: "40%" }}
                className="mt-4"
              >
                Update Basic Information
              </Button>
            </form>
          </div>
        )}

        {/* Send OTP Div: */}
        {divToShow === "send-otp" && (
          <div id="send-otp" className="card shadow center-div">
            <h4 style={{ textAlign: "left", fontWeight: "bold" }}>
              Reset your password
            </h4>
            <p
              style={{ textAlign: "left", fontSize: "16px" }}
              className="text-secondary"
            >
              Forgotten your password? Click the designated button below to
              initiate the process of receiving a securely generated One-Time
              Password (OTP) that will be sent to your registered email address.
            </p>
            <form action="">
              <FormGroup>
                <Label
                  for="exampleEmail"
                  style={{ float: "left", fontSize: "15px" }}
                >
                  Email
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={email}
                  disabled={true}
                  onChange={handleInputChange}
                  id="name"
                />
              </FormGroup>
              <Button
                color="primary mb-2"
                style={{ float: "", width: "100%" }}
                className="mt-4"
                onClick={() => switchDiv("enter-otp")}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending OTP..." : "Reset my password"}
              </Button>
            </form>
          </div>
        )}

        {/* Enter Otp Div: */}
        {divToShow === "enter-otp" && (
          <div id="enter-otp" className="card shadow center-div">
            <h4 style={{ textAlign: "left", fontWeight: "bold" }}>
              Reset your password
            </h4>
            <p
              style={{ textAlign: "left", fontSize: "16px" }}
              className="text-secondary"
            >
              We have just sent a 4-digit OTP to your registered email address.
              Enter the code here to proceed
            </p>
            <form action="">
              <FormGroup>
                <OtpInput
                  renderSeparator={<span>•</span>}
                  numInputs={4}
                  value={otpValue}
                  renderInput={(props) => <input {...props} />}
                  onChange={handleOtpChange}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                  isInputNum={true} // Add this line if each input should only accept numbers
                  inputStyle={{
                    width: "40px", // Adjust the width as needed
                    height: "40px", // Adjust the height as needed
                    fontSize: "16px", // Adjust the font size as needed
                    margin: "0 5px",
                    borderRadius: "4px", // Add margin for better spacing
                  }}
                />
              </FormGroup>
              <p
                style={{
                  float: "left",
                  marginBottom: "0px",
                  fontWeight: "700",
                }}
                className="text-primary mt-2"
              >
                OTP Sent Successfully
              </p>
              <Button
                color="primary mb-2"
                style={{ float: "", width: "100%" }}
                className="mt-4"
                onClick={() => switchDiv("change-password")}
                disabled={otpValue.length !== 4 || isSubmitting}
              >
                {isSubmitting ? "Validating OTP..." : "Validate OTP"}
              </Button>
            </form>
          </div>
        )}

        {/* Change Password Div: */}
        {divToShow === "change-password" && (
          <div id="send-otp" className="card shadow center-div">
            <h4 style={{ textAlign: "left", fontWeight: "bold" }}>
              Change Password
            </h4>
            <form action="">
              <FormGroup className="mt-4">
                <Label
                  for="exampleEmail"
                  style={{ float: "left", fontSize: "15px" }}
                >
                  New Password
                </Label>
                <Input type="password" name="password" id="new-pw" />
              </FormGroup>
              <FormGroup className="mt-4">
                <Label
                  for="exampleEmail"
                  style={{ float: "left", fontSize: "15px" }}
                >
                  Confirm New Password
                </Label>
                <Input
                  type="password"
                  name="confirm_password"
                  id="confirm-new-pw"
                />
              </FormGroup>
              <Button
                color="primary mb-2"
                style={{ float: "", width: "100%" }}
                className="mt-4"
                onClick={() => switchDiv("reset")}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating Password..." : "Change Password"}
              </Button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Settings;
