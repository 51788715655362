import React from "react";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as GoIcons from "react-icons/go";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

// Your code to get 'access_role_id' from local storage
const accessRoleId = localStorage.getItem("access_role_id");

export const SidebarData = [
  {
    title: "Schedule",
    path: "/schedule",
    icon: <AiIcons.AiOutlineAlignRight />,
    cName: "nav-text",
  },
  /* {
    title: "Reports",
    path: "#",
    icon: <MdIcons.MdGraphicEq />,
    cName: "nav-text",
    hr: true,
  }, */
  {
    title: "",
    path: "/settings",
    icon: <BsIcons.BsGear />,
    cName: "nav-text",
  },
  {
    title: "",
    path: "#",
    icon: <AiIcons.AiOutlineBell/>,
    cName: "nav-text",
    name: 'notification'
  },
  {
    title: "",
    path: "mailto:support@dwtc.com",
    icon: <BsIcons.BsQuestionCircle />,
    cName: "nav-text",
  },
  {
    title: "",
    path: "/",
    icon: <AiIcons.AiOutlinePoweroff />,
    cName: "nav-text",
  },
];

// Conditionally exclude the "People" item based on access_role_id
if (accessRoleId !== "8") {
  // Insert the "People" item as the second object in the array
  SidebarData.splice(1, 0, {
    title: "People",
    path: "/people",
    icon: <FiIcons.FiUsers />,
    cName: "nav-text",
  });

    SidebarData.splice(2, 0, {
    title: "Projects",
    path: "/projects",
    icon: <FiIcons.FiFolder />,
    cName: "nav-text",
    hr: true,
  });
}
