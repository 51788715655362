import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import apicall from "../../apicall";
import moment from 'moment';
import { IoMdReturnLeft } from "react-icons/io";
import SearchableDropdown from "../searchDropdown/SearchableDropdown";
import { Input } from "reactstrap";

function AddProgressModal(props) {
    const [eventTitle, setEventTitle] = useState(props.taskTitle);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState("");
    const [hoursPerDay, setHoursPerDay] = useState(props.hoursPerDay);
    const [notes, setNotes] = useState(props.notes);
    const [editStartDate , setEditStartDate] = useState(props.startDate);
    const [editEndDate , setEditEndDate] = useState(props.endDate);
    const [allocationId , setAllocationId] = useState(props.allocationId);
    const [value, setValue] = useState(props.taskTitle);
    const [tasks, setTasks] = useState( []); 
    const [isSubmitting, setIsSubmitting] = useState(false);



    const getTasks = () => {

        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
              'slot_id' : props.currentAddEvent.slotId 
            }),
          };

          fetch(apicall.SERVER_URL_CA + "api/v1/get/tasks", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setTasks(data.data);
        })
          .catch((error) => {
              console.error('Error fetching data from the API: ', error);
          });
    }

    useEffect(() => {

        getTasks();

        // Set a default start date
        const defaultStartDate = new Date(props.currentAddEvent.start);
        defaultStartDate.setHours(8); // Set the desired hour
        defaultStartDate.setMinutes(0); // Set the desired minutes


        const defaultEditStartDate = new Date(props.startDate);
        defaultEditStartDate.setHours(8); // Set the desired hour
        defaultEditStartDate.setMinutes(0); // Set the desired minutes


        
        const defaultEditEndDate = new Date(props.endDate);
        defaultEditEndDate.setHours(8); // Set the desired hour
        defaultEditEndDate.setMinutes(0); // Set the desired minutes
        defaultEditEndDate.setDate(defaultEditEndDate.getDate() - 1);



        setEditEndDate(formatDate(defaultEditEndDate))
        setEditStartDate(formatDate(defaultEditStartDate))
        setStartDate(formatDate(defaultStartDate));
        setEndDate(formatDate(defaultStartDate));
        setEventTitle(props.taskTitle);
        setValue(props.taskTitle);
        setHoursPerDay(props.hoursPerDay);
        setNotes(props.notes);
        setAllocationId(props.allocationId);
    }, [props]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        
        return `${year}-${month}-${day}`;
    };

    function removeTimeFromDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const handleTitleChange = (event) => {
        setEventTitle(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleNotes = (event) => {
        setNotes(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleEditStartDateChange = (event) => {
        setEditStartDate(event.target.value);
    }

    const handleEditEndDateChange = (event) => {
        setEditEndDate(event.target.value);
    }

    function handleHoursPerDay(event) {
        if(event.target.value > 24){
            event.target.value = 24;
        }
        if(event.target.value < 0){
            event.target.value = 0;
        }

        setHoursPerDay(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        localStorage.setItem('current_calendar', props.current_calendar);
        props.schedulerStart  ? localStorage.setItem('scheduler_start', props.schedulerStart) : localStorage.setItem('scheduler_start', null); 
        props.schedulerEnd  ? localStorage.setItem('scheduler_end', props.schedulerEnd) : localStorage.setItem('scheduler_end', null); 
        if(localStorage.getItem('access_role_id') == '8')
        {
                setIsSubmitting(true);
                let apiURL = 'api/v1/add/logtime'
                if(props.modalTitle == 'Edit Allocation'){
                    apiURL = 'api/v1/edit/allocation'
                }
                const formData = new FormData(event.target);
                const apiResponse = await fetch(apicall.SERVER_URL_CA + apiURL, {
                    method: 'POST',
                    body: formData,
                });
            
                if (apiResponse.status === 200) {
                    // const responseData = await apiResponse.json();
                    // let addEventData = props.currentAddEvent;
                    
                    // let newFreshId = 0;
                    // addEventData.schedulerData.events.forEach((item) => {
                    //     if (item.id >= newFreshId) newFreshId = item.id + 1;
                    // });
                    
                    // let newEvent = {};
                    // if(eventTitle == 'Leave'){
                    //     newEvent = {
                    //         id: newFreshId,
                    //         title: eventTitle,
                    //         start: startDate+" 00:00:00",//addEventData.start,
                    //         end: endDate+" 23:59:59",//addEventData.end,
                    //         resourceId: addEventData.slotId,
                    //         bgColor: responseData.border_color,
                    //         total_hours : 0 ,
                    //         type : 3
                    //     };
                    // }
                    // else{
                    //     newEvent = {
                    //         id: newFreshId,
                    //         title: eventTitle,
                    //         start: startDate+" 00:00:00",//addEventData.start,
                    //         end: endDate+" 23:59:59",//addEventData.end,
                    //         resourceId: addEventData.slotId,
                    //         bgColor: responseData.border_color,
                    //         total_hours : calculateTotalHours(startDate,endDate,hoursPerDay),
                    //         type : 2 
                    //     };
                    // }
                    // addEventData.schedulerData.addEvent(newEvent);
                    // props.onHide();
                    window.location.reload();
                }
                else{
                    setIsSubmitting(false);
                }
        }
        else{
            if(props.task == 'edit'){
                setIsSubmitting(true);
                //Call edit allocation api
                const formData = new FormData(event.target);

                const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/edit/allocation", {
                    method: 'POST',
                    body: formData,
                });

                if (apiResponse.status === 200) {
                    window.location.reload();
                }
                else{
                    setIsSubmitting(false);
                }
            }
            else if(props.task == 'manageleave'){
                
                const formData = new FormData(event.target);
                let approveLeave = false;





                    setIsSubmitting(true);
                    const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/approve/leave", {
                        method: 'POST',
                        body: formData,
                    });

                    if (apiResponse.status === 200) {
                        window.location.reload();
                    }
                    else{
                    setIsSubmitting(false);
                    }
                
            }
            else if(props.task == 'editmilestone'){
                setIsSubmitting(true);
                const formData = new FormData(event.target);

                const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/edit/milestone", {
                    method: 'POST',
                    body: formData,
                });
                
                if(apiResponse.status === 200){
                    window.location.reload();
                }
                else{
                    setIsSubmitting(false);
                }
            }
        else{
            // alert('hello');
            // return;
                setIsSubmitting(true);
                const formData2 = new FormData(event.target);
                const hoursPerDay = formData2.get('hours_per_day');
        
        
                //API Call:
                const formData = new FormData(event.target);
                const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/add/allocation", {
                    method: 'POST',
                    body: formData,
                });
        
                if (apiResponse.status === 200) {
                   const responseData = await apiResponse.json();
                   let addEventData = props.currentAddEvent;
           
                   let newFreshId = 0;
                   addEventData.schedulerData.events.forEach((item) => {
                     if (item.id >= newFreshId) newFreshId = item.id + 1;
                   });
           
               
                   let newEvent = {
                     id: newFreshId,
                     title: eventTitle,
                     start: startDate+" 00:00:00",//addEventData.start,
                     end: endDate+" 23:59:59",//addEventData.end,
                     resourceId: addEventData.slotId,
                     bgColor: responseData.bgColor,
                     total_hours : calculateTotalHours(startDate,endDate,hoursPerDay)
                   };
           
           
                   addEventData.schedulerData.addEvent(newEvent);
                //    props.addToView(addEventData.schedulerData);
                   props.onHide();
                   window.location.reload();
                } else {
                    setIsSubmitting(false);
                    // Handle other status codes or errors
                    // You can display an error message or take other actions
                }
    }
}
    };

    const calculateTotalHours = (startDate, endDate, hoursPerDay) => {
        const start_date = moment(startDate);
        const end_date = moment(endDate);
    
        const daysDifference = end_date.diff(start_date, 'days');
        const total_hours = (daysDifference + 1) * hoursPerDay;
    
        return total_hours;
      };
    
      const setLeaveMarked = (event) =>{
        if(event.target.checked){
            document.getElementById('leave_marked').value = 'on';
            document.getElementById('hours_per_day').required = false;
        }
        else{
            document.getElementById('leave_marked').value = 'off';
            document.getElementById('hours_per_day').required = true;
        }
      }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static" // Prevents modal from closing when clicking outside
            keyboard={false}
        >
            <Modal.Header closeButton className="modal-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.modalTitle}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
                <Tabs defaultActiveKey="title" id="event-tabs">
                    <Tab eventKey="title" title={props.task == 'editmilestone' ? 'Milestone' : 'Allocation'}>
                            <br />
                            <input type="text" name="person_id" value={localStorage.getItem('person_id')} hidden />
                            {props.task === 'editmilestone' ? (
                              <div>
                            <Form.Group >
                                 <Form.Label>Title</Form.Label>
                                 <Form.Control
                                    type="text"
                                    name = {'milestone_title'}
                                    defaultValue={props.milestone_title}
                                    required

                                />
                                <br />
                                <div className="row">
                                    <div className="col">
                                    <Form.Label>From</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'milestone_start'}
                                    defaultValue={removeTimeFromDate(props.milestone_start)}
                                    required
                                />
                                    </div>
                                    <div className="col">

                                    <Form.Label>To</Form.Label>
                                <Form.Control
                                    type="date"
                                    name = {'milestone_end'}
                                    defaultValue={removeTimeFromDate(props.milestone_end)}
                                    required
                                />

                                <Form.Control
                                    type="text"
                                    name = {'milestone_id'}
                                    value={props.milestone_id}
                                    hidden
                                />
                                    </div>
                                </div>

                            </Form.Group>
                              </div>
                            ) : props.task === 'manageleave' ? (
                                 null // Render nothing
                            ) : (
                              <div>
                            <Form.Group >
                                 <Form.Label>Task</Form.Label>
                                      <SearchableDropdown
                                      options={tasks}
                                      label="name"
                                      id="id"
                                      name={'title'}
                                      selectedVal={value}
                                      handleChange={(val) => setValue(val)}
                                    />
                            </Form.Group>
                            <div className="row my-3">
                                <div className="col">
                                    <Form.Group >
                                        <Form.Label>Hours Per Day</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name = {'hours_per_day'}
                                                onChange={handleHoursPerDay}
                                                id={'hours_per_day'}
                                                value = {hoursPerDay}
                                                required
                                            />
                                     </Form.Group>
                                </div>
                                <div className="col">
                                {props.startDate ? 
                            <Form.Group >
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={editStartDate}
                                    onChange={handleEditStartDateChange}
                                    name = {'start_date'}
                                />
                            </Form.Group> :
                             <Form.Group >
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    name = {'start_date'}
                                />
                            </Form.Group>}
                                </div>
                                <div className="col">
                            
                            {props.endDate ? 
                             <Form.Group >
                             <Form.Label>End Date</Form.Label>
                             <Form.Control
                                 type="date"
                                 value={editEndDate}
                                 onChange={handleEditEndDateChange}
                                 name = {'end_date'}
                             />
                         </Form.Group> :
                            <Form.Group >
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                name = {'end_date'}
                            />
                        </Form.Group>
                        
                            }
                                </div>
                            </div>

                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name = {'notes'}
                                    value = {notes}
                                    onChange = {handleNotes}
                                />
                            </Form.Group>

                              </div>
                            )}

                            {allocationId ? <input type="text" value={allocationId} name="allocation_id" hidden />: null}
                            <input type="text"  value={props.currentAddEvent.slotId} name="slot_id" hidden/>
                            <Form.Label check >
                            {
                            localStorage.getItem('access_role_id') === '3' && props.task === 'manageleave' ? (
                              <div>
                                <input type="text" name="leave_id" value={props.leave_id} hidden />
                                <Input type="checkbox" style={{ fontSize: '18px' }} name="approve_leave" defaultChecked = {props.leave_status == 'Approved' ? true : false}/>
                                <span style={{ fontSize: '18px' }}> Approve Leave</span>
                              </div>
                            ) : (
                              localStorage.getItem('access_role_id') === '8' && props.modalTitle != 'Edit Allocation' ? (
                                <div>
                                    <input type="text" id="leave_marked" value="off" hidden />
                                    <br />
                                  {/* <Input type="checkbox" style={{ fontSize: '18px' }} name="leave" id="mark_as_leave" onClick={setLeaveMarked} />
                                  <span style={{ fontSize: '18px' }}> Mark as Leave</span> */}
                                </div>
                              ) : null
                            )
                            }

                         </Form.Label>
                    </Tab>
  
                </Tabs>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                {/* <Button onClick={props.onHide}>Close</Button> */}
                {/* <Button type='submit' disabled={isSubmitting}>{isSubmitting ? 'Add and Invite Person...' : 'Add and Invite Person'}</Button> */}
                <Button type="submit" disabled={isSubmitting}>
                  {localStorage.getItem('access_role_id') === '8'
                    ? isSubmitting
                      ? props.modalTitle + '...'
                      : props.modalTitle
                    : isSubmitting
                      ? props.modalTitle + '...'
                      : props.modalTitle
                  }
                </Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddProgressModal;
