import moment from "moment";
//const SERVER_URL_CA = "http://localhost:8000/";
//const SERVER_URL_CA = "http://192.168.5.218:8000/";
const SERVER_URL_CA = "https://dwtc.sixthstar.in/";

const setDebug = true;

var PostCall = function (method, data, callback, errorcallback) {
  if (setDebug) console.log(SERVER_URL_CA + method, JSON.stringify(data));

  fetch(SERVER_URL_CA + method, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    timeout: 30000,
    body: data,
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      errorcallback(error);
    });
};

// GEtCall
var GetCall = function (method, callback, errorcallback) {
  if (setDebug) console.log(SERVER_URL_CA + method);
  try {
    if (SERVER_URL_CA != null) {
      fetch(SERVER_URL_CA + method, {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          pragma: "no-cache",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        timeout: 30000,
      })
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          callback(responseData);
        })
        .catch((error) => {
          errorcallback(error);
        });
    }
  } catch (e) {
    if (setDebug) {
      console.log("Error module:PostCall");
    }
    errorcallback();
  }
};

const calculateTotalHours = (startDate, endDate, hoursPerDay) => {
  // alert(endDate);

  const start_date = moment(startDate);
  const end_date = moment(endDate);
  const daysDifference = end_date.diff(start_date, "days");
  const total_hours = daysDifference * parseInt(hoursPerDay);
  return total_hours;
};

export default {
  PostCall,
  GetCall,
  SERVER_URL_CA,
  calculateTotalHours,
};
