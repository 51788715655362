import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';



function ConfirmArchiveActive(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleModal = () =>{
        props.onHide();
    }

    const confirm = () => {
      setIsSubmitting(true);
       props.onConfirm();
    }

    return (
      <div>
        <Modal
            {...props}
            backdrop="static" // Prevents modal from closing when clicking outside
            keyboard={false}
        >
            <Modal.Header  className='modal-header'> 
            <h5>{props.heading}</h5>
            </Modal.Header>
            <Modal.Body>
            <p>{props.bodyText}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-danger btn-sm' onClick={confirm} disabled={isSubmitting}>{isSubmitting ? props.heading+'...' : props.heading}</button>
                <button className='btn btn-secondary btn-sm' onClick={toggleModal} disabled={isSubmitting}>Cancel</button>
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
  
  
export default ConfirmArchiveActive;
