import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Row, Col } from "react-bootstrap";
// import ManagesMultiSelect from
import apicall from "../../apicall";
import ManagesMultiSelect from "../chipInput/ManagesMultiSelect";

import "../../index.css";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import SearchableDropdown from "../searchDropdown/SearchableDropdown";
import ChipInput from "../chipInput/ChipInput";

import "../../App.css";

function PeopleModal(props) {
  const [projects, setProjects] = useState([]);
  const [managerAccessDepartments, setmanagerAccessDepartments] = useState([]);
  const [
    selectedmanagerAccessDepartments,
    setSelectedmanagerAccessDepartments,
  ] = useState([]);
  const [department, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [accessRole, setAccessRole] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editdropdownOpen, seteditdropdownOpen] = useState(false);
  const [memberEditDropdownOpen, setMemberEditDropdownOpen] = useState(false);
  const [canViewTitle, setCanViewTitle] = useState("All");
  const [canEditMemberTitle, setcanEditMemberTitle] = useState("Themself");
  const [canViewMemberTitle, setCanViewMemberTitle] = useState("Everyone");
  const [isChecked, setIsChecked] = useState(false);
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
  const [managerAccess, setManagerAccess] = useState("Everyone");
  const [tags, setTags] = useState([]);
  const [memberAccessDepartments, setMemberAccessDepartments] = useState([]);
  const [selectedMemberAccessDepartments, setSelectedMemberAccessDepartments] =
    useState([]);
  const [memberCanView, setMemberCanView] = useState("Everyone");
  const [managerProjects, setManagerProjects] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showmanages, setShowManages] = useState(false);

  // const [canEditTitle, setCanEditTitle] = useState('Choose Access');

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const projectRequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_role_id: localStorage.getItem("access_role_id"),
      accessible_projects: localStorage.getItem("accessible_projects"),
    }),
  };

  const getDepartments = () => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/departments")
      .then((response) => response.json())
      .then((data) => {
        setDepartmentOptions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });
  };

  useEffect(() => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/departments", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let projectsData = data.data;
        setmanagerAccessDepartments(projectsData);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });
  }, [props.show]);

  useEffect(() => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/projects", projectRequestOptions)
      .then((response) => response.json())
      .then((data) => {
        let projectsData = data.data;
        setProjects(projectsData);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });
  }, [props.show]);

  useEffect(() => {
    setCanViewTitle("Everyone");
    setManagerAccess("Everyone");
    setSelectedmanagerAccessDepartments([]);
    setShowProjectDropdown(false);
    setShowManages(false);
    getDepartments();
  }, [props.show]);

  useEffect(() => {
    setAccessRole("");
  }, [props.show]);

  let projectObject = {};
  let projectString = "";

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!document.getElementsByName("department")[0].value) {
      document.getElementById("department-error-text").style.display = "block";
    } else {
      document.getElementById("department-error-text").style.display = "none";
      setIsSubmitting(true);

      const formData = new FormData(event.target);

      try {
        const apiResponse = await fetch(
          apicall.SERVER_URL_CA + "api/v1/add/person",
          {
            method: "POST",
            body: formData,
          }
        );

        if (apiResponse.ok) {
          //window.location.reload();
        } else {
          const errorResponse = await apiResponse.json().catch(() => {
            throw new Error("Failed to parse the error response");
          });
          alert(errorResponse.message || "An error occurred");
        }
      } catch (error) {
        alert(`An error occurred: ${error.message}`);
      } finally {
        setIsSubmitting(false);
        window.location.reload();
      }
    }
  };

  function handleProjects(event) {
    const selectedProject = event.target.value; // Get the selected value from the event
    const optionToRemove = event.target.options[event.target.selectedIndex];
    let id = optionToRemove.getAttribute("id");

    let div = document.createElement("div");
    div.style.borderBottom = "1px solid #D3D3D3";
    div.style.marginLeft = "10px";
    div.style.marginRight = "10px";
    div.style.paddingTop = "15px";
    div.style.paddingBottom = "5px";

    div.setAttribute("value", optionToRemove.text);
    div.setAttribute("name", id);

    let p = document.createElement("p");
    p.innerText = optionToRemove.text;
    div.appendChild(p);

    let span = document.createElement("span");
    span.innerText = "X";
    span.style.float = "right";
    span.style.marginRight = "10px";
    span.style.cursor = "pointer";
    p.appendChild(span);

    projectObject[id] = optionToRemove.text;
    projectString = JSON.stringify(projectObject);
    document.getElementById("projectInput").value = projectString;
    document.getElementById("all-projects-checkbox").hidden = true;
    document.getElementById("all-projects-checkbox-label").hidden = true;

    span.onclick = function (event) {
      var projectsDiv = document.getElementById("projectsDiv");
      if (projectsDiv.childElementCount === 1) {
        document.getElementById("all-projects-checkbox").hidden = false;
        document.getElementById("all-projects-checkbox-label").hidden = false;
      }

      let parentDiv = event.target.parentElement.parentElement;
      let newOption = document.createElement("option");
      newOption.setAttribute("value", parentDiv.getAttribute("value"));
      newOption.setAttribute("id", parentDiv.getAttribute("name"));
      newOption.innerText = parentDiv.getAttribute("value");
      document.getElementById("projectselect").appendChild(newOption);
      parentDiv.remove();

      let proj = event.target.parentElement.parentElement.getAttribute("name");

      const projString = proj.toString();
      if (projectObject.hasOwnProperty(projString)) {
        delete projectObject[projString];
      }

      projectString = JSON.stringify(projectObject);
      document.getElementById("projectInput").value = projectString;
    };

    optionToRemove.remove();
    document.getElementById("projectsDiv").appendChild(div);
  }

  const handleDepartment = (event) => {
    setSelectedDepartment(event);
  };

  const handleAccessRoles = (event) => {
    setAccessRole(event.target.value);
    // document.getElementById('access_role_input').value = event.target.value;
  };

  const togglebtndropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMemberEdit = () => {
    setMemberEditDropdownOpen(!memberEditDropdownOpen);
  };

  const toggleeditbtndropdown = () => {
    seteditdropdownOpen(!editdropdownOpen);
  };

  const handleCanView = (title) => {
    setCanViewTitle(title);
    if (title === "Departments") {
      setShowProjectDropdown(true);
    } else {
      setShowProjectDropdown(false);
      setManagerAccess("Everyone");
    }
  };
  const handleCanViewMember = async (title) => {
    setCanViewMemberTitle(title);
    setMemberCanView(title);

    if (title === "Departments") {
      // API call and set department options
      if (memberAccessDepartments.length === 0) {
        try {
          const apiResponse = await fetch(
            apicall.SERVER_URL_CA + "api/v1/all/departments",
            {
              method: "GET",
            }
          );

          if (apiResponse.ok) {
            const responseData = await apiResponse.json();
            setMemberAccessDepartments(responseData.data);
          } else {
            console.error("Network response was not ok");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      setShowDepartmentDropdown(true);

      if (selectedMemberAccessDepartments.length > 0) {
        setSelectedMemberAccessDepartments([]);
      }
    } else {
      setShowDepartmentDropdown(false);
    }
  };

  const handleCanEditMember = (title) => {
    setcanEditMemberTitle(title);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePeopleYesNo = (event) => {
    if (event.target.innerText == "Yes") {
      event.target.classList.add("btn-success");
      document.getElementById("peopleno").classList.remove("btn-danger");
      setShowManages(true);
    } else {
      event.target.classList.add("btn-danger");
      document.getElementById("peopleyes").classList.remove("btn-success");
      setShowManages(false);
    }

    document.getElementById("people_manager_input").value =
      event.target.innerText;
  };

  const handleProjectYesNo = (event) => {
    if (event.target.innerText == "Yes") {
      event.target.classList.add("btn-success");
      document.getElementById("projectno").classList.remove("btn-danger");
    } else {
      event.target.classList.add("btn-danger");
      document.getElementById("projectyes").classList.remove("btn-success");
    }

    document.getElementById("project_manager_input").value =
      event.target.innerText;
  };

  const handlemanagerAccessDepartments = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];

    if (selectedOption.value) {
      let newSelectedProj = {
        id: selectedOption.value,
        name: selectedOption.innerText,
      };

      let updatedSelectedProjects = [
        ...selectedmanagerAccessDepartments,
        newSelectedProj,
      ];
      setSelectedmanagerAccessDepartments(updatedSelectedProjects);

      let filteredAccessProjects = managerAccessDepartments.filter(
        (item) => item.id != selectedOption.value
      );
      setmanagerAccessDepartments(filteredAccessProjects);

      setManagerAccess(JSON.stringify(updatedSelectedProjects));
    }
  };

  const removeSelectedAccessProjects = (event) => {
    let filteredSelectedAccessProjects =
      selectedmanagerAccessDepartments.filter(
        (item) => item.id != event.target.getAttribute("value")
      );
    setSelectedmanagerAccessDepartments(filteredSelectedAccessProjects);

    const lastIndex = event.target.parentElement.innerText.lastIndexOf("X");
    let projectName = "";
    if (lastIndex !== -1) {
      projectName =
        event.target.parentElement.innerText.slice(0, lastIndex) +
        event.target.parentElement.innerText.slice(lastIndex + 1);
    }

    let newAccessProj = {
      id: event.target.getAttribute("value"),
      name: projectName,
    };

    let updatedAccessProj = [...managerAccessDepartments, newAccessProj];
    setmanagerAccessDepartments(updatedAccessProj);

    setManagerAccess(JSON.stringify(filteredSelectedAccessProjects));
  };

  const handleMemberAccessDepartments = (event) => {
    if (event.target.value) {
      let newDeptObj = {
        id: event.target.value,
        name: event.target.value,
      };

      let updatedSelectedMemberAccessDepts = [
        ...selectedMemberAccessDepartments,
        newDeptObj,
      ];
      setSelectedMemberAccessDepartments(updatedSelectedMemberAccessDepts);

      let updatedMemberAccessDepts = memberAccessDepartments.filter(
        (dept) => dept.id != event.target.value
      );
      setMemberAccessDepartments(updatedMemberAccessDepts);

      setMemberCanView(JSON.stringify(updatedSelectedMemberAccessDepts));
    }
  };

  const removeSelectedAccessDepartments = (event) => {
    let updatedSelectedMemberAccessDepts =
      selectedMemberAccessDepartments.filter(
        (dept) => dept.id != event.target.getAttribute("value")
      );
    setSelectedMemberAccessDepartments(updatedSelectedMemberAccessDepts);

    let newDeptObj = {
      id: event.target.getAttribute("value"),
      name: event.target.getAttribute("value"),
    };

    let updatedMemberAccessDepts = [...memberAccessDepartments, newDeptObj];
    setMemberAccessDepartments(updatedMemberAccessDepts);

    setMemberCanView(JSON.stringify(updatedSelectedMemberAccessDepts));
  };

  const markAllProjects = (event) => {
    document.getElementById("projectselect").hidden = event.target.checked;

    if (!event.target.checked) {
      document.getElementById("projectInput").value = "{}";
      return;
    }

    let allProjects = {};

    projects.forEach((project) => {
      allProjects[project.id] = project.name;
    });

    document.getElementById("projectInput").value = JSON.stringify(allProjects);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static" // Prevents modal from closing when clicking outside
      keyboard={false}
    >
      {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Person 
                </Modal.Title>
            </Modal.Header> */}
      <form onSubmit={handleSubmit}>
        <Modal.Body style={{ minHeight: "430px" }}>
          <Tabs defaultActiveKey="title" id="event-tabs">
            <Tab eventKey="title" title="Info">
              {/* <Form> */}
              <br />
              <Form.Group controlId="eventTitle">
                <Form.Label>Name*</Form.Label>
                <Form.Control type="text" name={"name"} required />
              </Form.Group>

              <br />
              <Form.Group controlId="eventTitle">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  name={"email"}
                  pattern="[^@]+@[^@]+\.[^@]+"
                  required
                />
              </Form.Group>

              <div className="row my-3">
                <div className="col">
                  <Form.Group controlId="selectDropdown">
                    <Form.Label>Role*</Form.Label>
                    <Form.Control as="select" name="role_id" required>
                      <option value="3">Manager</option>
                      <option value="6">Employee</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="selectDropdown">
                    <Form.Label>Person Type*</Form.Label>
                    <Form.Control as="select" name="person_type" required>
                      <option value="employee">Employee</option>
                      <option value="contractor">Contractor</option>
                      <option value="placeholder">Placeholder</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="selectDropdown">
                    <Form.Label>Department*</Form.Label>
                    <SearchableDropdown
                      options={department}
                      label="name"
                      id="department"
                      name={"department"}
                      selectedVal={selectedDepartment}
                      handleChange={(val) => handleDepartment(val)}
                    />
                  </Form.Group>
                </div>
              </div>
              <p
                id="department-error-text"
                className="text-danger"
                style={{ display: "none" }}
              >
                Please select a department
              </p>

              <Form.Group controlId="selectDropdown">
                <Form.Label>Tags</Form.Label>
                <ChipInput setTags={(value) => setTags(value)} tags={tags} />
                <input type="hidden" name="tags" value={tags}></input>
              </Form.Group>

              {/* </Form> */}
            </Tab>
            <Tab eventKey="startDate" title="Access">
              <input
                type="text"
                id="member_can_view"
                name="member_can_view"
                value={memberCanView}
                hidden
              />
              <input
                type="text"
                id="member_can_edit"
                name="member_can_edit"
                value={canEditMemberTitle}
                hidden
              />
              {/* <Form> */}
              <br />
              <Form.Group controlId="selectDropdown">
                <Form.Label>Access</Form.Label>
                <Form.Control
                  as="select"
                  name="access_role_id"
                  onChange={handleAccessRoles}
                >
                  <option selected value="">
                    --No Access Rights--
                  </option>
                  <option value="8">Member</option>
                  <option value="3">Manager</option>
                  {localStorage.getItem("access_role_id") == "2" && (
                    <option value="2">Admin</option>
                  )}
                </Form.Control>
              </Form.Group>
              {/* <input type="text" name="access_role_id" id="access_role_input"></input> */}
              <input
                type="text"
                name="people_manager"
                id="people_manager_input"
                value="No"
                hidden
              ></input>
              <input
                type="text"
                name="project_manager"
                id="project_manager_input"
                value="No"
                hidden
              ></input>
              <input
                type="text"
                name="manager_departments"
                id="manager_departments_input"
                value={managerAccess}
                hidden
              ></input>
              {accessRole == "3" ? (
                <div className="mt-4">
                  <div className="row">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <AiIcons.AiOutlineEye />
                      </h5>
                    </div>
                    <div className="col-8">
                      <h5 style={{ marginBottom: "0px" }}>Can View</h5>
                      <p>Who they can see on the schedule</p>
                      {showProjectDropdown === true ? (
                        <div>
                          <FormGroup className="mb-4">
                            <Label
                              style={{ marginLeft: "2px", fontSize: "14px" }}
                              className="mb-1"
                            >
                              Departments
                            </Label>
                            <FormGroup>
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                onChange={handlemanagerAccessDepartments}
                              >
                                <option value="" selected>
                                  Select Departments
                                </option>
                                {managerAccessDepartments.map((project) => (
                                  <option value={project.id}>
                                    {project.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </FormGroup>
                          {selectedmanagerAccessDepartments.map((project) => (
                            <div>
                              <p
                                style={{
                                  border: "1px solid #D3D3D3",
                                  borderRadius: "7px",
                                  width: "fit-content",
                                  padding: "3px 8px 3px 4px",
                                }}
                                className="shadow"
                              >
                                {project.name}
                                <span
                                  style={{
                                    marginLeft: "50px",
                                    color: "black",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                  onClick={removeSelectedAccessProjects}
                                  value={project.id}
                                >
                                  {" "}
                                  X
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-3">
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={togglebtndropdown}
                        style={{ width: "100%" }}
                      >
                        <DropdownToggle caret color="primary">
                          {canViewTitle}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleCanView("Everyone")}
                          >
                            <span>
                              <FiIcons.FiGlobe
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            Everyone
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={() => handleCanView("Departments")}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <BsIcons.BsBuilding />
                              </span>
                              Departments
                            </p>
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <AiIcons.AiOutlineTeam />
                      </h5>
                    </div>
                    <div className="col-8">
                      <h5 style={{ marginBottom: "0px" }}>People Manager</h5>
                      <p>Can schedule and approve time off requests </p>
                    </div>
                    <div className="col-3">
                      <button
                        className="mx-2 btn btn-danger"
                        type="button"
                        size="sm"
                        id="peopleno"
                        onClick={handlePeopleYesNo}
                      >
                        No
                      </button>
                      <button
                        className="btn "
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={handlePeopleYesNo}
                        id="peopleyes"
                      >
                        Yes
                      </button>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <AiIcons.AiOutlineFolderOpen />
                      </h5>
                    </div>
                    <div className="col-8">
                      <h5 style={{ marginBottom: "0px" }}>Project Manager</h5>
                      <p>Can schedule tasks and manage projects</p>
                    </div>
                    <div className="col-3">
                      <button
                        className="mx-2 btn btn-danger"
                        type="button"
                        size="sm"
                        id="projectno"
                        onClick={handleProjectYesNo}
                      >
                        No
                      </button>
                      <button
                        className="btn "
                        type="button"
                        style={{ cursor: "pointer" }}
                        id="projectyes"
                        onClick={handleProjectYesNo}
                      >
                        Yes
                      </button>
                    </div>
                  </div>

                  <div style={{ borderTopWidth: "2px", borderColor: "grey" }}>
                    <h5>Additional Roles</h5>
                    <label>
                      <input
                        type="checkbox"
                        name="create_edit_people"
                        style={{ marginTop: "2px", marginRight: "5px" }}
                      />
                      <span>Create and edit people</span>
                    </label>
                  </div>
                </div>
              ) : accessRole == "2" ? (
                <div className="mt-4">
                  <div className="row">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <AiIcons.AiOutlineEye />
                      </h5>
                    </div>
                    <div className="col-8">
                      <h5 style={{ marginBottom: "0px" }}>Can View</h5>
                      <p>Who they can see on the schedule</p>
                    </div>
                    <div className="col-3">
                      <h6>Everyone</h6>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <FiIcons.FiGlobe />
                      </h5>
                    </div>
                    <div className="col-7">
                      <h5 style={{ marginBottom: "0px" }}>Manages</h5>
                    </div>
                    <div className="col">
                      <h6 style={{ float: "right" }}>
                        All people and projects
                      </h6>
                    </div>
                  </div>
                </div>
              ) : accessRole === "8" ? (
                <div className="mt-4">
                  <div className="row ">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <AiIcons.AiOutlineEye />
                      </h5>
                    </div>
                    <div className="col-8">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 style={{ marginBottom: "0px" }}>Can View</h5>
                      </div>
                      <div>
                        <p>Who they can view on the Schedule</p>
                        {showDepartmentDropdown ? (
                          <div>
                            <label className="mb-1 text-secondary">
                              Departments
                            </label>
                            <select
                              class="form-select form-select-sm"
                              aria-label="Small select example"
                              onChange={handleMemberAccessDepartments}
                            >
                              <option selected value="">
                                Select Departments
                              </option>
                              {memberAccessDepartments.map((dept) => (
                                <option value={dept.name}>{dept.name}</option>
                              ))}
                            </select>

                            <div className="mt-4">
                              {selectedMemberAccessDepartments.map((dept) => (
                                <p
                                  style={{
                                    borderBottom: "1px solid #D3D3D3",
                                    paddingBottom: "7px",
                                  }}
                                >
                                  {dept.name}
                                  <span
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                    onClick={removeSelectedAccessDepartments}
                                    value={dept.name}
                                  >
                                    X
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-3">
                      <ButtonDropdown
                        isOpen={dropdownOpen}
                        toggle={togglebtndropdown}
                        style={{ width: "100%" }}
                      >
                        <DropdownToggle caret color="primary">
                          {canViewMemberTitle}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleCanViewMember("Everyone")}
                          >
                            <span>
                              <FiIcons.FiGlobe
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            Everyone
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={() => handleCanViewMember("Departments")}
                          >
                            <span>
                              <BsIcons.BsBuilding
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            Departments
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={() => handleCanViewMember("Themself")}
                          >
                            <span>
                              <AiIcons.AiOutlineUser
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            Themself
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-1 access-div">
                      <h5 className="access-icon">
                        <BsIcons.BsPencil />
                      </h5>
                    </div>
                    <div className="col-8">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 style={{ marginBottom: "0px" }}>Can Edit</h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Who they can edit on the schedule</p>
                      </div>
                    </div>
                    <div className="col-3">
                      <ButtonDropdown
                        isOpen={memberEditDropdownOpen}
                        toggle={toggleMemberEdit}
                        style={{ width: "100%" }}
                      >
                        <DropdownToggle caret color="primary">
                          {canEditMemberTitle}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleCanEditMember("Themself")}
                          >
                            <span>
                              <AiIcons.AiOutlineUser
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            Themself
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={() => handleCanEditMember("No One")}
                          >
                            <span>
                              <FiIcons.FiGlobe
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </span>
                            No One
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* </Form> */}
            </Tab>

            {/* <Tab eventKey="availability" title='Availibility'>

                    </Tab> */}

            <Tab eventKey="endDate" title="Projects">
              {/* <Form> */}
              <br />

              <Form.Group controlId="selectDropdown">
                <Form.Label>Assign a Project</Form.Label>

                <Form.Control
                  as="select"
                  id="projectselect"
                  onChange={handleProjects}
                >
                  <option>--Select Projects--</option>
                  {projects.map((project) => (
                    <option
                      id={project.id}
                      value={project.name}
                      key={project.id}
                    >
                      {project.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {/* </Form> */}
              <input type="text" name="projects" id="projectInput" hidden />
              <input
                type="checkbox"
                id="all-projects-checkbox"
                className="mt-3"
                style={{ fontSize: "16px" }}
                onClick={(event) => markAllProjects(event)}
              />
              <label
                for="all-projects-checkbox"
                id="all-projects-checkbox-label"
                className="mx-1"
                style={{ fontSize: "16px" }}
              >
                Assign all projects
              </label>
              <div id="projectsDiv" className="scrollable-div"></div>
            </Tab>
            {showmanages && (
              <Tab eventKey="manages" title="Manages">
                <div className="mt-4">
                  <label htmlFor="">Manager of</label>
                  <ManagesMultiSelect parent={"addPeople"} />
                </div>
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting
              ? "Add and Invite Person..."
              : "Add and Invite Person"}
          </Button>
          <p
            className="btn btn-secondary"
            onClick={props.onHide}
            style={{ right: "20px", position: "absolute" }}
            hidden={isSubmitting}
          >
            Close
          </p>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default PeopleModal;
