import React, { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelectDropdwon = (props) => {
    const [options, setOptions] = useState(props.personsOptions);
    const [selectedValue, setSelectedValue] = useState([]);

    /* useEffect(() =>{
        setChips(props.tags);
    }, [props.tags]) */

    const onSelect = (selectedList, selectedItem) =>{
        setSelectedValue(selectedList);
    }
    
    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
    }


    return(
        <MultiSelect
        options={options}
        value={props.selectedValue}
        onChange={props.setSelectedValue}
        labelledBy="Select"
      />

    )

}

export default MultiSelectDropdwon;
