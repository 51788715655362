
import React, { useState } from "react";
import "./style.css";

function CustomDropdown (props) {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(props.currentLeave ? props.currentLeave : "Choose Leave Type");
  const [colorsObj , setColorsObj] = useState({
    'Annual Leave' : '#fc6629',
    'Compassionate Leave' : '#5ddefa',
    'Paid Time Off' : '#0094f7',
    'Paternal Leave' : '#988369',
    'Public Holiday' : '#1ca472' ,
    'Sick Leave' : '#ea4650',
    'Unpaid Time Off' : '#db419b'
})

  function handleBlur(e) {
    //console.log(e);
  }

  return (
    <div className="custom-dropdown-app">
      <div className="custom-dropdown">
        <div
          onClick={(e) => {
            setIsActive(!isActive);
          }}
          className="custom-dropdown-btn"
        >
          {selected}
          <span
            className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
          />
        </div>
        <div
          className="custom-dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
        >
          <div
            onClick={(e) => {
              setIsSelected('Annual Leave');
              setIsActive(!isActive);
              props.setTimeOffType('Annual Leave')
              props.setTimeOffBgColor(colorsObj['Annual Leave']);
            }}
            className="item"
          >
           <span className="inline-span" style={{ backgroundColor : '#fc6629'}}>ab</span>Annual Leave
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Compassionate Leave');
              setIsActive(!isActive);
              props.setTimeOffType('Compassionate Leave')
              props.setTimeOffBgColor(colorsObj['Compassionate Leave']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#5ddefa'}}>ab</span>Compassionate Leave
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Paid Time Off');
              setIsActive(!isActive);
              props.setTimeOffType('Paid Time Off')
              props.setTimeOffBgColor(colorsObj['Paid Time Off']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#0094f7'}}>ab</span>Paid Time Off
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Paternal Leave');
              setIsActive(!isActive);
              props.setTimeOffType('Paternal Leave')
              props.setTimeOffBgColor(colorsObj['Paternal Leave']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#988369'}}>ab</span>Paternal Leave
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Public Holiday');
              setIsActive(!isActive);
              props.setTimeOffType('Public Holiday')
              props.setTimeOffBgColor(colorsObj['Public Holiday']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#1ca472'}}>ab</span>Public Holiday
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Sick Leave');
              setIsActive(!isActive);
              props.setTimeOffType('Sick Leave')
              props.setTimeOffBgColor(colorsObj['Sick Leave']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#ea4650'}}>ab</span>Sick Leave
          </div>
          <div
            className="item"
            onClick={(e) => {
              setIsSelected('Unpaid Time Off');
              setIsActive(!isActive);
              props.setTimeOffType('Unpaid Time Off')
              props.setTimeOffBgColor(colorsObj['Unpaid Time Off']);
            }}
          >
            <span className="inline-span" style={{ backgroundColor : '#db419b'}}>ab</span>Unpaid Time Off
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDropdown;
