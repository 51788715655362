import React, { useEffect, useState } from "react";
import Basic from "./Basic";
import "./iconstyle.css";
import apicall from "../../apicall";
import Sidebar from "../../components/navbar/sidebar";
import LoadingScreen from "react-loading-screen";
import EditProject from "../projects/Modals/editproject";
import EditPeople from "../people/Modals/editpeople";
import Pusher from "pusher-js";
import { useAuth } from "../../context/AuthContext";

export default function Calendar(props) {
  const [data, setData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState(
    localStorage.getItem("current_calendar")
      ? localStorage.getItem("current_calendar")
      : "Schedule"
  );
  const [modalShow, setModalShow] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [persons, setPerson] = useState([]);
  const [projects, setProjects] = useState([]);
  const [defaultFiltersize, setDefaultFiltersize] = useState("Compact");
  const [editPeopleModal, setEditPeopleModal] = useState(false);
  const [currentpeopledata, setCurrentPeople] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [managedPersons, setManagedPersons] = useState(
    JSON.parse(localStorage.getItem("managed_persons"))
  );

  const [dateLimit, setDateLimit] = useState(
    localStorage.getItem("dateLimit")
      ? JSON.parse(localStorage.getItem("dateLimit"))
      : getDefaultDateLimit()
  );

  const { toogleToast } = useAuth();

  function getDefaultDateLimit(start_date, end_date) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;

    let isYearView = localStorage.getItem("defaultView") == 4;

    const defaultStartDate = !isYearView
      ? `${currentYear}-${formattedMonth}-01`
      : `${currentYear}-01-01`;
    const defaultEndDate = !isYearView
      ? `${currentYear}-${formattedMonth}-31`
      : `${currentYear}-12-31`;

    start_date = start_date || defaultStartDate;
    end_date = end_date || defaultEndDate;

    return {
      start_date,
      end_date,
    };
  }

  useEffect(() => {
    // setDefaultFilter(localStorage.getItem('current_calendar') ? localStorage.getItem('current_calendar') : 'Schedule');
    getResources();
    getallPersons();
    getAllProjects();
    // initializePusher();
  }, []);

  useEffect(() => {
    localStorage.setItem("dateLimit", JSON.stringify(dateLimit));
    localStorage.setItem("scheduler_start", dateLimit.start_date);
    filterEvents({ target: { value: defaultFilter } });
  }, [dateLimit]);

  const initializePusher = () => {
    //Initialize Pusher:
    const pusher = new Pusher("7c9f1a8d354908656152", {
      cluster: "ap2",
      encrypted: true,
    });

    // //console.log(pusher,'pusher')
    pusher.connection.bind("connected", function () {
      //console.log('Socket ID:', pusher.connection.socket_id);

      const response = fetch(apicall.SERVER_URL_CA + "api/v1/add/onlineuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Use an object for headers
        },
        body: JSON.stringify({
          person_id: localStorage.getItem("person_id"),
          person_email: localStorage.getItem("person_email"),
          socket_id: pusher.connection.socket_id,
        }),
      });

      const channel = pusher.subscribe(
        "notification-channel." + pusher.connection.socket_id
      );

      channel.bind("test_event", function (data) {
        alert(data);
      });

      channel.bind("send_notification", function (data) {
        // setShowToastMsg(true);
        toogleToast();
        setTotalNotifications(totalNotifications + 1);
      });

      return () => {
        pusher.unsubscribe("realtime-notification-channel");
      };
    });
  };

  const handleToggleModal = () => {
    setModalShow(!modalShow);
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_role_id: localStorage.getItem("access_role_id"),
      person_id: localStorage.getItem("person_id"),
      accessible_projects: localStorage.getItem("accessible_projects"),
      filter_value: defaultFilter,
      start_date: dateLimit.start_date,
      end_date: dateLimit.end_date,
    }),
  };

  const getallPersons = () => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/persons", {
      method: "POST",
      body: JSON.stringify({
        access_role_id: localStorage.getItem("access_role_id"),
        person_id: localStorage.getItem("person_id"),
        accessible_projects: localStorage.getItem("accessible_projects"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPerson(data.data);
      })
      .catch((error) => {
        console.error("Error fetching person data: ", error);
      });
  };

  const getAllProjects = () => {
    fetch(apicall.SERVER_URL_CA + "api/v1/all/projects", {
      method: "POST",
      body: JSON.stringify({
        access_role_id: localStorage.getItem("access_role_id"),
        accessible_projects: localStorage.getItem("accessible_projects"),
        person_email: localStorage.getItem("person_email"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log('This is data: ',data);
        setProjects(data.data);
      })
      .catch((error) => {
        //console.error("Error fetching project data: ", error);
      });
  };

  const getResources = () => {
    Promise.all([
      fetch(
        apicall.SERVER_URL_CA + "api/v1/all/resources",
        requestOptions
      ).then((response) => response.json()),
      fetch(
        apicall.SERVER_URL_CA + "api/v1/get/scheduledata",
        requestOptions
      ).then((response) => response.json()),
    ])
      .then(([resourcesData, scheduleData]) => {
        // Assuming the API responses are arrays
        setData(resourcesData.data);
        setScheduleData(scheduleData.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
        setIsLoading(false);
      });
  };

  /* const filterEvents = async (filter) => {
    setIsLoading(true);

    let filterValue = filter.target.value;
    localStorage.setItem("current_calendar", filterValue);
    const resourceApiResponse = await fetch(
      apicall.SERVER_URL_CA + "api/v1/all/resources",
      {
        method: "POST",
        body: JSON.stringify({
          access_role_id: localStorage.getItem("access_role_id"),
          person_id: localStorage.getItem("person_id"),
          accessible_projects: localStorage.getItem("accessible_projects"),
          filter_value: filterValue,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resourceApiResponse.status === 200) {
      const ResourceresponseData = await resourceApiResponse.json();
      setData(ResourceresponseData.data);
    }

    const apiResponse = await fetch(
      apicall.SERVER_URL_CA + "api/v1/get/scheduledata",
      {
        method: "POST",
        body: JSON.stringify({
          access_role_id: localStorage.getItem("access_role_id"),
          person_id: localStorage.getItem("person_id"),
          accessible_projects: localStorage.getItem("accessible_projects"),
          filter_value: filterValue,
          start_date: dateLimit.start_date,
          end_date: dateLimit.end_date,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (apiResponse.status === 200) {
      const responseData = await apiResponse.json();
      setScheduleData(responseData.data);
      setDefaultFilter(filterValue);
      setIsLoading(false);
    }
  }; */

  const filterEvents = async (filter) => {
    setIsLoading(true);

    try {
      let filterValue = filter.target.value;
      localStorage.setItem("current_calendar", filterValue);

      const resourceApiResponse = await fetch(
        apicall.SERVER_URL_CA + "api/v1/all/resources",
        {
          method: "POST",
          body: JSON.stringify({
            access_role_id: localStorage.getItem("access_role_id"),
            person_id: localStorage.getItem("person_id"),
            accessible_projects: localStorage.getItem("accessible_projects"),
            filter_value: filterValue,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (resourceApiResponse.status === 200) {
        const ResourceresponseData = await resourceApiResponse.json();
        setData(ResourceresponseData.data);
      } else {
        throw new Error("Failed to fetch resource data");
      }

      const apiResponse = await fetch(
        apicall.SERVER_URL_CA + "api/v1/get/scheduledata",
        {
          method: "POST",
          body: JSON.stringify({
            access_role_id: localStorage.getItem("access_role_id"),
            person_id: localStorage.getItem("person_id"),
            accessible_projects: localStorage.getItem("accessible_projects"),
            filter_value: filterValue,
            start_date: dateLimit.start_date,
            end_date: dateLimit.end_date,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (apiResponse.status === 200) {
        const responseData = await apiResponse.json();
        setScheduleData(responseData.data);
        setDefaultFilter(filterValue);
      } else {
        throw new Error("Failed to fetch schedule data");
      }
    } catch (error) {
      console.error("Error in filterEvents:", error);
      // You can also show an error message to the user if needed
    } finally {
      setIsLoading(false);
    }
  };

  const onSlotClick = (slot) => {
    //console.log(slot, "slot");
    if (localStorage.getItem("access_role_id") == "8") return;

    if (localStorage.getItem("access_role_id") == "3") {
      let isPersonManaged = managedPersons.some(
        (obj) => obj.person_id == slot.slotId
      );

      if (isPersonManaged || localStorage.getItem("person_id") == slot.slotId) {
        startPersonEdit(slot.slotId);
      }
    }

    if (defaultFilter === "Schedule" && slot.slotId[0] !== "p") {
      startPersonEdit(slot.slotId);
    } else if (slot.slotId[0] === "p") {
      let project_id = slot.slotId.split("p");
      startProjectEdit(project_id[1]);
    }
  };

  function subDay(dateString) {
    const inputDate = new Date(dateString);
    inputDate.setDate(inputDate.getDate());
    const outputDateString =
      inputDate.toISOString().split("T")[0] + " 05:30:00";
    return outputDateString;
  }

  const updateOnDrag = async (newEvent) => {
    const eventObject = JSON.parse(newEvent);

    // endDateToDisplay
    if (eventObject.endDateToDisplay) {
      let endDate = eventObject.end;
      eventObject.end = subDay(endDate);
    }
    //console.log(eventObject, "this-obj");

    let apiURL = "";
    let apiBody = JSON.stringify({});

    if (eventObject.type == "1") {
      apiURL = "api/v1/edit/milestone";
      apiBody = JSON.stringify({
        milestone_id: eventObject.id,
        milestone_start: eventObject.start,
        milestone_end: eventObject.end,
      });
    } else if (eventObject.type === 3) {
      apiURL = "api/v1/edit/timeoff/drag";
      apiBody = JSON.stringify({
        new_timeoff: JSON.stringify(eventObject),
      });
    } else {
      //console.log(props, "all-props");
      eventObject["dragFor"] = localStorage.getItem("current_calendar");
      //console.log(eventObject, "ev-obj");
      apiURL = "api/v1/edit/allocation/drag";
      apiBody = JSON.stringify({
        new_allocation: JSON.stringify(eventObject),
      });
    }

    const apiResponse = await fetch(apicall.SERVER_URL_CA + apiURL, {
      method: "POST",
      body: apiBody,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (apiResponse.status === 200) {
      const responseData = await apiResponse.json();
      // setScheduleData(responseData.data);
      // setDefaultFilter(responseData.applied_filter);
      // setIsLoading(false);
    }
  };

  const handleFilterSize = (selectedValue) => {
    setDefaultFiltersize(selectedValue);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  const startProjectEdit = async (project_id) => {
    fetch(apicall.SERVER_URL_CA + `api/v2/all/project_data/${project_id}`)
      .then((response) => response.json())
      .then((data) => {
        setProjectData(data.data[0]);
        setModalShow(true);
      })
      .catch((error) => {
        console.error("Error fetching project data: ", error);
      });
  };

  const startPersonEdit = (personId) => {
    for (let item of persons) {
      if (item.id === personId) {
        setCurrentPeople(item);
        setEditPeopleModal(true);
        return item;
      }
    }
  };

  const copyOnDrag = async (event) => {
    let apiurl = "api/v2/add/scheduleallocation";
    let data = new FormData();

    console.log(event);

    // Append the fields to FormData
    data.append("project_id", event.project_id);
    data.append("sender_id", localStorage.getItem("person_id"));
    data.append("title", event.title);
    data.append("notes", event.notes);
    data.append("hours_per_day", event.hoursPerDay);
    data.append("start_date", event.start);
    data.append("end_date", event.end);
    data.append(
      "assigned_to",
      JSON.stringify([{ value: event.resourceId, label: event.person_name }])
    ); // Serialized array
    data.append("slot_id", event.resourceId);

    // Uncomment and set these fields as needed
    // data.append("allocation_id", event.allocationId);
    // data.append("timeoff_hours_per_day", event.timeoffHoursPerDay);
    // data.append("timeoff_start_date", event.timeoffStartDate);
    // data.append("timeoff_end_date", event.timeoffEndDate);
    // data.append("time_off_type", event.timeOffType);
    // data.append("bg_color", event.bgColor);
    // data.append("tentative", "No");
    // data.append("timeoff_notes", "No");
    // data.append("weekDaysdates", '');

    // Assuming generateWeekdayDates is a function that returns a string or array
    data.append(
      "weekDaysdates",
      JSON.stringify([
        {
          start: event.start,
          end: event.end,
        },
      ])
    );

    try {
      const apiResponse = await fetch(apicall.SERVER_URL_CA + apiurl, {
        method: "POST",
        body: data,
      });

      const result = await apiResponse.json();
      console.log(result);
      return result?.allocation_ids || [];
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "rgb(243, 242, 245)",
        minHeight: "100vh",
      }}
    >
      {modalShow && (
        <EditProject
          show={modalShow}
          onHide={handleToggleModal}
          persons={persons}
          currentprojectdata={projectData}
        />
      )}

      {editPeopleModal && (
        <EditPeople
          show={editPeopleModal}
          onHide={() => setEditPeopleModal(false)}
          projects={projects}
          currentpeopledata={currentpeopledata}
        />
      )}

      <div
        className="mx-2"
        style={{ backgroundColor: "rgb(243, 242, 245)", marginTop: "6px" }}
      >
        <Sidebar
          activeRoute={"Schedule"}
          totalNotifications={totalNotifications}
        />
      </div>
      <div
        className=""
        style={{
          background: "#fff",
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
        }}
      >
        {isLoading ? (
          <LoadingScreen
            loading={isLoading}
            bgColor="rgba(0, 0, 0, 0.5)"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc=""
            text=""
          >
            {" "}
          </LoadingScreen>
        ) : (
          <Basic
            data={data}
            milestones={scheduleData}
            filterEvents={(value) => filterEvents(value)}
            defaultFilter={defaultFilter}
            updateOnDrag={(newEvent) => updateOnDrag(newEvent)}
            onSlotClick={onSlotClick}
            defaultFiltersize={defaultFiltersize}
            handleFilterSize={handleFilterSize}
            projects={projects}
            setDateLimit={setDateLimit}
            copyOnDrag={copyOnDrag}
          />
        )}

        {/* {showToastMsg && <ToastMsg />} */}
      </div>
    </div>
  );
}
