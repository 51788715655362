import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col } from 'react-bootstrap';
import apicall from '../../../apicall';



import '../../../index.css';
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, FormGroup } from 'reactstrap';
import SearchableDropdown from '../../../components/searchDropdown/SearchableDropdown';
import ChipInput from '../../../components/chipInput/ChipInput';

import '../../../App.css'
import MoreAction from '../../../components/modals/moreaction';
import ConfirmArchiveActive from '../../../components/modals/confirmArchiveActive';
import ManagesMultiSelect from '../../../components/chipInput/ManagesMultiSelect';


function EditPeople(props) {
    const [projects, setProjects] = useState(props.projects);
    const [selectedProjects, setSelectedProjects] = useState([props.currentpeopledata.projects_detail]);
    const [department, setDepartmentOptions] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(props.currentpeopledata.department);
    const [currentAccessRole , setCurrentAccessRole] = useState(props.currentpeopledata.access_role_id);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedManagerAccessDepartments, setselectedManagerAccessDepartments] = useState(props.currentpeopledata.accessible_projects);
    const [canViewTitle, setCanViewTitle] = useState('Everyone');
    const [showProjectDropdown , setShowProjectDropdown] = useState(false);
    const [memberEditDropdownOpen, setMemberEditDropdownOpen] = useState(false);
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
    const [peopleManager, setPeopleManager] = useState(props.currentpeopledata.people_manager);
    const [projectManager, setProjectManager] = useState(props.currentpeopledata.project_manager);
    const [managerAccessDepartments , setmanagerAccessDepartments] = useState([]);
    const [name, setName] = useState(props.currentpeopledata.name);
    const [email, setEmail] = useState(props.currentpeopledata.email);
    const [tags, setTags] = useState(props.currentpeopledata.tags ? JSON.parse(props.currentpeopledata.tags) : []);
    const [canViewMemberTitle, setCanViewMemberTitle] = useState(props.currentpeopledata.can_view);
    const [canEditMemberTitle, setcanEditMemberTitle] = useState(props.currentpeopledata.can_edit);
    const [memberAccessDepartments, setMemberAccessDepartments] = useState([]);
    const [selectedMemberAccessDepartments, setSelectedMemberAccessDepartments] = useState([]);
    const [memberCanView, setMemberCanView] = useState(props.currentpeopledata.can_view);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmModalShow , setConfirmModalShow] =  useState(false);
    const [showmanages, setShowManages] = useState(props.currentpeopledata.people_manager === 'Yes' ? true : false);
    const [managedPeople, setManagedPeople] = useState(null);

        const requestOptions = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
            'access_role_id' : localStorage.getItem('access_role_id'),
            'accessible_projects' : localStorage.getItem('accessible_projects')
            }),
      };

    const getManagedPeople = async () => {
        const response = await fetch(apicall.SERVER_URL_CA + 'api/v1/get/managedpeople', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    'manager_id' : props.currentpeopledata.id
            }),
        });

        const responseData = await response.json();
        //console.log(responseData, 'managed-people');
        setManagedPeople(responseData.data);
    }

     useEffect(() => {
        getDepartments();
        if(localStorage.getItem('access_role_id') == 3){
            getManagedPeople();
        }
        fetch(apicall.SERVER_URL_CA + "api/v1/all/projects", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let projectsData = data.data;
                setProjects(projectsData);
                // setmanagerAccessDepartments(projectsData);
            })
            .catch((error) => {
                console.error('Error fetching data from the API: ', error);
            });
    }, [props.show]); 



    // useEffect(() => {
    // fetch(apicall.SERVER_URL_CA + "api/v1/all/departments", {
    //     method: 'GET'
    // })
    //     .then((response) => response.json())
    //     .then((data) => {
    //         let projectsData = data.data;
    //         // setProjects(projectsData);
    //     })
    //     .catch((error) => {
    //         console.error('Error fetching data from the API: ', error);
    //     });
    // }, [props.show]);



    const getDepartments = () => {
        fetch(apicall.SERVER_URL_CA + "api/v1/all/departments")
        .then((response) => response.json())
        .then((data) => {
          setDepartmentOptions(data.data);
          setmanagerAccessDepartments(data.data);
      })
        .catch((error) => {
            console.error('Error fetching data from the API: ', error);
        });
  }



    const togglebtndropdown = (dropdownFor)=>{
        if(dropdownFor === "member"){
            setDropdownOpen(!dropdownOpen);
        }
        else if(dropdownFor === "manager"){
            if(localStorage.getItem('access_role_id') == 2)
            setDropdownOpen(!dropdownOpen);
        }
    }
    
    const changeAccessRole = (event)=> {
        setCurrentAccessRole(event.target.value);
    }

    useEffect(() => {

        
        setProjects(props.projects);
        setCurrentAccessRole(props.currentpeopledata.access_role_id);

        //Setting Can-View Button Text Based on Access Role Id:
        if(props.currentpeopledata.access_role_id == '8'){
            // alert(props.currentpeopledata.access_role_id);
            setCanViewMemberTitle(props.currentpeopledata.can_view);
            setMemberCanView(props.currentpeopledata.can_view);
            setcanEditMemberTitle(props.currentpeopledata.can_edit);
            document.getElementById('manager_access_departments').value = 'Everyone';


            if(props.currentpeopledata.can_view != 'Everyone' && props.currentpeopledata.can_view != 'Themself'){
                setCanViewMemberTitle('Departments')
                if(props.currentpeopledata.can_view){
                    setSelectedMemberAccessDepartments(JSON.parse(props.currentpeopledata.can_view))
                }

                const apiResponse = fetch(apicall.SERVER_URL_CA + "api/v1/all/departments", {
                    method: 'GET'
                });

                apiResponse.then(response => {
                    if (response.ok) {
                        return response.json(); // Parse the JSON data from the response
                    } else {
                        // Handle errors here
                        throw new Error('Failed to fetch data');
                    }
                })
                .then(data => {
                    setMemberAccessDepartments(data.data)
                    setShowDepartmentDropdown(true);
                })
                .catch(error => {
                    // Handle any errors that occurred during the fetch or processing of the data
                });
            }
        }
        else{
            
            setShowDepartmentDropdown(false);
            setCanViewMemberTitle('Everyone');
            setMemberCanView('Everyone')
            setcanEditMemberTitle('Themself')
            setSelectedMemberAccessDepartments([]);

            // alert(props.currentpeopledata.can_view);
            if(props.currentpeopledata.can_view == 'Everyone'){
                setCanViewTitle(props.currentpeopledata.can_view)
                setShowProjectDropdown(false);
                document.getElementById('manager_access_departments').value = props.currentpeopledata.can_view;
            }
            else{
                setCanViewTitle('Departments')
                setShowProjectDropdown(true);
                if(props.currentpeopledata.can_view == 'Admin'){
                    document.getElementById('manager_access_departments').value = 'Everyone';
                }
                else{
                    document.getElementById('manager_access_departments').value = props.currentpeopledata.can_view;
                }
            }

            if(props.currentpeopledata.can_view != 'Everyone' && props.currentpeopledata.can_view != 'Admin'){
                setselectedManagerAccessDepartments(JSON.parse(props.currentpeopledata.can_view));
            }
            else{
                setselectedManagerAccessDepartments(JSON.parse('[]'));
                setCanViewTitle('Everyone');
                setShowProjectDropdown(false);
            }

            if(props.currentpeopledata.create_edit_people &&  props.currentpeopledata.create_edit_people === 'Yes'){
                let create_edit_people_checkbox =  document.getElementById('create_edit_people');
                if(create_edit_people_checkbox){
                    create_edit_people_checkbox.checked = true;
                }
            }
    
        }


    
        setPeopleManager(props.currentpeopledata.people_manager);
        setProjectManager(props.currentpeopledata.project_manager);


        setName(props.currentpeopledata.name);
        setEmail(props.currentpeopledata.email);
        setTags(props.currentpeopledata.tags ? JSON.parse(props.currentpeopledata.tags) : []);
    }, [props.show]);


    useEffect(()=>{
        setSelectedProjects(props.currentpeopledata.projects_detail);
    },[props.currentpeopledata.projects_detail] );


    useEffect(() => {
        setSelectedDepartment(props.currentpeopledata.department);
      }, [props.currentpeopledata.department]);


      useEffect(()=>{
        setShowManages(props.currentpeopledata.people_manager === 'Yes' ? true : false);
    },[props.currentpeopledata.people_manager] );

     useEffect(() => {
       // Filter out persons whose id is in selectedPersons
       const filteredProjects = projects.filter(project => !selectedProjects.some(selectedProject => selectedProject.project_id == project.id));
       // Update the persons state with the filtered array
       setProjects(filteredProjects);
   }, [selectedProjects]);



    let projectObject  = {}
    let projectString = '' ; 

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true)
        
        const formData = new FormData(event.target);
        const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/edit/person", {
            method: 'POST',
            body: formData,
        });

        if (apiResponse.status === 200) {
             window.location.reload();
        } else {
            const errorResponse = await apiResponse.json();
            alert(errorResponse.message);
            setIsSubmitting(false);
            // Handle other status codes or errors
            // You can display an error message or take other actions
        }
    };

    function handleProjects(event){
         var selectElement = document.getElementById("projectselect")
         var selectedOption = selectElement.options[selectElement.selectedIndex];
        if(selectedOption.value){
            const updatedProjects = projects.filter(item => item.id != selectedOption.id);
            setProjects(updatedProjects);

            let projectAlreadyPresent = selectedProjects.some(obj => obj.project_id == selectedOption.id);

            if(!projectAlreadyPresent){
                const newproject = {
                    'project_id' : selectedOption.id,
                    'project_name' : selectedOption.value
                 }
                const updatedSelectedProjects = [...selectedProjects , newproject];
                setSelectedProjects(updatedSelectedProjects);
                document.getElementById('projectInput').value = JSON.stringify(updatedSelectedProjects);
     
            }
        }
    }

    const removeProject = (event)=>{

        const updatedSelectedProjects = selectedProjects.filter(item => item.project_id != event.target.id);
        setSelectedProjects(updatedSelectedProjects);
        
        let projectAlreadyPresent = projects.some(obj => obj.id == event.target.id);

        if(!projectAlreadyPresent){
            const newproject = {
                'id' : event.target.id,
                'name' : event.target.getAttribute('value')
            }
    
            const updatedProjects = [...projects,  newproject];
            setProjects(updatedProjects);
            document.getElementById('projectInput').value = JSON.stringify(updatedProjects);
        }

    }

    const handleDepartment = (event) => {
        setSelectedDepartment(event);
    }


    const handleCanView = (title) => {
        // setCanViewTitle(title);
        if(title === 'Departments'){
            setShowProjectDropdown(true);
            setCanViewTitle('Departments');

        }
        else{
            setShowProjectDropdown(false);
            setCanViewTitle('Everyone');
            document.getElementById('manager_access_departments').value = 'Everyone';
        }
    }

    const handlePeopleYesNo = (event) => {
        if(event.target.innerText == 'Yes'){
            event.target.classList.add('btn-success');
            document.getElementById('peopleno').classList.remove('btn-danger');
            setShowManages(true);
        }
        else{
            event.target.classList.add('btn-danger');
            document.getElementById('peopleyes').classList.remove('btn-success');
            setShowManages(false);
        } 

        document.getElementById('people_manager').value = event.target.innerText;
    }


    const handleProjectYesNo = (event)=>{
        if(event.target.innerText == 'Yes'){
            event.target.classList.add('btn-success');
            document.getElementById('projectno').classList.remove('btn-danger');
        }
        else{
            event.target.classList.add('btn-danger');
            document.getElementById('projectyes').classList.remove('btn-success');
        } 

        document.getElementById('project_manager').value = event.target.innerText;

    }

    const handlemanagerAccessDepartments = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target.options[selectedIndex];

        if(selectedOption.value){
            if(selectedManagerAccessDepartments != 'All')
            {
                let filteredAccessProjects = managerAccessDepartments.filter((item) => item.id != selectedOption.value);
                setmanagerAccessDepartments(filteredAccessProjects);
            }
            else{
                setselectedManagerAccessDepartments([]);
            }
            
            let projectAlreadyPresent = false;
            if(Array.isArray(selectedManagerAccessDepartments)){
                projectAlreadyPresent = selectedManagerAccessDepartments.some(obj => obj.id == selectedOption.value);
            }
            if(!projectAlreadyPresent){
                let newSelectedProj = {
                    'id' : selectedOption.value,
                    'name' : selectedOption.innerText
                }
                if(selectedManagerAccessDepartments != 'All'){
                    let updatedAccessibleProj = [...selectedManagerAccessDepartments, newSelectedProj];
                    setselectedManagerAccessDepartments(updatedAccessibleProj);
                    document.getElementById('manager_access_departments').value = JSON.stringify(updatedAccessibleProj);
                }

            }     
        }

    }

    const removeSelectedAccessProjects = (event) => { 
        let filteredAccessible = selectedManagerAccessDepartments.filter((item) => item.id != event.target.getAttribute('value'));
        setselectedManagerAccessDepartments(filteredAccessible);
        
        document.getElementById('manager_access_departments').value = JSON.stringify(filteredAccessible);

        const projectAlreadyPresent = managerAccessDepartments.some(obj => obj.id == event.target.getAttribute('value'));

        let projectName = ''
        if(!projectAlreadyPresent){
            const lastIndex = event.target.parentElement.innerText.lastIndexOf('X');
            projectName =  event.target.parentElement.innerText.slice(0, lastIndex) + event.target.parentElement.innerText.slice(lastIndex + 1);
            let newSelectedProj = {
                'id' : event.target.getAttribute('value'),
                'name' : projectName
            }
            let updatedAccessProj = [...managerAccessDepartments, newSelectedProj];
            setmanagerAccessDepartments(updatedAccessProj);
        }

    }

    const handleChange = (event) => {
        if(event.target.name == "name"){
            setName(event.target.value);
        }
        else if(event.target.name == "email"){
            setEmail(event.target.value)
        }

    }

    
    const handleCanViewMember = async (title) =>{
        setCanViewMemberTitle(title);
        setMemberCanView(title);
    
        if (title === "Departments") {
            // API call and set department options
            if (memberAccessDepartments.length === 0) {
              try {
                const apiResponse = await fetch(apicall.SERVER_URL_CA + "api/v1/all/departments", {
                  method: 'GET'
                });
        
                if (apiResponse.ok) {
                  const responseData = await apiResponse.json();
                  setMemberAccessDepartments(responseData.data);
                } else {
                  console.error('Network response was not ok');
                }
              } catch (error) {
                console.error('Error:', error);
              }
            }
            setShowDepartmentDropdown(true);
    
            if(selectedMemberAccessDepartments.length > 0){
                setSelectedMemberAccessDepartments([]);
            }
          }
        
        else{
            setShowDepartmentDropdown(false);
        }
       }

        
       const toggleMemberEdit = () => {
        setMemberEditDropdownOpen(!memberEditDropdownOpen);
    }
       
   const handleCanEditMember = (title) => {
    setcanEditMemberTitle(title);
   }


   const handleMemberAccessDepartments = (event) => {
    if(event.target.value){
        let newDeptObj = {
            'id' : event.target.value,
            'name' : event.target.value
        }

        const deptAlreadyPresent = selectedMemberAccessDepartments.some(obj => obj.id == event.target.value);

        if(!deptAlreadyPresent){
            let updatedSelectedMemberAccessDepts = [...selectedMemberAccessDepartments, newDeptObj];
            setSelectedMemberAccessDepartments(updatedSelectedMemberAccessDepts);
            setMemberCanView(JSON.stringify(updatedSelectedMemberAccessDepts));
        }

        let updatedMemberAccessDepts = memberAccessDepartments.filter(dept => dept.id != event.target.value);
        setMemberAccessDepartments(updatedMemberAccessDepts);

    }

}

const removeSelectedAccessDepartments = (event) => {

    let updatedSelectedMemberAccessDepts = selectedMemberAccessDepartments.filter(dept => dept.id != event.target.getAttribute('value'));
    setSelectedMemberAccessDepartments(updatedSelectedMemberAccessDepts);

    let newDeptObj = {
        'id' : event.target.getAttribute('value'),
        'name' : event.target.getAttribute('value')
    }

    const deptAlreadyPresent = memberAccessDepartments.some(obj => obj.id == event.target.getAttribute('value'));

    if (!deptAlreadyPresent) {
    let updatedMemberAccessDepts = [...memberAccessDepartments, newDeptObj];
    setMemberAccessDepartments(updatedMemberAccessDepts);
    }

    setMemberCanView(JSON.stringify(updatedSelectedMemberAccessDepts));
}

const deletePerson = async () => {
    // setIsSubmitting(true);
    const apiResponse = await fetch(apicall.SERVER_URL_CA + 'api/v1/delete/person', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',     
          },
        body: JSON.stringify({
            'selectedPerson' : [props.currentpeopledata.id]
        }),
    });

    let reponseData = await apiResponse.json();
    //console.log(reponseData);
    if(apiResponse.status === 200){
        setIsSubmitting(false);
        window.location.reload();
    }
    else{
        setIsSubmitting(false);
        alert('Some Error Occured');
    }
}

    const markAllProjects = (event) => {
        document.getElementById('projectselect').hidden = event.target.checked;

        if(!event.target.checked){
            document.getElementById('projectInput').value = JSON.stringify({});
            return;
        }
        
        let allProjects = [];


        projects.map((project) => {
            let projObj = {
                'project_id' : parseInt(project.id),
                'project_name' : project.name
            }

            allProjects.push(projObj);
        })

        document.getElementById('projectInput').value = JSON.stringify(allProjects);

    }
    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static" // Prevents modal from closing when clicking outside
            keyboard={false}
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                     Edit Person
                </Modal.Title>
            </Modal.Header> */}
            <form onSubmit={handleSubmit}>
            <Modal.Body style={{minHeight: '430px'}}>
                <Tabs defaultActiveKey="title" id="event-tabs">
                    <Tab eventKey="title" title="Info">
                        {/* <Form> */}
                            <br />
                          <input type="text" name="person_id" value={props.currentpeopledata.id}  hidden />
                          <input type="text" name="department" value={selectedDepartment} hidden />
                            <Form.Group controlId="eventTitle">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'name'}
                                    value={name || ''}
                                    onChange = {handleChange}
                                    required
                                />
                            </Form.Group>
                            <br />
                            <Form.Group controlId="eventTitle">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    pattern="[^@]+@[^@]+\.[^@]+"
                                    name={'email'}
                                    value={email || ''}
                                    onChange= {handleChange}
                                    required
                                />
                            </Form.Group>

                        <div className="row my-3">
                            <div className="col">
                                <Form.Group controlId="selectDropdown">
                                <Form.Label>Role</Form.Label>
                                <Form.Control as="select" name="role_id" required>
                                <option value="3">Manager</option>
                                <option  value="6">Employee</option>
                                </Form.Control>
                                </Form.Group>
                            </div>    
                            <div className="col">
                            <Form.Group controlId="selectDropdown">
                            <Form.Label>Department</Form.Label>
                            <SearchableDropdown
                                options={department}
                                label="name"
                                id="department"
                                name={'department'}
                                selectedVal={selectedDepartment}
                                handleChange={(val) => handleDepartment(val)}
                            />                                         
                            <p id="department-error-text" className='text-danger' style={{ display: 'none'}}>Please select a department</p>
                        </Form.Group>
                            </div>    
                            <div className="col">
                            <Form.Group controlId="selectDropdown">
                        <Form.Label>Person Type</Form.Label>
                        <Form.Control as="select" name="person_type" required>
                        {props.currentpeopledata.person_type && <option value={props.currentpeopledata.person_type}>{props.currentpeopledata.person_type}</option>}
                        <option  value="contractor">Contractor</option>
                        <option  value="placeholder">Placeholder</option>
                        </Form.Control>
                        </Form.Group>
                            </div>    
                        </div>                            


                        <Form.Group controlId="selectDropdown">
                            <Form.Label>Tags</Form.Label>
                            <ChipInput setTags={(value) => setTags(value)} tags={tags}/>
                            <input type="hidden" name="tags" value={tags}></input>
                        </Form.Group>
                        <br />
                       


                        {/* </Form> */}
                    </Tab>
                    <Tab eventKey="startDate" title="Access">
                        {/* Can Edit and Can view hidden input for member */}
                        <input type="text"  id="member_can_view" name="member_can_view" value={memberCanView} hidden />
                        <input type="text"  id="member_can_edit" name="member_can_edit" value={canEditMemberTitle ? canEditMemberTitle : 'Themself'} hidden />
                        {/* <Form> */}
                        <br />
                        {/* Can Edit and Can view hidden input for manager */}
                        <input type="text" name="manager_access_departments" id="manager_access_departments" hidden/>
                        <input type="text" name="people_manager" id="people_manager" defaultValue={props.currentpeopledata.people_manager !== null ? props.currentpeopledata.people_manager : 'No'}  hidden/>
                        <input type="text" name="project_manager" id="project_manager" defaultValue={props.currentpeopledata.project_manager !== null ? props.currentpeopledata.project_manager : 'No'}  hidden/>

                        <Form.Group controlId="selectDropdown">
                        <Form.Label>Access</Form.Label>
                        <Form.Control as="select" name="access_role_id"  onChange={changeAccessRole}>
                        <option  value="7" selected={currentAccessRole == '7'}>--No Access Rights--</option>
                        <option value="8" selected={currentAccessRole == '8'}>Member</option>
                        <option value="3" selected={currentAccessRole == '3'}>Manager</option>
                        {(localStorage.getItem('access_role_id') == '2') &&
                        <option value="2" selected={currentAccessRole == '2'}>Admin</option>
                    }
                        </Form.Control>
                        </Form.Group>

                        <div>
                            {currentAccessRole == '8' ? 
                                 <div className='mt-4'>
                                 <div className="row ">
                                     <div className="col-1 access-div ">
                                     <h5 className='access-icon'><AiIcons.AiOutlineEye /></h5>
                                     </div>
                                     <div className="col-8">
                                         <div style={{ display :'flex', justifyContent : 'space-between'}}>
                                         <h5 style={{marginBottom:  '0px'}}>Can View    </h5>
                                         </div>
                                     <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                     <p>Who they can view on the schedule</p>
                                     </div>
                                     {showDepartmentDropdown ? 
                                <div>
                                <label className='mb-1 text-secondary'>Departments</label>
                                <select className="form-select form-select-sm" aria-label="Small select example" onChange={handleMemberAccessDepartments}>
                                  <option selected value="">Select Departments</option>
                                  {memberAccessDepartments.map((dept) => (
                                 <option value={dept.name}>{dept.name}</option>  
                                 ))}
                                </select>

                                <div className='mt-4'>
                                {selectedMemberAccessDepartments.map((dept)=>(
                                    <p style={{borderBottom: '1px solid #D3D3D3' , paddingBottom : '7px'}}>{dept.name}<span style={{float : 'right', cursor : 'pointer'}} onClick = {removeSelectedAccessDepartments} value={dept.name}>X</span></p>
                                ))}
                                </div>
                                </div>
                                : null}
                                     </div>
                                     <div className="col-3">
                                     <ButtonDropdown isOpen={dropdownOpen} toggle={() => togglebtndropdown('member')}  style={{width : '100%'}}>
                                  <DropdownToggle caret color="primary">
                                    {canViewMemberTitle}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem onClick={() => handleCanViewMember('Everyone')}><span><FiIcons.FiGlobe style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>Everyone</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => handleCanViewMember('Departments')}><span><BsIcons.BsBuilding style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>Departments</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => handleCanViewMember('Themself')}><span><AiIcons.AiOutlineUser style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>Themself</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>
                                     </div>
                                 </div>
                        
                                 <div className="row mt-3">
                                     <div className="col-1 access-div ">
                                     <h5 className='access-icon'><BsIcons.BsPencil /></h5>
                                     </div>
                                     <div className="col-8">
                                         <div style={{ display :'flex', justifyContent : 'space-between'}}>
                                         <h5 style={{marginBottom:  '0px'}}>Can Update</h5>
                                         </div>
                                     <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                     <p>Member can update their data on schedule</p>   
                                     </div>
                                     </div>
                                     <div className="col-3">
                                     <ButtonDropdown isOpen={memberEditDropdownOpen} toggle={toggleMemberEdit}  style={{width : '100%'}}>
                                  <DropdownToggle caret color="primary">
                                    {canEditMemberTitle ? canEditMemberTitle : 'Themself'}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem onClick={() => handleCanEditMember('Themself')}><span><AiIcons.AiOutlineUser style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>Themself</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => handleCanEditMember('No One')}><span><FiIcons.FiGlobe style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>No One</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>    
                                     </div>

                                 </div>
                             </div> 
                            : currentAccessRole == '3'?
                             <div className='mt-4'>
                            <div className="row">
                            <div className="col-1 access-div">
                            <h5 className='access-icon'><AiIcons.AiOutlineEye /></h5>
                            </div>
                            <div className="col-8">
                                <h5 style={{ marginBottom: '0px'}}>   Can View</h5>
                                <p>Who they can see on the schedule</p>
                                {showProjectDropdown || canViewTitle == 'Projects' ?   
                                <div>
                                <FormGroup className='mb-4'>
                                <Label style={{marginLeft: '2px', fontSize:'14px'}} className='mb-1'>Departments</Label>
                                <FormGroup>
                                    <Input type="select" name="select" id="exampleSelect" onChange={handlemanagerAccessDepartments} >
                                        <option value="" selected>--Select Departments--</option>
                                    {managerAccessDepartments.map((project) => (
                                        <option  value={project.id}>{project.name}</option>
                                    ))}
                                    </Input>
                                </FormGroup>
                              </FormGroup>
                              {Array.isArray(selectedManagerAccessDepartments) ? selectedManagerAccessDepartments.map((project) => (
                             <div key={project.id}>
                              <p style={{ border: '1px solid #D3D3D3', borderRadius: '7px', width: 'fit-content', padding: '3px 8px 3px 4px' }} className='shadow'>
                             {project.name}
                             {localStorage.getItem('access_role_id') != '3'  ? <span style={{ marginLeft: '50px', color: 'black', fontWeight: '600', cursor: 'pointer' }} value={project.id} onClick={removeSelectedAccessProjects} > X</span> : null}
                            </p>
                            </div>
                            )) : null}


                        </div>
                              : null }
                            </div>
                            <div className="col-3">
                                <ButtonDropdown isOpen={dropdownOpen} toggle={() => togglebtndropdown('manager')}  style={{width : '100%'}} >
                                  <DropdownToggle caret color="primary">
                                     {canViewTitle}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                  {/* onClick={() => handleCanView('Everyone')} */}
                                    <DropdownItem onClick={() => handleCanView('Everyone')} ><span><FiIcons.FiGlobe style={{marginRight :'5px', verticalAlign : 'middle'}}/></span>Everyone</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem  onClick={() => handleCanView('Departments')}>
                                    <p style={{ marginBottom: '0px', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                                  <span style={{ marginRight: '5px', display: 'inline-flex', alignItems: 'center' }}>
                                    <AiIcons.AiOutlineTeam />
                                  </span>
                                    Departments
                                </p>
                                        </DropdownItem>

                                  </DropdownMenu>
                                </ButtonDropdown>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-1 access-div">
                                <h5 className='access-icon'><AiIcons.AiOutlineTeam /></h5>
                                </div>
                                <div className="col-8">
                                    <h5 style={{ marginBottom : '0px'}}>People Manager</h5>
                                    <p>Can schedule and approve time off requests</p>
                                </div>
                                <div className="col-3" >
                                    <button className={peopleManager == 'Yes' ? 'btn mx-2' : 'btn btn-danger mx-2' } type="button" size="sm" id="peopleno" onClick={handlePeopleYesNo} disabled={localStorage.getItem('access_role_id') == 3}>No</button>
                                    <button className={peopleManager == 'Yes' ? 'btn btn-success' : 'btn'} type="button"  style={{cursor: 'pointer'}} id="peopleyes" onClick={handlePeopleYesNo} disabled={localStorage.getItem('access_role_id') == 3}>Yes</button>  
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-1 access-div">
                                <h5 className='access-icon'><AiIcons.AiOutlineFolderOpen /></h5>
                                </div>
                                <div className="col-8">
                                    <h5 style={{ marginBottom : '0px'}}>Project Manager</h5>
                                    <p>Can schedule tasks and manage projects</p>
                                </div>
                                <div className="col-3" >
                                    <button className={projectManager == 'Yes' ? 'btn mx-2' : 'btn btn-danger mx-2' } type="button" size="sm" id="projectno" onClick={handleProjectYesNo} disabled={localStorage.getItem('access_role_id') == 3}>No</button>
                                    <button className={projectManager == 'Yes' ? 'btn btn-success' : 'btn' } type="button"  style={{cursor: 'pointer'}} id="projectyes" onClick={handleProjectYesNo} disabled={localStorage.getItem('access_role_id') == 3}>Yes</button>  
                                </div>
                            </div>
                            
                            <div style={{borderTopWidth: '2px', borderColor: "grey"}}>
                                <h5>Additional Roles</h5>
                                <label>
                                    <input type="checkbox" name="create_edit_people" id="create_edit_people" style={{marginTop: '2px', marginRight: '5px'}} disabled={localStorage.getItem('access_role_id') == 3} /><span>Create and edit people</span> 
                                </label>
                            </div>
                        </div>
                        
                            : currentAccessRole == '2'? 
                            <div className="mt-4">
                            <div className='row'>
                                <div className="col-1 access-div">
                                    <h5 className='access-icon'><AiIcons.AiOutlineEye /></h5>
                                </div>
                                <div className="col-8">
                                    <h5 style={{ marginBottom: '0px'}}>Can View</h5>
                                    <p>Who they can see on the schedule</p>
                                </div>
                                <div className="col-3">
                                    <h6>Everyone</h6>
                                </div>
                            </div>
    
                            <div className='row mt-2'>
                                <div className="col-1 access-div">
                                    <h5 className='access-icon'><FiIcons.FiGlobe /></h5>
                                </div>
                                <div className="col-7">
                                    <h5 style={{ marginBottom: '0px'}}>Manages</h5>
                                </div>
                                <div className="col">
                                    <h6 style={{float: 'right' , marginRight : '6   px'}}>All people and projects</h6>
                                </div>
                            </div>
                            </div>
                            : null}
                        </div>
                        {/* </Form> */}
                    </Tab>

                    <Tab eventKey="endDate" title="Projects">
                    {/* <Form> */}
                        <br />
                        <Form.Group controlId="selectDropdown">
                        <Form.Label>Assign a Project</Form.Label>
                        <Form.Control as="select" id="projectselect" onChange={handleProjects} > 
                        <option value="">--Select Project--</option>
                        {projects.map((project) => (
                                        <option id={project.id} value={project.name}>{project.name}</option>
                        ))}
                        </Form.Control>
                        </Form.Group>
                        {/* </Form> */}
                        <input type="text" name="projects" id="projectInput" value={JSON.stringify(selectedProjects)} hidden />
                        { selectedProjects.length == 0 ? (
                        <>
                        <input type="checkbox" id="all-projects-checkbox" className="mt-3" style={{fontSize : '16px'}}  onClick={(event) => markAllProjects(event)} />
                        <label for="all-projects-checkbox" id="all-projects-checkbox-label" className='mx-1' style={{fontSize : '16px'}} >Assign all projects</label>
                        </>
                        ) 
                        : null}
                        <div id="projectsDiv"  ></div>
                        <div style={{ marginTop : '20px' }} className='scrollable-div'>
                            {selectedProjects.map((project, index) => (
                                <div style = {{borderBottom : '1px solid #D3D3D3', paddingTop : '10px'}}>
                              <p className='text-primary' >{project.project_name}<span style={{float: 'right', marginRight:'10px', cursor : 'pointer', color : 'black' }} id={project.project_id} onClick={removeProject} value={project.project_name}>X</span></p>
                                </div>
                            ))}
                        </div>
                    </Tab>
                    {showmanages || showmanages === true ? (
                    <Tab eventKey="manages" title="Manages">
                        <div className='mt-4'>
                          <h6 htmlFor="">Manager of</h6>
                          {localStorage.getItem('access_role_id') == 2 ? (
                            <ManagesMultiSelect parent='editPeople' managerId={props.currentpeopledata.id} />
                          ) : null}
                        </div>
                        {localStorage.getItem('access_role_id') == 3 && managedPeople ? (
                          <ul className='scrollable-div'>
                            {managedPeople.map((person_name) => (
                              <li style = {{fontSize : '17px', marginBottom : '7px'}} className="text-secondary" key={person_name}>{person_name}</li>
                            ))}
                          </ul>
                        ) : null}
                      </Tab>
                    ) : null}

                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                <Button type='submit' disabled={isSubmitting}>{isSubmitting ? 'Update Person...' : 'Update Person'}</Button>
                
                {props.currentpeopledata.id != localStorage.getItem('person_id') ?
                <div hidden={isSubmitting}>
                <MoreAction  delete = {setConfirmModalShow} />
                </div>
                 : null}
                <p className='btn btn-secondary' onClick={props.onHide} style={{right : "20px", position : "absolute"}} hidden={isSubmitting}>Close</p>
                <ConfirmArchiveActive  show={confirmModalShow} onHide={()=>{setConfirmModalShow(false)}} heading= {'Delete Person'} bodyText = {'Delete ' + name + '?'} onConfirm = {deletePerson} isSubmitting = {isSubmitting}/>
            </Modal.Footer>
            </form>
        </Modal>
    );

}

export default EditPeople;
